<script setup></script>
<template>
  <div>
    <Toast />
    <h1>{{ $t('elections') }}</h1>
    <div class="grid">
      <div>
        <h1 style="margin-left: 0 !important">Isabelle Baron</h1>
        <video controls>
          <source src="/videos/Final_Isabelle_Baron.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Kevin Morais</h1>
        <video controls>
          <source src="/videos/Final_Kevin_Morais.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Martine Bruneau</h1>
        <video controls>
          <source src="/videos/Final_Martine_Bruneau.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Nancy St-Gelais</h1>
        <video controls>
          <source src="/videos/Final_Nancy_St-Gelais.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Nelson Lepage</h1>
        <video controls>
          <source src="/videos/Final_Nelson_Lepage.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Pascale Dallaire</h1>
        <video controls>
          <source src="/videos/Final_Pascale_Dallaire.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Patrick Savoie</h1>
        <video controls>
          <source src="/videos/Final_Patrick_Savoie.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Pierre-Luc Gauthier</h1>
        <video controls>
          <source src="/videos/Pierre-Luc-Gauthier-V2.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Richard Bélanger</h1>
        <video controls>
          <source src="/videos/Final_Richard_Belanger.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Shirley Kennedy</h1>
        <video controls>
          <source src="/videos/Final_Shirley_Kennedy.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
    <h1>{{ $t('poser_question') }}</h1>
    <form class="grid">
      <!-- Nom -->
      <div class="w-full">
        <label for="nom" class="form-label">{{ $t('nom_complet') }}</label>
        <InputText
          type="text"
          id="nom"
          name="nom"
          class="form-input w-full"
          v-model="nom"
        />
        <div
          class="errorMessage"
          v-for="error of v$.nom.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('errors.required') }}
          </label>
        </div>
      </div>
      <!-- Courriel -->
      <div class="w-full">
        <label for="courriel" class="form-label">{{ $t('courriel') }}</label>
        <InputText
          type="email"
          id="courriel"
          name="courriel"
          class="form-input w-full"
          v-model="courriel"
        />
        <div
          class="errorMessage"
          v-for="error of v$.courriel.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('errors.required') }}
          </label>
          <label v-if="error.$validator == 'email'">
            {{ $t('errors.email') }}
          </label>
        </div>
      </div>
      <!-- Candidat à viser -->
      <div class="w-full">
        <label for="candidat" class="form-label">{{ $t('candidat') }}</label>
        <Dropdown
          id="candidat"
          name="candidat"
          class="form-input w-full"
          v-model="candidat"
          :options="candidats"
        >
        </Dropdown>
        <div
          class="errorMessage"
          v-for="error of v$.candidat.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('errors.required') }}
          </label>
        </div>
      </div>

      <!-- Message -->
      <div class="w-full cols-3">
        <label for="message" class="form-label">
          {{ $t('question') }}
        </label>
        <Textarea
          id="message"
          name="message"
          class="form-input w-full"
          style="height: 100px; resize: none"
          v-model="message"
        ></Textarea>
        <div
          class="errorMessage"
          v-for="error of v$.message.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('errors.required') }}
          </label>
        </div>
      </div>
      <!-- Submit -->
      <div class="w-full">
        <button class="button button-primary" type="button" @click="submitForm">
          <font-awesome-icon :icon="['far', 'floppy-disk']" class="disquette" />
          {{ $t('bouton.envoyer') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import commonMessages from '../locales/common';
import axios from 'axios';
export default defineComponent({
  name: 'Elections',
  setup() {
    return { v$: useVuelidate() };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      fr: {
        candidat: 'Candidat',
        poser_question: 'Poser une question à un candidat',
        nom_complet: 'Votre nom complet',
        courriel: 'Votre courriel',
        question: 'Question (une seule question par formulaire)',
        bouton: {
          envoyer: 'Envoyer',
        },
        errors: {
          required: 'Ce champ est obligatoire',
          email: 'Veuillez entrer un courriel valide',
        },
      },
      en: {
        candidat: 'Candidate',
        poser_question: 'Ask a question to a candidate',
        nom_complet: 'Your full name',
        courriel: 'Your email',
        question: 'Question (one question per form)',
        bouton: {
          envoyer: 'Send',
        },
        errors: {
          required: 'This field is required',
          email: 'Please enter a valid email',
        },
      },
    },
  },
  data() {
    return {
      nom: '',
      candidat: '',
      courriel: '',
      message: '',
      candidats: [
        'Isabelle Baron',
        'Kevin Morais',
        'Martine Bruneau',
        'Nancy St-Gelais',
        'Nelson Lepage',
        'Pascale Dallaire',
        'Patrick Savoie',
        'Richard Bélanger',
        'Shirley Kennedy',
      ],
    };
  },
  methods: {
    submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$loading.startLoading();
        axios
          .post(process.env.VUE_APP_BACKEND_URL + 'contactElections', {
            nom: this.nom,
            candidat: this.candidat,
            courriel: this.courriel,
            message: this.message,
          })
          .then(() => {
            this.$loading.stopLoading();
            this.$toast.add({
              severity: 'success',
              summary: 'Succès',
              detail: 'Votre question a été envoyée avec succès',
              life: 3000,
            });
            this.nom = '';
            this.candidat = '';
            this.courriel = '';
            this.message = '';
            this.v$.$reset();
          })
          .catch(() => {
            this.$loading.stopLoading();
            this.$toast.add({
              severity: 'error',
              summary: 'Erreur',
              detail: 'Une erreur est survenue, veuillez réessayer plus tard',
              life: 3000,
            });
          });
      }
    },
  },
  validations() {
    return {
      nom: { required },
      candidat: { required },
      courriel: { required, email },
      message: { required },
    };
  },
});
</script>

<style scoped>
.errorMessage {
  color: red !important;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  label {
    color: red !important;
  }
}
.grid {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 4rem;
  gap: 2rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
    margin: 1rem;
  }
}

.w-full {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .cols-3 {
    grid-column: span 3;
  }
}

label {
  color: #274b93;
  font-weight: 500;
  display: block;
}

h1 {
  margin: 2rem 4rem;
  @media screen and (max-width: 1024px) {
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
}

video {
  width: 100%;
  height: auto;
}
</style>
