import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-31a14ce8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "adresses-box" }
const _hoisted_2 = { class: "t1" }
const _hoisted_3 = { class: "t2" }
const _hoisted_4 = { class: "t3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sites, (adress) => {
      return (_openBlock(), _createElementBlock("span", {
        key: adress.ville
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(adress.ville), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(adress.lieux), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(adress.adresse), 1)
      ]))
    }), 128))
  ]))
}