<template>
  <div class="content-boite">
    <div class="input-2">
      <div class="input-from mr">
        <label>{{ $t('prenom') }}</label>
        <input
          type="text"
          placeholder="Prénom"
          v-model="firstName"
          :disabled="true"
        />
      </div>
      <div class="input-from">
        <label>{{ $t('nom') }}</label>
        <input
          type="text"
          placeholder="Nom"
          v-model="lastName"
          :disabled="true"
        />
      </div>
    </div>
    <div class="input-from mt">
      <label>{{ $t('adresse') }}</label>
      <input
        type="text"
        placeholder="Adresse"
        v-model="address"
        :disabled="true"
      />
    </div>
    <div class="input-2 mt">
      <div class="input-from mr">
        <label>{{ $t('ville') }}</label>
        <input
          type="text"
          placeholder="Ville"
          v-model="city"
          :disabled="true"
        />
      </div>
      <div class="input-from">
        <label>{{ $t('province') }}</label>
        <input
          type="text"
          placeholder="Ville"
          v-model="province"
          :disabled="true"
        />
      </div>
    </div>
    <div class="input-from mt">
      <label>{{ $t('codePostal') }}</label>
      <input
        class="postalCode"
        type="text"
        placeholder="Code postal"
        v-model="postalCode"
        :disabled="true"
      />
    </div>
    <div class="input-2 mt">
      <div class="input-from mr">
        <label>{{ $t('email') }}</label>
        <input
          type="email"
          placeholder="Courriel"
          v-model="email"
          :disabled="true"
        />
      </div>
      <div class="input-from">
        <label>{{ $t('telephone') }}</label>
        <input
          type="tel"
          placeholder="Téléphone"
          v-model="telephone"
          :disabled="true"
        />
      </div>
    </div>
    <div class="input-from mt">
      <label>{{ $t('siteDistribution') }}</label>
      <select
        :value="distribution"
        @change="$emit('update:distribution', $event.target.value)"
      >
        <option v-for="adress in adresses" :key="adress.id" :value="adress.id">
          {{ uppercase(adress.ville) }} - {{ adress.lieux }}
        </option>
      </select>
      <div
        class="errorMessage"
        v-for="error of v$.distribution.$errors"
        :key="error.$uid"
      >
        <label v-if="error.$validator == 'required'">
          {{ requiredMessage }}
        </label>
        <label v-if="error.$validator == 'minLength'">
          Ce champ doit être au moins {{ error.$params.min }} caractères.
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'InformationFrom',
  setup: () => ({ v$: useVuelidate() }),
  props: {
    distribution: { type: Number, required: true },
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        siteDistribution: 'Distribution site where you will pick up your order',
      },
      fr: {
        siteDistribution:
          'Site de distribution où vous irez chercher votre commande',
      },
    },
  },
  mounted() {
    this.emitInterface();
  },
  emits: ['update:distribution', 'interface'],
  data() {
    return {
      requiredMessage: 'Champ requis.',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      postalCode: '',
      telephone: '',
      email: '',
      province: '',
      adresses: {} as Array<siteDistributionDTO>,
    };
  },
  validations: {
    distribution: { required },
  },
  methods: {
    uppercase(text: string) {
      return text.toUpperCase();
    },
    validate() {
      this.v$.$touch();
      return this.v$.$invalid;
    },
    emitInterface() {
      this.$emit('interface', {
        checkValide: () => this.validate(),
      });
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
  },
  created() {
    this.siteDistrib();
    const user = JSON.parse(sessionStorage.user);
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.address = user.address;
    this.city = user.city;
    this.postalCode = user.postalCode;
    this.telephone = user.telephone;
    this.email = user.email;
    this.province = user.province;
  },
});
</script>

<style scoped lang="scss">
.content-boite {
  .input-2 {
    display: flex;
  }
}
.mr {
  margin-right: 15px;
}
.mt {
  margin-top: 15px;
}
.input-from {
  width: 100%;
  label {
    color: $bleuFonce;
    margin-bottom: 5px;
  }
  .errorMessage {
    label {
      color: red;
      font-size: 12px;
    }
  }
  input,
  select {
    display: block;
    height: 42px;
    width: 100%;
    padding-left: 15px;
    border-radius: 3px;
    border: 1px solid $colorGrisBorder;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  .postalCode {
    width: 25%;
  }
  @media only screen and (max-width: $md) {
    .postalCode {
      width: 48%;
    }
  }
}
</style>
