import axios from 'axios';
import authHeader from './AuthHeader';
import authHeaderAdmin from './AuthHeaderAdmin';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import { UserUpdateDTO } from '@/types/store/DTO/UserUpdateDTO';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';
const API_URL = process.env.VUE_APP_BACKEND_URL;
class UserService {
  //exemple utilisation sans header
  getData() {
    return axios.get(API_URL + 'all');
  }
  commander(data: CommandeDTO) {
    return axios.post(API_URL + 'commander', data, { headers: authHeader() });
  }
  commanderPermis(data: CommandePermisDTO) {
    return axios.post(API_URL + 'commanderPermis', data, {
      headers: authHeader(),
    });
  }
  reclamerCommande(data: object) {
    return axios.post(API_URL + 'reclamerCommande', data, {
      headers: authHeaderAdmin(),
    });
  }
  updateUser(data: UserUpdateDTO) {
    return axios.post(API_URL + 'update', data, { headers: authHeader() });
  }
  verifIfUserHaveCommandDeer() {
    return axios.get(API_URL + 'doesUserHaveCommandDeer', {
      headers: authHeader(),
    });
  }
  verifIfUserHaveCommandFish() {
    return axios.get(API_URL + 'doesUserHaveCommandFish', {
      headers: authHeader(),
    });
  }
  verifIfUserHaveCommandSaumon() {
    return axios.get(API_URL + 'doesUserHaveCommandSaumon', {
      headers: authHeader(),
    });
  }
  getCoupons(data) {
    return axios.post(API_URL + 'getCoupons', data, { headers: authHeader() });
  }
  getUserPermis() {
    return axios.get(API_URL + 'getUserPermis', {
      headers: authHeader(),
    });
  }
  getSiteDistribution() {
    return axios.get(API_URL + 'getSiteDistribution');
  }
  getAllPermis() {
    return axios.get(API_URL + 'getAllPermis');
  }
  inscriptionAssemblee(data: InscriptionAssembleeDTO) {
    return axios.post(API_URL + 'inscriptionAssemblee', data, {
      headers: authHeader(),
    });
  }
  inscriptionAssembleeAdmin(data: InscriptionAssembleeDTO) {
    return axios.post(API_URL + 'inscriptionAssembleeAdmin', data, {
      headers: authHeaderAdmin(),
    });
  }
  getAllProduitsDC() {
    return axios.get(API_URL + 'getAllProduitsDC');
  }
  updateSiteDistribution(data) {
    return axios.post(API_URL + 'updateSiteDistribution', data, {
      headers: authHeader(),
    });
  }
  getUserCouponPromo(data: object) {
    return axios.post(API_URL + 'getUserCouponPromo', data, {
      headers: authHeader(),
    });
  }
}
export default new UserService();
