<script setup></script>
<template>
  <div>
    <h1>Espace vidéo</h1>
    <div class="grid">
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 1</h1>
        <video controls>
          <source src="/videos/Larry_1.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 2</h1>
        <video controls>
          <source src="/videos/Larry_2.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 3</h1>
        <video controls>
          <source src="/videos/Maxime_Lizotte_Chapitre1.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 4</h1>
        <video controls>
          <source src="/videos/marco_final.mov" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 5</h1>
        <video controls>
          <source src="/videos/caroline.mov" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 6</h1>
        <video controls>
          <source src="/videos/larry_chapitre_4.mov" type="video/mp4" />
        </video>
      </div>
      <!-- <div>
        <h1 style="margin-left: 0 !important">Vidéo 7</h1>
        <video controls>
          <source src="/videos/alex.mov" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 8</h1>
        <video controls>
          <source src="/videos/laurence.mov" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Vidéo 9</h1>
        <video controls>
          <source src="/videos/nicole.mov" type="video/mp4" />
        </video>
      </div> -->
    </div>
  </div>
</template>
<style scoped>
.grid {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 4rem;
  gap: 2rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
    margin: 1rem;
  }
}

h1 {
  margin: 2rem 4rem;
  @media screen and (max-width: 1024px) {
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
}

video {
  width: 100%;
  height: auto;
}
</style>
