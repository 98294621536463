<template>
  <div class="container">
    <label class="titre">
      {{ $t('distributionCommunautaire') }} {{ new Date().getFullYear() }}
    </label>
    <!--
      <MessageBox
        :HeaderMessage="$t('infosGenerales.titre')"
        :BodyMessage="messageBox"
        :type="boxStyle.Info"
      />
    -->
    <Card>
      <template v-slot:content>
        <DetailBox :menus="menuTab">
          <template v-slot:poisson-body>
            <ul>
              <li>
                {{ $t('poisson.liste.item1') }}
              </li>
              <li>{{ $t('poisson.liste.item2') }}</li>
              <li>
                {{ $t('poisson.liste.item3') }}
              </li>
            </ul>
            <p class="titre1">{{ $t('poisson.apercu.titre') }}</p>
            <p class="text1">
              {{ $t('poisson.apercu.contenu') }}
            </p>
          </template>
          <template v-slot:gibier-body>
            <ul>
              <li>{{ $t('gibier.liste.item1') }}</li>
              <li>{{ $t('gibier.liste.item2') }}</li>
            </ul>
            <p class="titre1">{{ $t('gibier.apercu.titre') }}</p>
            <p class="text1">{{ $t('gibier.apercu.contenu') }}</p>
          </template>
          <template v-slot:poisson-footer>
            <p class="foot">
              {{ $t('poisson.valeur') }}
            </p>
          </template>
        </DetailBox>
      </template>
    </Card>
    <Card :icon="'box-open'" :title="$t('formulaireDemande')">
      <template v-slot:content>
        <ApplicationForm />
      </template>
    </Card>
    <Card :icon="'hand-holding-box'" :title="$t('adressesDistribution')">
      <template v-slot:content>
        <AdressesDistribution />
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ApplicationForm from '@/components/ApplicationForm.vue';
import AdressesDistribution from '@/components/AdressesDistribution.vue';
import DetailBox from '@/components/DetailBox.vue';
//import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import Card from '@/components/Card.vue';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'CommunityDistribution',
  components: {
    DetailBox,
    ApplicationForm,
    AdressesDistribution,
    Card,
  },
  data() {
    return {
      boxStyle: MessageBoxType,
      name: 'Boite de poisson',
      menuTab: [
        {
          id: 'poisson',
          iconStyle: 'far',
          icon: 'fish',
          menuName: this.$t('boite.poisson'),
        },
        {
          id: 'gibier',
          iconStyle: 'fal',
          icon: 'deer',
          menuName: this.$t('boite.gibier'),
        },
      ],
      messageBox: this.$t('infosGenerales.contenu'),
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        adressesDistribution: 'Addresses of distribution sites',
        formulaireDemande: 'Application form',
        infosGenerales: {
          titre: 'General information',
          contenu:
            'The community distribution will take place during the week of December 11 to 17, 2022. All the information necessary for the recovery of the boxes will be communicated to you by telephone during the week of November 20, 2022.',
        },
        poisson: {
          liste: {
            item1:
              'All members who complete the application form and pay the $20 fee will receive a box of fish.',
            item2: 'Only one request per mailing address will be accepted.',
            item3:
              'Payments must be made by check, money order or credit card (internet procedure only) at the time of your request.',
          },
          apercu: {
            titre: 'OVERVIEW OF FISH BOX CONTENTS',
            contenu:
              'Nordic shrimp, lobster meat, turbot, halibut steak, crab meat, salmon jerky, salmon fillet, sliced smoked salmon, fish spices, redfish fillet, seafood sauce, maple syrup, whelk )',
          },
          valeur: 'Approximate market value of box of fish: 325 $',
        },
        gibier: {
          liste: {
            item1: 'Only one request per mailing address will be accepted.',
            item2: 'There is no charge for game boxes',
          },
          apercu: {
            titre: 'OVERVIEW OF GAME BOX CONTENTS',
            contenu: 'Moose',
          },
        },
      },
      fr: {
        adressesDistribution: 'Adresses des sites de distribution',
        formulaireDemande: 'Formulaire de demande',
        infosGenerales: {
          titre: 'Informations générales',
          contenu:
            'La distribution communautaire aura lieu dans la semaine du 11 au 17 décembre 2022. Toutes les informations nécessaires pour la récupération des boites vous seront communiquées par téléphone dans la semaine du 20 novembre 2022.',
        },
        poisson: {
          liste: {
            item1:
              'Tous les membres qui auront complété le formulaire de demande et qui auront payé le frais de 20$ recevront une boite de poissons.',
            item2: 'Une seule demande par adresse postale sera acceptée.',
            item3:
              'Les paiements devront être effectués par chèque, mandat-poste ou carte de crédit (procédure par internet seulement) au moment de votre demande.',
          },
          apercu: {
            titre: 'APERÇU DU CONTENU DE LA BOÎTE DE POISSONS',
            contenu:
              'Crevette nordique, Chair de homard, Turbot, Darne de flétan, Chair de crabe, Jerky de saumon, Filet de saumon, Saumon fumé tranché, Épices à poisson, Filet de sébaste, Sauce fruits de mer, Sirop d’érable, Buccin(bourgot)',
          },
          valeur:
            'Valeur marchande approximative de la boîte de poissons : 325 $',
        },
        gibier: {
          liste: {
            item1: 'Une seule demande par adresse postale sera acceptée.',
            item2: 'Il n’y a pas de frais pour les boites de gibier',
          },
          apercu: {
            titre: 'APERÇU DU CONTENU DE LA BOÎTE DE GIBIER',
            contenu: 'Orignal',
          },
        },
      },
    },
  },
});
</script>
<style lang="scss" scoped>
.titre {
  color: #273566;
  font-size: 26px;
  margin-top: 44px;
  font-weight: 700;
  margin-bottom: 41px;
}
ul {
  margin-bottom: 0;
  margin-left: 20px;
  padding: 0;
}
.titre1 {
  margin-top: 20px;
  font-weight: bold;
}
.text1 {
  font-style: italic;
}
.text2 {
  margin-top: 20px;
}
span {
  color: $rougeFonce2;
}
.foot {
  font-weight: bold;
}
</style>
