import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55f87c8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "second-box" }
const _hoisted_2 = { class: "navigator" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "content-box" }
const _hoisted_5 = { class: "infos" }
const _hoisted_6 = {
  key: 0,
  class: "footer-box"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["selector", { selected: _ctx.selectedBox == menu.id }]),
          key: menu.menuName,
          onClick: ($event: any) => (_ctx.selectBox(menu.id))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: [menu.iconStyle, menu.icon],
            class: "ico"
          }, null, 8, ["icon"]),
          _createElementVNode("span", null, [
            _renderSlot(_ctx.$slots, menu.id + '-header', { menu: menu }, () => [
              _createTextVNode(_toDisplayString(menu.menuName), 1)
            ], true)
          ])
        ], 10, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menus, (menu) => {
          return (_openBlock(), _createElementBlock("div", {
            key: menu.menuName
          }, [
            (_ctx.selectedBox === menu.id)
              ? _renderSlot(_ctx.$slots, menu.id + '-body', {
                  key: 0,
                  menu: menu
                }, undefined, true)
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      (_ctx.selectedBox == 'poisson')
        ? (_openBlock(), _createElementBlock("footer", _hoisted_6, [
            _renderSlot(_ctx.$slots, 'poisson-footer', {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}