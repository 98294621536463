<template>
  <div>
    <h1>{{ $t('reponsesCandidatsElections') }}</h1>
    <div class="grid">
      <div>
        <h1 style="margin-left: 0 !important">Isabelle Baron</h1>
        <video controls>
          <source src="/videos/Reponses_Isabelle_Baron.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Kevin Morais</h1>
        <video controls>
          <source src="/videos/Reponses_Kevin_Morais.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Martine Bruneau</h1>
        <video controls>
          <source src="/videos/Reponses_Martine_Bruneau.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Nancy St-Gelais</h1>
        <video controls>
          <source src="/videos/Reponses_Nancy_St-Gelais.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Nelson Lepage</h1>
        <video controls>
          <source src="/videos/Reponses_Nelson_Lepage.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Pascale Dallaire</h1>
        <video controls>
          <source
            src="/videos/Reponses_Pascale_Dallaire.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Patrick Savoie</h1>
        <video controls>
          <source src="/videos/Reponses_Patrick_Savoie.mp4" type="video/mp4" />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Pierre-Luc Gauthier</h1>
        <video controls>
          <source
            src="/videos/Reponses_Pierre-Luc_Gauthier.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Richard Bélanger</h1>
        <video controls>
          <source
            src="/videos/Reponses_Richard_Belanger.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div>
        <h1 style="margin-left: 0 !important">Shirley Kennedy</h1>
        <video controls>
          <source src="/videos/Reponses_Shirley_Kennedy.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'ReponsesCandidatsElections',
  i18n: {
    sharedMessages: commonMessages,
  },
});
</script>

<style scoped>
.grid {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  width: 90%;
  margin: 4rem;
  gap: 2rem;
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
    margin: 1rem;
  }
}

.w-full {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .cols-3 {
    grid-column: span 3;
  }
}

label {
  color: #274b93;
  font-weight: 500;
  display: block;
}

h1 {
  margin: 2rem 4rem;
  @media screen and (max-width: 1024px) {
    margin: 2rem;
  }
  @media screen and (max-width: 768px) {
    margin: 1rem;
  }
}

video {
  width: 100%;
  height: auto;
}
</style>
