<template>
  <p>
    {{ $t('consigne') }}
  </p>
  <div class="content-boite">
    <div class="boite">
      <span>
        <input
          type="checkbox"
          v-model="poisson"
          @change="addRemoveFishBasket"
          :checked="poisson"
        />
        <label>{{ $t('boite.poisson') }}</label>
      </span>
      <font-awesome-icon icon="fish" class="ico fish" />
    </div>
    <div class="boite b2">
      <span>
        <input
          type="checkbox"
          v-model="gibier"
          @change="addRemoveGibierBasket"
          :checked="gibier"
        />
        <label>{{ $t('boite.gibier') }}</label>
      </span>
      <font-awesome-icon icon="deer" class="ico gibier" />
    </div>
  </div>

  <div class="content-boite" v-if="getAge() >= 75">
    <div class="boite">
      <span>
        <input
          type="checkbox"
          v-model="saumon"
          @change="addRemoveSaumonBasket"
          :checked="saumon"
        />
        <label>{{ $t('boite.saumon') }}</label>
      </span>
      <img
        class="saumon-icon"
        src="../assets/img/icon-saumon.svg"
        alt="saumon"
      />
    </div>
  </div>

  <button
    class="btn-demande"
    :disabled="isDisabled"
    :class="{ isActive: !isDisabled }"
    @click="goToConfimation"
  >
    <font-awesome-icon :icon="[ico, 'box-circle-check']" />
    {{ $t('faireDemande') }}
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import commonMessages from '../locales/common';
import { ProduitDCDTO } from '@/types/store/DTO/ProduitDCDTO';
export default defineComponent({
  name: 'ApplicationFrom',
  data() {
    return {
      // poisson: false,
      // gibier: false,
      // saumon: false,
      isDisabled: true,
      ico: 'far',
      panier: {} as Panier,
      produitsDC: {} as Array<ProduitDCDTO>,
      cart: [] as Array<ProduitDCDTO>,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        consigne: 'Indicate the desired box(es) ($20 fee for the box of fish):',
        faireDemande: 'Make a request',
      },
      fr: {
        consigne:
          'Indiquez la ou les boîtes désirées (frais de 20$ pour la boite de poisson) :',
        faireDemande: 'Faire la demande',
      },
    },
  },
  methods: {
    checkDisabled() {
      if (!this.poisson && !this.gibier && !this.saumon) {
        this.isDisabled = true;
        this.ico = 'far';
      } else {
        this.isDisabled = false;
        this.ico = 'fas';
      }
    },
    addRemoveFishBasket() {
      this.panier.poisson = this.poisson;
      sessionStorage.panier = JSON.stringify(this.panier);
      this.checkDisabled();
    },
    addRemoveSaumonBasket() {
      this.panier.saumon = this.saumon;
      sessionStorage.panier = JSON.stringify(this.panier);
      this.checkDisabled();
    },
    addRemoveGibierBasket() {
      this.panier.gibier = this.gibier;
      sessionStorage.panier = JSON.stringify(this.panier);
      this.checkDisabled();
    },
    goToConfimation() {
      this.$router.push('/order-validation');
    },
    getAge() {
      return sessionStorage.age;
    },
  },
  created() {
    if (sessionStorage.panier != undefined) {
      this.panier = JSON.parse(sessionStorage.panier);
      this.poisson = this.panier.poisson;
      this.gibier = this.panier.gibier;
      this.saumon = this.panier.saumon;
    }
    this.checkDisabled();
  },
});
</script>

<style scoped lang="scss">
.header-box {
  color: #274b93;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #acbdde;
  display: flex;
  .ico {
    font-size: 30px;
    align-self: center;
  }
  span {
    margin-left: 10px;
    font-size: 17px;
    font-weight: 700;
    align-self: center;
  }
}
p {
  font-weight: 500;
}
.btn-demande {
  margin-top: 20px;
  background-color: white;
  border: 1px solid $grisFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: $grisFonce;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.isActive {
  background-color: $bleuFonce;
  color: white;
  .ico {
    margin-right: 5px;
  }
}
.fish {
  font-size: 24px;
  color: $bleuFonce;
  align-self: center;
}
.gibier {
  font-size: 24px;
  color: $rougeFonce;
  align-self: center;
}
.content-boite {
  display: flex;
  .saumon-icon {
    width: 2em;
  }
  .boite {
    margin-top: 20px;
    display: flex;
    img {
    }
    span {
      margin-right: 20px;
      display: flex;
      input {
        margin-right: 10px;
        width: 19px;
        height: 19px;
        align-self: center;
      }
      label {
        font-size: 20px;
        color: $bleu;
        align-self: center;
      }
    }
  }
  @media only screen and (min-width: $lg) {
    .b2 {
      margin-left: 30%;
    }
  }
  @media only screen and (max-width: $lg) {
    .b2 {
      margin-left: 10%;
    }
  }
}
@media only screen and (max-width: $md) {
  .content-boite {
    display: block;
    .b2 {
      margin-left: 0%;
    }
    .boite {
      span {
        width: 160px;
        label {
          font-size: 17px;
        }
      }
    }
  }
}
</style>
