<template>
  <div>
    <Card>
      <template v-slot:content>
        Du
        <input type="date" v-model="minDate" @change="checkDisabled" /> Au
        <input type="date" v-model="maxDate" @change="checkDisabled" />
        <button
          class="btn-rapport btn-rigth"
          :disabled="isDisabled"
          :class="{ notActivated: isDisabled }"
          @click="getRapportsWithDates"
        >
          <font-awesome-icon :icon="[ico, 'filter']" />
          Filtrer
        </button>
      </template>
    </Card>
    <Card>
      <template v-slot:content>
        <div class="cherch-btn">
          <input
            type="text"
            placeholder="Rechercher par nom, téléphone…"
            v-model="search"
          />
          <button class="recherche" @click="filter">
            <font-awesome-icon :icon="[ico, 'magnifying-glass']" />
          </button>
        </div>
        <p class="result">Résultats</p>
        <DataTable
          :value="rappots"
          :paginator="true"
          :rows="10"
          ref="dt"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} COMMANDES"
        >
          <Column header="DATE">
            <template #body="{ data }">
              {{ data.created_at.slice(0, 10) }}
            </template>
          </Column>
          <Column header="NUMÉRO DE REÇU">
            <template #body="{ data }">
              {{ data.commande.squareReceiptNumber }}
            </template>
          </Column>
          <Column field="id" header="Nº COMMANDE">
            <template #body="{ data }">
              {{ padLeadingZeros(data.commande_id) }}
            </template>
          </Column>
          <Column header="NOM">
            <template #body="{ data }">
              {{ data.ssamtauser.firstName }} <br />
              {{ data.ssamtauser.lastName }}
            </template>
          </Column>
          <Column header="BOITE">
            <template #body="{ data }">
              <font-awesome-icon
                v-if="data.produit.nom === 'Boîte de poisson'"
                icon="fish"
                class="ico"
                v-bind:class="{ showIcon: true }"
              />
              <font-awesome-icon
                v-if="data.produit.nom === 'Boîte de gibier'"
                icon="deer"
                class="ico gibier"
                v-bind:class="{ showIcon: true }"
              />
              <img
                v-if="data.produit.nom === 'Boîte de saumon'"
                class="saumon-icon"
                src="../../assets/img/icon-saumon.svg"
                alt="saumon"
                v-bind:class="{ showIcon: true }"
              />
            </template>
          </Column>
          <Column header="ADRESSE">
            <template #body="{ data }">
              {{ data.ssamtauser.address }} <br />
              {{ data.ssamtauser.city }} <br />
              {{ data.ssamtauser.postalCode }}
            </template>
          </Column>
          <Column header="TÉLÉPHONE">
            <template #body="{ data }">
              {{ data.ssamtauser.telephone }}
            </template>
          </Column>
          <Column header="CELLULAIRE">
            <template #body="{ data }">
              {{ data.ssamtauser.mobile }}
            </template>
          </Column>
          <Column header="COURRIEL">
            <template #body="{ data }">
              {{ data.ssamtauser.email }}
            </template>
          </Column>
          <Column header="GÉNÉRATION">
            <template #body="{ data }">
              <div v-if="data.commande.created_by_id">Manuelle</div>
              <div v-else>Automatique</div>
            </template>
          </Column>
          <template #paginatorstart>
            <button class="btn-rapport" @click="exportCSV">
              <font-awesome-icon :icon="[ico, 'file-export']" />
              Exporter en CSV
            </button>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'RapportCs',
  components: { DataTable, Column, Card },
  data() {
    return {
      rappots: [],
      copieRappots: [],
      ico: 'far',
      minDate: null,
      maxDate: null,
      isDisabled: true,
      search: '',
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getRapportsWithDates() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/commandesSupprimeesWithDate', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then((data: Array<CommandeDTO>) => {
            this.rappots = data;
            this.copieRappots = this.rappots;
          });
      }
    },
    checkDisabled() {
      if (this.minDate == null || this.maxDate == null) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    exportCSV() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$store
          .dispatch('Rapports/getCommandesSupprimeesCsvWithDates', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then();
      } else {
        this.$store.dispatch('Rapports/getCommandesSupprimeesCsv').then();
      }
    },
    allCommandes() {
      this.$store.dispatch('Rapports/getAllDeletedCommandes').then((data) => {
        this.rappots = data;
        this.copieRappots = this.rappots;
        console.log(data);
      });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      const hasReciepNumber = commande.commande.squarePaymentId
        ? this.getIfStringMatchSearch(commande.commande.squarePaymentId)
        : false;
      return (
        this.getIfStringMatchSearch(
          this.padLeadingZeros(commande.commande.id)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.lastName) ||
        this.getIfStringMatchSearch(commande.ssamtauser.email) ||
        this.getIfStringMatchSearch(
          dateParserLong(commande.commande.created_at)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtauser.memberId) ||
        this.getIfStringMatchSearch(commande.ssamtauser.adress) ||
        this.getIfStringMatchSearch(commande.ssamtauser.city) ||
        this.getIfStringMatchSearch(commande.ssamtauser.postalCode) ||
        hasReciepNumber
      );
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
  },
  created() {
    this.allCommandes();
  },
});
</script>

<style scoped lang="scss">
.ico {
  font-size: 20px;
  color: $bleuFonce;
  opacity: 0;
}
.result {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid $bleuFonce;
  color: $bleuFonce;
  font-weight: bold;
}
.gibier {
  margin-left: 15px;
  color: $rougeFonce;
}
.saumon-icon {
  opacity: 0;
  width: 2em;
  margin-left: 15px;
}
.showIcon {
  opacity: 1;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
input {
  height: 42px;
  border: 1px solid #cecece;
  padding-left: 10px;
}
.btn-rigth {
  margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.cherch-btn {
  margin-top: 20px;
  input {
    height: 42px;
    width: 30%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.button {
  background-color: $bleuFonce;
  border: none;
  color: white;
  width: 13%;
  height: 90%;
  @media screen and (max-width: 1750px) {
    width: 30%;
  }
}
</style>
