<template>
  <div class="page-coupons">
    <div class="titre">
      <h1>{{ $t('mesCoupons') }}</h1>
    </div>
    <div class="contenu">
      <div class="texte">
        <p>{{ $t('consigneCoupons') }}</p>
        <div class="choix">
          <div class="checkboxes couponsBox">
            <div v-for="produit in produits" :key="produit.id">
              <span class="bottom-margin"
                >Coupon de {{ produit.nom.toLowerCase() }}

                <font-awesome-icon
                  v-if="produit.type === 'poisson'"
                  :icon="['far', 'fish']"
                  class="fish-icon"
                />
                <font-awesome-icon
                  v-else-if="produit.type === 'gibier'"
                  :icon="['fal', 'deer']"
                  class="steak-icon"
                />
                <img
                  v-else-if="produit.type === 'saumon'"
                  class="saumon-icon"
                  src="../assets/img/icon-saumon-noir.svg"
                  alt="saumon"
                />
              </span>
              <div class="bouton-telecharger">
                <a
                  class="button"
                  :href="getUrlCoupon(produit)"
                  download="couponPoisson.pdf"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    class="download-icon"
                  />
                  {{ $t('telecharger') }}
                </a>
              </div>
            </div>
            <!-- <div class="poisson" v-if="poissonId">
              <span class="bottom-margin"
                >{{ $t('coupons.poisson') }}
                <font-awesome-icon :icon="['far', 'fish']" class="fish-icon" />
              </span>
              <div class="bouton-telecharger">
                <a
                  class="button"
                  :href="getURLPoisson"
                  download="couponPoisson.pdf"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    class="download-icon"
                  />
                  {{ $t('telecharger') }}
                </a>
              </div>
            </div>
            <div class="viande" v-if="gibierId">
              <span class="bottom-margin"
                >{{ $t('coupons.gibier') }}
                <font-awesome-icon :icon="['fal', 'deer']" class="steak-icon"
              /></span>
              <div class="bouton-telecharger">
                <a
                  class="button"
                  :href="getURLViande"
                  download="couponViande.pdf"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    class="download-icon"
                  />
                  {{ $t('telecharger') }}
                </a>
              </div>
            </div>
            <div class="viande" v-if="saumonId">
              <span class="bottom-margin"
                >{{ $t('coupons.saumon') }}
                <img
                  class="saumon-icon"
                  src="../assets/img/icon-saumon-noir.svg"
                  alt="saumon"
                />
              </span>
              <div class="bouton-telecharger">
                <a
                  class="button"
                  :href="getURLSaumon"
                  download="couponSaumon.pdf"
                >
                  <font-awesome-icon
                    :icon="['fas', 'download']"
                    class="download-icon"
                  />
                  {{ $t('telecharger') }}
                </a>
              </div>
            </div> -->
            <div class="no-coupons" v-if="produits.length < 0">
              <span>{{ $t('aucunCoupons') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="container-site"
      v-if="produits.length > 0 && isBeforeDateLimite"
    >
      <div class="form-site">
        <label for="site">
          {{ $t('siteDistribution') }}
        </label>
        <Dropdown
          :options="sitesDistribution"
          style="width: 100%"
          v-model="selectedSite"
          optionLabel="label"
          optionValue="value"
          filter
        >
          <!-- <option
            v-for="site in sitesDistribution"
            :key="site.id"
            :value="site.id"
          >
            {{ site.ville.toUpperCase() }} - {{ site.lieux }}
          </option> -->
        </Dropdown>
        <Button class="button" @click="updateSiteDistribution">
          {{ $t('boutons.sauvegarder') }}
        </Button>
      </div>
    </div>
    <Toast />
  </div>
</template>
<script lang="ts">
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { defineComponent } from 'vue';
import commonMessages from '../locales/common';
import Dropdown from 'primevue/dropdown';
import Toast from 'primevue/toast';
export default defineComponent({
  name: 'Coupons',
  components: { Dropdown, Toast },
  data() {
    return {
      poissonId: '',
      gibierId: '',
      saumonId: '',
      produits: [],
      sitesDistribution: [],
      dateLimiteChangementSite: new Date('2024-11-08'),
      selectedSite: '',
      commande_id: null,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        consigneCoupons: 'Check the coupons to download',
        coupons: {
          saumon: 'Salmon box coupon',
          poisson: 'Fish box coupon',
          gibier: 'Game box coupon',
        },
        telecharger: 'Download',
        aucunCoupons: "You don't have coupons",
        siteDistribution: 'Distribution site',
        dateLimiteChangementSiteDepassee:
          'The deadline to change site is passed.',
        siteDistributionSauvegarde: 'Distribution site saved.',
      },
      fr: {
        consigneCoupons: 'Veuillez cocher les coupons à télécharger.',
        coupons: {
          saumon: 'Coupon de boîte de saumon',
          poisson: 'Coupon de boîte de poisson',
          gibier: 'Coupon de boîte de gibier',
        },
        telecharger: 'Télécharger',
        aucunCoupons: "Vous n'avez pas de coupons",
        siteDistribution: 'Site de distribution',
        dateLimiteChangementSiteDepassee:
          'La date limite pour changer de site est dépassée.',
        siteDistributionSauvegarde: 'Site de distribution sauvegardé.',
      },
    },
  },
  computed: {
    isBeforeDateLimite() {
      let date = new Date();
      return date < this.dateLimiteChangementSite;
    },
    getURLPoisson(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponPoisson/' + this.poissonId
      );
    },
    getURLViande(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponGibier/' + this.gibierId
      );
    },
    getURLSaumon(): string {
      return (
        process.env.VUE_APP_BACKEND_URL + 'getCouponSaumon/' + this.saumonId
      );
    },
  },
  created() {
    this.$store.dispatch('Commande/getCoupons').then((data) => {
      this.produits = data.coupons;
      this.selectedSite = data.site;
      this.commande_id = data.commande_id;
    });

    this.$store
      .dispatch('SiteDistribution/getSiteDistribution')
      .then((data) => {
        console.log(data);
        data.forEach((site) => {
          this.sitesDistribution.push({
            label: site.ville.toUpperCase() + ' - ' + site.lieux,
            value: site.id,
          });
        });
      });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandDeer')
    //   .then((data: CommandeDTO) => {
    //     this.gibierId = data.uuid;
    //   });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandFish')
    //   .then((data: CommandeDTO) => {
    //     this.poissonId = data.uuid;
    //   });
    // this.$store
    //   .dispatch('Commande/verifIfUserHaveCommandSaumon')
    //   .then((data: CommandeDTO) => {
    //     this.saumonId = data.uuid;
    //   });
  },
  methods: {
    getUrlCoupon(data) {
      return (
        process.env.VUE_APP_BACKEND_URL +
        'getCoupon/' +
        data.pivot.commande_id +
        '/' +
        data.pivot.uuid +
        '/' +
        data.type
      );
    },
    updateSiteDistribution() {
      if (!this.isBeforeDateLimite) {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('erreur'),
          detail: this.$t('dateLimiteChangementSiteDepassee'),
        });
        return;
      }

      if (this.selectedSite !== null) {
        this.$store
          .dispatch('Commande/updateSiteDistribution', {
            commande_id: this.commande_id,
            site_distribution_id: this.selectedSite,
            memberId: JSON.parse(sessionStorage.user).memberId,
          })
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: this.$t('succes'),
              detail: this.$t('siteDistributionSauvegarde'),
            });
          });
      } else {
        this.$toast.add({
          severity: 'error',
          summary: this.$t('erreur'),
          detail: this.$t('siteDistributionNonSelectionne'),
        });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.couponsBox {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.titre {
  text-align: center;
  margin: 1.5%;
}
.saumon-icon {
  width: 2em;
}
.contenu {
  display: flex;
  margin: auto;
  justify-content: space;
  width: 55%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
  height: 100%;
  background-color: $white;
  padding: 10px;
  margin-bottom: 2%;
  @media screen and (max-width: $md) {
    width: 80%;
  }
}
.checkboxes {
  display: inline-flex;
  width: 100%;
}
.poisson,
.viande {
  width: 50%;
  margin: 2% 0% 1% 0%;
}
.texte {
  width: 100%;
  margin-left: 1%;
  margin-top: 1%;
}
.button {
  background-color: $bleu;
  color: $white;
  border: 1px solid $bleuCiel;
  padding: 1% 1.7% 1% 1.7%;
  margin-top: 1%;
}
.download-icon {
  color: $white;
  width: 20px;
  height: 21px;
}
input {
  transform: scale(1.5);
  margin-right: 3%;
  border-radius: 3px;
  margin-left: 0.5%;
  accent-color: $bleuFonce;
}
label {
  font-family: $police;
  color: $textRapport;
  font-weight: 400;
}
.fish-icon,
.steak-icon {
  transform: scale(1.5);
  margin-left: 2%;
}
.bottom-margin {
  padding-bottom: 5px;
}
.bouton-telecharger {
  margin-top: 2%;
}

.container-site {
  background-color: $white;
  box-shadow: 0px 0px 6px #87a8ea21;
  border-radius: 4px;
  padding: 10px;
  width: 55%;
  margin: auto;
  margin-top: 2%;
  @media screen and (max-width: $md) {
    width: 80%;
  }
}
</style>
