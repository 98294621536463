import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-630d60d7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "titre" }
const _hoisted_3 = { class: "titre1" }
const _hoisted_4 = { class: "text1" }
const _hoisted_5 = { class: "titre1" }
const _hoisted_6 = { class: "text1" }
const _hoisted_7 = { class: "foot" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailBox = _resolveComponent("DetailBox")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ApplicationForm = _resolveComponent("ApplicationForm")!
  const _component_AdressesDistribution = _resolveComponent("AdressesDistribution")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('distributionCommunautaire')) + " " + _toDisplayString(new Date().getFullYear()), 1),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_DetailBox, { menus: _ctx.menuTab }, {
          "poisson-body": _withCtx(() => [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('poisson.liste.item1')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('poisson.liste.item2')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('poisson.liste.item3')), 1)
            ]),
            _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('poisson.apercu.titre')), 1),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('poisson.apercu.contenu')), 1)
          ]),
          "gibier-body": _withCtx(() => [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('gibier.liste.item1')), 1),
              _createElementVNode("li", null, _toDisplayString(_ctx.$t('gibier.liste.item2')), 1)
            ]),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('gibier.apercu.titre')), 1),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t('gibier.apercu.contenu')), 1)
          ]),
          "poisson-footer": _withCtx(() => [
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('poisson.valeur')), 1)
          ]),
          _: 1
        }, 8, ["menus"])
      ]),
      _: 1
    }),
    _createVNode(_component_Card, {
      icon: 'box-open',
      title: _ctx.$t('formulaireDemande')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_ApplicationForm)
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Card, {
      icon: 'hand-holding-box',
      title: _ctx.$t('adressesDistribution')
    }, {
      content: _withCtx(() => [
        _createVNode(_component_AdressesDistribution)
      ]),
      _: 1
    }, 8, ["title"])
  ]))
}