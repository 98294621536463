<template>
  <div class="container-footer">
    <div class="divFooter">
      <div class="elementFooterHaut" id="elementFooterHautGauche">
        <h3>{{ $t('besoinAide') }}</h3>
        <h2>
          {{ $t('contact') }}
          <span class="lienTel">
            <a href="tel:+118883992393"> <br class="br" />1 888 399-2393</a>
          </span>
        </h2>
      </div>
      <div class="elementFooterHaut" id="elementFooterHautDroit">
        <div class="img-container">
          <img
            src="@/assets/img/footer.png"
            class="logoFooter"
            alt="logo malecitesFooter"
          />
        </div>
      </div>
    </div>
    <div class="barreBlancheFooter">
      <div class="elementFooter">
        <p class="admin">
          <router-link :to="{ name: 'LoginAdmin' }">
            {{ $t('administration') }}
          </router-link>
        </p>
      </div>
      <div class="elementFooter">
        <p class="copyright">
          {{ $t('copyright') }}
          <a href="https://conceptionwm.com/">Conception WebMédia.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  i18n: {
    messages: {
      en: {
        besoinAide: 'Need help?',
        contact: 'Contact us :',
        administration: 'Administration',
        copyright: '© Wolastoqiyik 2022. Portal designed by ',
      },
      fr: {
        besoinAide: "Besoin d'aide?",
        contact: 'Contactez-nous :',
        administration: 'Administration',
        copyright: '© Wolastoqiyik 2022. Portail conçu par ',
      },
    },
  },
  data() {
    return {
      userLoggedIn: false,
    };
  },
  methods: {
    getUserConnexionStatus() {
      this.userLoggedIn = this.$store.getters['Auth/isLoggedIn'];
    },
  },
  created() {
    this.getUserConnexionStatus;
  },
});
</script>

<style scoped lang="scss">
.container-footer {
  .divFooter {
    background-color: #d2d8ee;
    display: flex;
    width: 100%;
    h3 {
      margin-left: 6%;
      padding-top: 0.8vw;
    }

    h2 {
      margin-left: 6%;
      margin-top: 0.5rem;
      padding-bottom: 1rem;
    }
  }
  .br {
    display: none;
  }
  .lienTel a {
    font-size: 1.5rem;
    margin-left: 0.5rem;
  }
  .barreBlancheFooter {
    background-color: white;
    height: 5vh;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
  }
  .elementFooter {
    width: 50%;
  }
  .elementFooterHaut {
    width: 50%;
  }
  .admin {
    margin-left: 6%;
  }
  .admin a:hover,
  .copyright a:hover {
    text-decoration: underline;
  }
  #elementFooterHautDroit {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .img-container {
    display: flex;
    justify-content: flex-end;
  }
  .logoFooter {
    height: 145px;
    width: 208px;
    margin-top: -5%;
    margin-right: 3%;
  }
  .copyright {
    float: right;
    margin-right: 6%;
  }
  @media screen and (max-width: 1000px) {
    .barreBlancheFooter {
      flex-direction: column;
    }
    .elementFooter {
      width: 100%;
    }
    .copyright {
      float: left;
      margin-left: 3%;
      font-size: 0.8rem;
    }
    .admin {
      margin-left: 3%;
    }
    .logoFooter {
      bottom: 4.2rem;
    }
    .divFooter {
      height: auto;
    }
  }
  @media screen and (max-width: 675px) {
    .logoFooter {
      height: 120px;
      width: 173px;
      margin-top: -5%;
      margin-right: 3%;
    }
    .br {
      display: block;
    }
    h3 {
      font-size: 22px;
      margin-top: 15px;
    }

    h2 {
      margin-left: 6%;
      margin-top: 0.5rem;
      padding-bottom: 1rem;
    }
  }
}
</style>
