<template>
  <div class="container-message">
    <div :class="'box-message ' + type">
      <div :class="'titre ' + type">
        <font-awesome-icon :icon="['fal', icon]" class="ico" />
        <span>{{ HeaderMessage }}</span>
      </div>
      <p class="sous-titre">
        {{ BodyMessage }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import MessageBoxType from '@/types/enums/MessageBoxType';

export default defineComponent({
  name: 'MessageBox',
  props: {
    type: { type: String as PropType<MessageBoxType>, required: true },
    HeaderMessage: { type: String, required: true },
    BodyMessage: { type: String, required: true },
  },
  data() {
    return {
      icon: '',
    };
  },
  created() {
    switch (this.type) {
      case 'info':
        this.icon = 'circle-info';
        break;
      case 'success':
        this.icon = 'circle-check';
        break;
      case 'warning':
        this.icon = 'circle-exclamation';
        break;
      case 'error':
        this.icon = 'circle-xmark';
        break;
    }
  },
});
</script>

<style scoped lang="scss">
.container-message {
  display: flex;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  .box-message {
    background-color: white;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    text-align: left;
    .titre {
      margin-bottom: 28px;
      display: flex;
      padding-bottom: 15px;
      span {
        margin-left: 10px;
        font-size: 17px;
        align-self: center;
        font-weight: 700;
      }
      .ico {
        font-size: 30px;
        align-self: center;
      }
    }
    .sous-titre {
      font-weight: 500;
      margin-bottom: 9px;
      font-size: 15px;
    }
  }
}
.container-message > .success {
  border: 1px solid $vertClaire;
  border-left: 10px solid $vertClaire;
}
.container-message > .error {
  border: 1px solid $rougeFonce2;
  border-left: 10px solid $rougeFonce2;
}
.container-message > .info {
  border: 1px solid $bleuFonce;
  border-left: 10px solid $bleuFonce;
}
.container-message > .warning {
  border: 1px solid $orange;
  border-left: 10px solid $orange;
}

.box-message > .success {
  color: $vertClaire;
  border-bottom: 2px solid $vertClaire;
}
.box-message > .error {
  color: $rougeFonce2;
  border-bottom: 2px solid $rougleClair;
}
.box-message > .info {
  color: $bleuFonce;
  border-bottom: 2px solid $grisFonce;
}
.box-message > .warning {
  color: $orange;
  border-bottom: 2px solid $orangeFonce;
}
</style>
