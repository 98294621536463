<template>
  <div class="container-login">
    <div class="box-login">
      <p class="titre-admin">{{ $t('admin') }}</p>
      <p class="titre">{{ $t('bienvenue') }}</p>
      <p class="sous-titre">
        {{ $t('loginInfo') }}
      </p>
      <div class="input-container">
        <label for="email" class="form-label">{{ $t('email') }}</label>
        <input type="email" id="email" class="form-input" v-model="email" />
        <div
          class="errorMessage"
          v-for="error of v$.email.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('erreurs.required') }}
          </label>
          <label v-if="error.$validator == 'email'">
            {{ $t('erreurs.email') }}
          </label>
        </div>
      </div>
      <div class="input-container">
        <label for="noMembre" class="form-label">{{ $t('password') }}</label>
        <input
          type="password"
          id="noMembre"
          class="form-input"
          v-model="password"
        />
        <div
          class="errorMessage"
          v-for="error of v$.password.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ $t('erreurs.required') }}
          </label>
        </div>
      </div>
      <div class="input-container">
        <button class="btn-form" @click="submitform">
          <font-awesome-icon :icon="['fas', 'lock']" class="ico-form" />
          {{ $t('boutons.connexion') }}
        </button>
      </div>
      <label v-if="errorConnection" class="errorcnx">{{
        $t('erreurs.infosIncorrectes')
      }}</label>
    </div>
  </div>
</template>

<script lang="ts">
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import EventBus from '../events/EventBus';
import commonMessages from '../locales/common.js';
export default defineComponent({
  name: 'LoginAdmin',
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      email: '',
      password: '',
      requiredMessage: 'Champ requis.',
      errorConnection: false,
      recaptcha: {} as ReCaptchaInstance,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        bienvenue: 'Welcome to our portal',
        loginInfo:
          'To log in as an administrator, please complete the fields below:',
        password: 'Password',
        erreurs: {
          required: 'This field is required.',
          email: 'Invalid email format.',
          infosIncorrectes: 'The information entered is incorrect',
        },
      },
      fr: {
        bienvenue: 'Bienvenue sur notre portail',
        loginInfo:
          "Pour vous connecter en tant qu'administrateur, veuillez compléter les champs ci-dessous :",
        password: 'Mot de passe',
        erreurs: {
          required: 'Ce champ est requis.',
          email: 'Format du courriel invalide.',
          infosIncorrectes: 'Les informations entrées sont incorrectes.',
        },
      },
    },
  },
  validations: {
    email: { required, email },
    password: { required },
  },
  methods: {
    submitform() {
      this.$loading.startLoading();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$loading.stopLoading();
        return;
      }
      this.login();
    },
    login() {
      if (this.email && this.password) {
        this.recaptcha.execute('login').then((token) => {
          this.$store
            .dispatch('Auth/loginAdmin', {
              email: this.email,
              password: this.password,
              recaptcha: token,
            })
            .then(() => {
              this.$loading.stopLoading();
              EventBus.emit('userStatusChange');
              this.$router.push({ name: 'Home' });
            })
            .catch(() => {
              this.errorConnection = true;
              this.$loading.stopLoading();
            });
        });
      }
    },
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    const userLogged = this.$store.getters['Auth/isLoggedIn'];
    if (userLogged) this.$router.push({ name: 'Home' });
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_layout.scss';
.container-login {
  background-image: url('@/assets/img/fondConnexion.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  justify-content: right;
  padding: 0px;
  margin: 0px;
  @include media-breakpoint-down($lg) {
    justify-content: center;
  }
}
.box-login {
  background-color: white;
  width: 464px;
  margin: 66px;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
}
.titre-admin {
  color: #273566;
  font-size: 26px;
  border-bottom: 1px solid #abcdde;
  margin-bottom: 43px;
  padding-bottom: 5px;
}
.titre {
  color: #273566;
  margin-bottom: 21px;
  font-size: 22px;
  font-weight: 800;
}
.sous-titre {
  font-weight: 600;
  margin-bottom: 9px;
}
.form-label {
  display: block;
  color: #274b93;
  font-weight: 500;
}
.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #cecece;
  padding-left: 10px;
}
.form-input:focus {
  outline: none;
}
.input-container {
  margin-top: 15px;
}
.btn-form {
  background-color: #274b93;
  width: 100%;
  margin-top: 10px;
  border-radius: 21px;
  border: 1px solid #abcdde;
  color: white;
  padding: 11px;
  font-weight: 500;
}
.ico-form {
  margin-right: 5px;
}
@media screen and (max-width: 1000px) {
  .container-login {
    justify-content: center;
  }
}

@media screen and (min-width: 1200px) {
  .container-login {
    padding-right: 15%;
  }
}
.errorMessage {
  font-size: 12px;
  color: red;
}
.errorcnx {
  color: red;
  margin-top: 10px;
}
</style>
