class EventBusClass {
  #eventsMap = new Map();
  on(eventName: string, action: unknown) {
    if (!this.#eventsMap.has(eventName)) {
      this.#eventsMap.set(eventName, []);
    }
    const functionList = this.#eventsMap.get(eventName);
    functionList.push(action);
    this.#eventsMap.set(eventName, functionList);
  }
  emit(eventName: string) {
    const functionList = this.#eventsMap.get(eventName);
    for (const element of functionList) {
      element();
    }
  }
}
const EventBus = new EventBusClass();
export default EventBus;
