<template>
  <div class="home">
    <DashBoard />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DashBoard from '@/components/DashBoard.vue';

export default defineComponent({
  name: 'Home',
  components: {
    DashBoard,
  },
});
</script>
