import axios from 'axios';
import authHeader from './AuthHeaderAdmin';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
const API_URL = process.env.VUE_APP_BACKEND_URL;
class UsersService {
  getAllUsers() {
    return axios.get(API_URL + 'getUserList', { headers: authHeader() });
  }
  getUserById(id: string) {
    return axios.post(
      API_URL + 'getUserById',
      { id },
      { headers: authHeader() }
    );
  }
  createUser(data: UsersDTO) {
    return axios.post(API_URL + 'createUser', data, { headers: authHeader() });
  }
  modifyUser(data: UsersDTO) {
    return axios.post(API_URL + 'modifyUser', data, { headers: authHeader() });
  }
  deleteUser(id: string) {
    return axios.delete(API_URL + 'deleteUser', {
      data: { id },
      headers: authHeader(),
    });
  }
  getCommandeById(id: string) {
    return axios.post(
      API_URL + 'getCommandeById',
      { id },
      { headers: authHeader() }
    );
  }
  createCommande(data) {
    return axios.post(API_URL + 'createCommande', data, {
      headers: authHeader(),
    });
  }
  modifyCommande(data) {
    return axios.post(API_URL + 'modifyCommande', data, {
      headers: authHeader(),
    });
  }
  deleteCommande(id: string) {
    return axios.delete(API_URL + 'deleteCommande', {
      data: { id },
      headers: authHeader(),
    });
  }
  adminMe() {
    return axios.get(API_URL + 'admin/me', { headers: authHeader() });
  }
  feedCoupon(data) {
    return axios.post(API_URL + 'feedCoupon', data, {
      headers: authHeader(),
    });
  }
  feedInscription(data) {
    return axios.post(API_URL + 'feedInscription', data, {
      headers: authHeader(),
    });
  }
}
export default new UsersService();
