import { createStore } from 'vuex';
import Auth from './modules/Auth';
import Commande from './modules/Commande';
import Rapports from './modules/Rapports';
import User from './modules/User';
import Users from './modules/Users';
import SiteDistribution from './modules/SiteDistribution';
import InscriptionAssemblee from './modules/InscriptionAssemblee';
import Permis from './modules/Permis';
import ProduitDC from './modules/ProduitDC';

export default createStore({
  modules: {
    Auth,
    Commande,
    Rapports,
    User,
    Users,
    SiteDistribution,
    InscriptionAssemblee,
    Permis,
    ProduitDC,
  },
});
