<template>
  <div id="page_profil">
    <div class="center">
      <h1 class="Titre">{{ $t('updateProfile') }}</h1>
    </div>
    <div class="container-profil">
      <div class="container-profil__blocs mon_profil">
        <h2>{{ $t('monProfil') }}</h2>
        <div class="mon_profil__informations_personelles">
          <p>
            <b>{{ prenom }} {{ nom }}</b>
          </p>
          <p>{{ adresse }}</p>
          <p>{{ ville }}</p>
          <p>{{ codePostal }}</p>
        </div>
        <div class="mon_profil__informations_personelles">
          <p>{{ email }}</p>
          <p>{{ numeroTelephone }}</p>
        </div>
      </div>
      <div class="container-profil__blocs infos_profil">
        <h2>{{ $t('profileInfos') }}</h2>
        <form class="form_user" action="">
          <div class="form_user__prenom">
            <label for="">{{ $t('prenom') }}</label>
            <span>
              <b>{{ prenom }}</b>
            </span>
          </div>
          <div class="form_user__nom">
            <label for="">{{ $t('nom') }}</label>
            <span>
              <b>{{ nom }}</b>
            </span>
          </div>
          <div
            class="form_user__adresse"
            :class="{ error: v$.form.adresse.$errors }"
          >
            <label for="adresse_user">{{ $t('adresse') }}</label>
            <input type="text" id="adresse_user" v-model="form.adresse" />
            <div
              class="input-errors"
              v-for="error of v$.form.adresse.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
            <!-- !//<font-awesome-icon :icon="['fas', 'xmark']" class="ico-form" /> -->
          </div>
          <div
            class="form_user__ville"
            :class="{ error: v$.form.ville.$errors }"
          >
            <label for="ville_user">{{ $t('ville') }}</label>
            <input type="text" id="ville_user" v-model="form.ville" />
            <div
              class="input-errors"
              v-for="error of v$.form.ville.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div
            class="form_user__codePostal"
            :class="{ error: v$.form.code_postal.$errors }"
          >
            <label for="codePostal_user">{{ $t('codePostal') }}</label>
            <input
              type="text"
              id="codePostal_user"
              v-model="form.code_postal"
            />
            <div
              class="input-errors"
              v-for="error of v$.form.code_postal.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div class="form_user__pays" :class="{ error: v$.form.pays.$errors }">
            <label for="pays_user">{{ $t('pays') }}</label>
            <select
              name="pays"
              id="pays_user"
              v-model="form.pays"
              @change="CountryChange"
            >
              <option value="CA">{{ $t('listePays.canada') }}</option>
              <option value="USA">{{ $t('listePays.etatsUnis') }}</option>
              <option value="FR">{{ $t('listePays.france') }}</option>
              <option value="NL">{{ $t('listePays.paysBas') }}</option>
              <option value="BO">{{ $t('listePays.bolivie') }}</option>
              <option value="NZ">{{ $t('listePays.nouvelleZelande') }}</option>
              <option value="JP">{{ $t('listePays.japon') }}</option>
              <option value="X">{{ $t('listePays.autre') }}</option>
            </select>
            <div
              class="input-errors"
              v-for="error of v$.form.pays.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div class="form_user__province" v-if="form.pays == 'CA'">
            <label for="province_user">{{ $t('province') }}</label>
            <select name="Province" id="province_user" v-model="province">
              <option
                v-for="provinces in form.provinces.provinces"
                :value="provinces.id"
                :key="provinces.id"
              >
                {{ provinces.displayName }}
              </option>
            </select>
            <div v-show="errorProvince" class="input-errors">
              <div class="error-msg error-province">Ce champ est requis</div>
            </div>
          </div>
          <div class="form_user__province" v-if="form.pays == 'USA'">
            <label for="province_user">{{ $t('etat') }}</label>
            <select name="Province" id="province_user" v-model="province">
              <option
                v-for="states in form.states.states"
                :value="states.id"
                :key="states.id"
              >
                {{ states.displayName }}
              </option>
            </select>
            <div v-show="errorProvince" class="input-errors">
              <div class="error-msg error-province">Ce champ est requis</div>
            </div>
          </div>
          <div
            class="form_user__courriel"
            :class="{ error: v$.form.email.$errors }"
          >
            <label for="courriel_user">{{ $t('email') }}</label>
            <input type="email" id="courriel_user" v-model="form.email" />
            <div
              class="input-errors"
              v-for="error of v$.form.email.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div
            class="form_user__telephone"
            :class="{ error: v$.form.mobile.$errors }"
          >
            <label for="telephone_user">{{ $t('mobile') }}</label>
            <input
              type="text"
              id="telephone_user"
              v-model="form.mobile"
              v-maska="'(###) ###-####'"
            />
            <div
              class="input-errors"
              v-for="error of v$.form.mobile.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div class="form_user__telephone">
            <label for="telephone_user">{{ $t('telephone') }}</label>
            <input
              type="text"
              id="telephone_user"
              v-model="form.telephone"
              v-maska="'(###) ###-####'"
            />
            <div
              class="input-errors"
              v-for="error of v$.form.telephone.$errors"
              :key="error.$uid"
            >
              <div class="error-msg">
                {{ error.$message }}
              </div>
            </div>
          </div>
          <div class="form_user__submit_button">
            <button
              class="button button-primary"
              type="button"
              @click="submitForm"
            >
              <font-awesome-icon
                :icon="['far', 'floppy-disk']"
                class="disquette"
              />
              {{ $t('boutons.sauvegarder') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import provinces from '../values/provinces.json';
import states from '../values/states.json';
import useVuelidate from '@vuelidate/core';
import { required, email, helpers } from '@vuelidate/validators';
import { UserUpdateDTO } from '@/types/store/DTO/UserUpdateDTO';
import commonMessages from '../locales/common';

export default defineComponent({
  name: 'DashBoard',
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      errorProvince: false,
      nom: this.getStoredInfo('lastName'),
      prenom: this.getStoredInfo('firstName'),
      adresse: this.getStoredInfo('address'),
      ville: this.getStoredInfo('city'),
      codePostal: this.getStoredInfo('postalCode'),
      email: this.getStoredInfo('email'),
      numeroTelephone: this.getStoredInfo('mobile'),
      province: this.getStoredInfo('province'),
      pays: this.getStoredInfo('country'),
      user: {} as UserUpdateDTO,
      form: {
        provinces: provinces,
        states: states,
        adresse: this.getStoredInfo('address'),
        ville: this.getStoredInfo('city'),
        code_postal: this.getStoredInfo('postalCode'),
        pays: this.getStoredInfo('country'),
        province: this.getStoredInfo('province'),
        etat: this.getStoredInfo('province'),
        email: this.getStoredInfo('email'),
        telephone: this.getStoredInfo('telephone'),
        mobile: this.getStoredInfo('mobile'),
      },
    };
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        profileInfos: 'Profile information',
        listePays: {
          canada: 'Canada',
          etatsUnis: 'United States',
          france: 'France',
          paysBas: 'Netherlands',
          bolivie: 'Bolivia',
          nouvelleZelande: 'New Zealand',
          japon: 'Japan',
          autre: 'Other',
        },
        erreurs: {
          required: 'This field is required.',
          email: 'Please enter a valid email address',
          infosIncorrectes: 'The information entered is incorrect',
          phone: 'Invalid phone number',
        },
      },
      fr: {
        profileInfos: 'Informations du profil',
        listePays: {
          canada: 'Canada',
          etatsUnis: 'États-Unis',
          france: 'France',
          paysBas: 'Netherlands',
          bolivie: 'Bolivia',
          nouvelleZelande: 'New Zealand',
          japon: 'Japan',
          autre: 'Other',
        },
        erreurs: {
          required: 'Ce champ est requis.',
          email: 'Veuillez entrer une adresse courriel valide',
          infosIncorrectes: 'Les informations entrées sont incorrectes.',
          phone: 'Numéro de téléphone invalide',
        },
      },
    },
  },
  validations() {
    const phone = helpers.withMessage(
      this.$t('erreurs.phone'),
      helpers.regex(/^\(\d{3}\)\s\d{3}-\d{4}$/)
    );
    return {
      form: {
        adresse: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        ville: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        code_postal: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        pays: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
        },
        email: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
          email: helpers.withMessage(this.$t('erreurs.email'), email),
        },
        mobile: {
          phone: phone,
        },
        telephone: {
          required: helpers.withMessage(this.$t('erreurs.required'), required),
          phone: phone,
        },
      },
    };
  },
  methods: {
    CountryChange() {
      this.province = null;
    },
    submitForm() {
      this.v$.$validate();
      this.errorProvince = false;
      if (this.form.pays !== 'CA' && this.form.pays !== 'USA') {
        this.province = null;
        this.errorProvince = false;
      } else {
        if (this.province == null) {
          this.errorProvince = true;
        } else {
          this.errorProvince = false;
        }
      }
      if (!this.v$.$error && !this.errorProvince) {
        this.$loading.startLoading();
        this.updateUser();
      } else {
        console.log(this.v$.$errors);
        console.log(this.errorProvince);
      }
    },
    getStoredInfo(info: string) {
      const test = JSON.parse(sessionStorage.getItem('user') || '');
      for (const [key, value] of Object.entries(test)) {
        if (key === info) return value;
      }
    },
    updateUser() {
      this.user.address = this.form.adresse;
      this.user.aggregateRootId = JSON.parse(sessionStorage.user).memberId;
      this.user.authToken = JSON.parse(sessionStorage.user).userAuthToken;
      this.user.email = this.form.email;
      this.user.mobile = this.form.mobile == '' ? null : this.form.mobile;
      this.user.address = this.form.adresse;
      this.user.city = this.form.ville;
      this.user.postalCode = this.form.code_postal;
      this.user.province = this.province;
      this.user.country = this.form.pays;
      this.user.telephone = this.form.telephone;
      this.$store
        .dispatch('User/updateUser', this.user)
        .then(() => {
          this.$loading.stopLoading();
          this.updateStorage();
        })
        .catch(() => {
          this.$loading.stopLoading();
        });
    },
    updateStorage() {
      let user = JSON.parse(sessionStorage.user);
      user.address = this.user.address;
      user.city = this.user.city;
      user.postalCode = this.user.postalCode;
      user.email = this.user.email;
      user.mobile = this.user.mobile;
      user.telephone = this.user.telephone;
      user.country = this.user.country;
      user.province = this.user.province;
      sessionStorage.user = JSON.stringify(user);
      this.adresse = this.user.address;
      this.ville = this.user.city;
      this.codePostal = this.user.postalCode;
      this.email = this.user.email;
      this.numeroTelephone = this.user.mobile;
      this.pays = this.user.country;
      this.province = this.user.province;
    },
  },
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
$colorGris: #f9f8ff;
$colorBleuFonce: #274b93;
$police: 'IBM Plex Sans', sans-serif;
#page_profil {
  background-color: $colorGris;
  padding: 100px 0;
}
.center {
  margin: auto;
  text-align: center;
  padding: 44px;
}
.Titre {
  color: #273566;
  font-size: 26px;
  font-weight: 700;
}
.container-profil {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 60%;
  height: 100%;
  text-align: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 80%;
    margin: 10%;
    align-content: center;
  }
  @media screen and (max-width: 1080px) {
    width: 80%;
  }
}
.container-profil .container-profil__blocs {
  height: 100%;
  background-color: #ffffff;
  padding: 10px;
  text-align: left;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
}
.container-profil__blocs.mon_profil {
  width: 33%;
  height: 50%;
  border: 2px solid $colorBleuFonce;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 10px;
  }
}
.container-profil__blocs.infos_profil {
  width: 65%;
  @media screen and (max-width: 768px) {
    width: 100%;
    margin-right: 10px;
    margin-top: 15px;
  }
}
.container-profil__blocs h2 {
  text-align: left;
  border-bottom: 1px solid #acbdde;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
}
.mon_profil__informations_personelles {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: $police;
  font-size: 15px;
}
.mon_profil__informations_personelles p {
  margin-bottom: 5px;
}
.form_user {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 8px;
}
.form_user label,
.reception-mode p {
  display: block;
  color: $colorBleuFonce;
  font-size: 15px;
  font-family: $police;
  padding-top: 20px;
  border-radius: 3px;
  opacity: 1;
}
.form_user input,
.form_user select {
  width: 100%;
}
.reception-mode {
  width: 100%;
}
.reception-mode-fields {
  display: flex;
  flex-wrap: wrap;
}
.reception-mode-fields label {
  margin-right: 4%;
  color: $textRapport;
  @media screen and (max-width: 479px) {
    width: 100% !important;
  }
}
.reception-mode-fields input {
  border-radius: 3px;
  accent-color: $colorBleuFonce;
  transform: scale(1.2);
  margin-right: 5px;
  width: auto;
  height: 100%;
  vertical-align: bottom;
}
.form_user input[type='text'],
.form_user input[type='email'],
.form_user input[type='tel'],
.form_user select {
  font-family: $police;
  font-weight: 600;
  border: 1px solid #cecece;
  border-radius: 3px;
  opacity: 1;
  padding-bottom: 5px;
  height: 42px;
  padding: 5px 10px;
}
.form_user__codePostal input {
  width: 100%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.form_user__prenom,
.form_user__nom {
  flex-basis: 50%;
}
.form_user__ville {
  flex-basis: 48%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}
.form_user__province {
  flex-basis: 48%;
}
.form_user__adresse {
  flex-basis: 100%;
}
.form_user__codePostal {
  flex-basis: 48%;
  @media screen and (max-width: 768px) {
    flex-basis: 48%;
  }
}
.form_user__telephone {
  flex-basis: 35%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}
.form_user__courriel {
  flex-basis: 60%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}
.form_user__submit_button {
  padding-bottom: 20px;
  padding-top: 25px;
  @media screen and (max-width: 768px) {
    flex-basis: 75%;
  }
}
.button {
  padding-top: 9px;
  padding-bottom: 9px;
  border: 1px solid #acbdde;
  opacity: 1;
}
.form_user__pays {
  flex-basis: 48%;
  @media screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}
.input-errors {
  color: red;
  font-size: 0.75em;
}
.disquette {
  padding-right: 5px;
}
</style>
