<template>
  <div class="second-box">
    <div class="navigator">
      <div
        class="selector"
        v-for="menu of menus"
        :key="menu.menuName"
        @click="selectBox(menu.id)"
        :class="{ selected: selectedBox == menu.id }"
      >
        <font-awesome-icon :icon="[menu.iconStyle, menu.icon]" class="ico" />
        <span>
          <slot v-bind:menu="menu" :name="menu.id + '-header'">
            {{ menu.menuName }}
          </slot>
        </span>
      </div>
    </div>
    <div class="content-box">
      <div class="infos">
        <div v-for="menu of menus" :key="menu.menuName">
          <slot
            v-if="selectedBox === menu.id"
            v-bind:menu="menu"
            :name="menu.id + '-body'"
          >
          </slot>
        </div>
      </div>
      <footer class="footer-box" v-if="selectedBox == 'poisson'">
        <slot :name="'poisson-footer'"></slot>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'DetailBox',
  props: {
    menus: { type: Object, required: true },
  },
  data() {
    return {
      selectedBox: '',
    };
  },
  methods: {
    selectBox(BoxName: string) {
      this.selectedBox = BoxName;
    },
  },
  created() {
    this.selectBox(this.menus[0].id);
  },
});
</script>

<style scoped lang="scss">
.second-box {
  background-color: white;
  padding: 20px;
  text-align: left;
  .navigator {
    display: flex;
    .selector {
      background-color: white;
      border: 1px solid $grisFonce;
      padding: 10px 25px 10px 25px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: $bleuFonce;
      margin-right: 5px;
      font-weight: bold;
      margin-bottom: 0;
      display: flex;
      align-self: flex-end;
      .ico {
        font-size: 30px;
        align-self: center;
      }
      span {
        margin-left: 10px;
        font-size: 17px;
        align-self: center;
        font-weight: 700;
      }
      @media only screen and (max-width: 600px) {
        span {
          font-size: 12px;
          font-weight: 500;
        }
        .ico {
          font-size: 20px;
          align-self: center;
        }
      }
    }
    .selector:hover {
      cursor: pointer;
    }
    .selected {
      padding-top: 17px;
      border-bottom: none;
      background-color: $gris;
    }
  }
  @media only screen and (max-width: $md) {
    .navigator {
      margin-top: -75px;
      .selector {
        background-color: white;
        border-bottom: 1px solid $grisFonce;
      }
      .selected {
        background-color: $gris;
        border-bottom: none;
      }
    }
  }
  @media only screen and (max-width: 605px) {
    .navigator {
      margin-top: -66px;
    }
  }
  @media only screen and (max-width: 412px) {
    .navigator {
      margin-top: -75px;
    }
  }
  .marf-left {
    margin-left: 8px;
  }
  .content-box {
    margin-top: -1px;
    border: 1px solid $grisFonce;
    background-color: $gris;
    padding: 30px;
    font-weight: 500;
    .footer-box {
      padding-top: 30px;
      padding-bottom: 10px;
      border-top: 1px solid $grisFonce;
    }
    .infos {
      padding-bottom: 30px;
    }
  }
}
</style>
