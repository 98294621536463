<template>
  <div>
    <Card>
      <template v-slot:content>
        <div class="cherch-btn">
          <input
            type="text"
            placeholder="Rechercher par nom, numéro commande..."
            v-model="search"
          />
          <button class="recherche" @click="filter">
            <font-awesome-icon :icon="[ico, 'magnifying-glass']" />
          </button>
        </div>
        <p class="sites">Site de distribution</p>
        <select v-model="site" @change="filterDatatable()" placeholder="Tous">
          <option value="-1">Tous</option>
          <option
            v-for="adress in getSiteDistributionByUser"
            :key="adress.ville"
            :value="adress.id"
          >
            {{ uppercase(adress.ville) }} - {{ adress.lieux }}
          </option>
        </select>
        <p class="sites">Statut de distribution</p>
        <select v-model="statut" @change="filterDatatable()" placeholder="Tous">
          <option :value="-1">Tous</option>
          <option :value="1">Distribuée</option>
          <option :value="0">Non distribuée</option>
        </select>
        <p v-if="!isAdmin" class="lieu">{{ siteNom }}</p>
        <p v-else class="lieu">Rapport des sites au complet</p>
        <DataTable
          v-model:selection="selectedOrder"
          selectionMode="single"
          :value="rappots"
          :paginator="true"
          :rows="10"
          ref="dt"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} COMMANDES"
        >
          <Column header="DATE">
            <template #body="{ data }">
              {{ dateParserLong(data.created_at) }}
            </template>
          </Column>
          <Column field="id" header="Nº COMMANDE">
            <template #body="{ data }">
              <div v-if="isAdmin">
                {{ padLeadingZeros(data.pivot.commande_id) }}
              </div>

              <div v-else>{{ padLeadingZeros(data.commande_id) }}</div>
            </template>
          </Column>
          <Column header="NOM">
            <template #body="{ data }">
              {{ data.ssamtaUser.firstName }} <br />
              {{ data.ssamtaUser.lastName }}
            </template>
          </Column>
          <Column v-if="isAdmin" header="BOITE">
            <template #body="{ data }">
              <font-awesome-icon
                v-if="data.type === 'poisson'"
                icon="fish"
                class="ico"
                v-bind:class="{ showIcon: data.path_icone }"
              />
              <font-awesome-icon
                v-else-if="data.type === 'gibier'"
                icon="deer"
                class="ico gibier"
                v-bind:class="{ showIcon: data.path_icone }"
              />
              <img
                v-else-if="data.type === 'saumon'"
                class="saumon-icon"
                src="../../assets/img/icon-saumon.svg"
                alt="saumon"
                v-bind:class="{ showIcon: data.path_icone }"
              />
            </template>
          </Column>
          <Column v-else header="BOITE">
            <template #body="{ data }">
              <font-awesome-icon
                v-if="data.produit.nom === 'Boîte de poisson'"
                icon="fish"
                class="ico"
                v-bind:class="{ showIcon: data.produit.path_icone }"
              />
              <font-awesome-icon
                v-else-if="data.produit.nom === 'Boîte de gibier'"
                icon="deer"
                class="ico gibier"
                v-bind:class="{ showIcon: data.produit.path_icone }"
              />
              <img
                v-else-if="data.produit.nom === 'Boîte de saumon'"
                class="saumon-icon"
                src="../../assets/img/icon-saumon.svg"
                alt="saumon"
                v-bind:class="{ showIcon: data.produit.path_icone }"
              />
            </template>
          </Column>
          <Column v-if="isAdmin" header="DISTRIBUÉE">
            <template #body="{ data }">
              <div v-if="data.pivot.distribuee" class="success">
                <font-awesome-icon
                  :icon="['fal', 'circle-check']"
                  class="ico-distribuee success"
                />
              </div>

              <div v-if="!data.pivot.distribuee" class="error">
                <font-awesome-icon
                  :icon="['fal', 'circle-xmark']"
                  class="ico-distribuee error"
                />
              </div>
            </template>
          </Column>
          <Column v-else header="DISTRIBUÉE"
            ><template #body="{ data }">
              <div v-if="data.distribuee" class="success">
                <font-awesome-icon
                  :icon="['fal', 'circle-check']"
                  class="ico-distribuee success"
                />
              </div>
              <div v-if="!data.distribuee" class="error">
                <font-awesome-icon
                  :icon="['fal', 'circle-xmark']"
                  class="ico-distribuee error"
                />
              </div> </template
          ></Column>
          <template #paginatorstart>
            <div v-if="isAdmin">
              <button class="btn-rapport" @click="exportCSV">
                <font-awesome-icon :icon="[ico, 'file-export']" />
                Exporter en CSV
              </button>
              <button
                v-if="selectedOrder === null"
                id="btn-distribuer"
                class="button button-primary"
                disabled
              >
                Distribuer
              </button>
              <button
                v-if="selectedOrder != null"
                id="btn-distribuer"
                class="button button-primary"
                @click="distribuerCommande()"
              >
                <p v-if="getBooleanDistribuee()" style="color: white">
                  Annuler la distribution
                </p>
                <p v-else style="color: white">Distribuer</p>
              </button>
            </div>
            <div v-else>
              <button
                v-if="selectedOrder === null"
                id="btn-distribuer"
                class="button button-primary"
                disabled
              >
                Distribuer
              </button>
              <button
                v-if="selectedOrder != null"
                id="btn-distribuer"
                class="button button-primary"
                @click="distribuerCommande()"
              >
                <p v-if="getBooleanDistribuee()" style="color: white">
                  Annuler la distribution
                </p>
                <p v-else style="color: white">Distribuer</p>
              </button>
            </div>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
export default defineComponent({
  name: 'RapportEc',
  components: { DataTable, Column, Card },
  data() {
    return {
      adresses: [] as Array<siteDistributionDTO>,
      rappots: new Array<CommandeDTO>(),
      copieRappots: new Array<CommandeDTO>(),
      ico: 'far',
      site: -1,
      statut: -1,
      search: '',
      isAdmin: false,
      selectedOrder: null,
      sitesDistributionUser: [],
    };
  },
  methods: {
    exportCSV() {
      this.$store
        .dispatch('Rapports/getEtatCommandesCsv', {
          site: this.site,
          statut: this.statut,
        })
        .then();
    },
    filterDatatable() {
      this.rappots = this.copieRappots;
      console.log(this.site, this.statut);
      if (this.site === -1 && this.statut === -1) {
        return;
      }
      //console.log(this.site);
      const result = this.rappots.filter((rapport) => {
        console.log(rapport);
        if (this.isAdmin) {
          return (
            (rapport.site_distribution_id === this.site || this.site == -1) &&
            (rapport.pivot.distribuee == this.statut || this.statut == -1)
          );
        } else {
          return (
            (rapport.commande.site_distribution_id === this.site ||
              this.site == '') &&
            (rapport.pivot.distribuee === this.statut || this.statut === '')
          );
        }
      });
      this.rappots = result;
    },
    getBooleanDistribuee() {
      if (this.isAdmin) {
        return this.selectedOrder.pivot.distribuee;
      } else {
        return this.selectedOrder.distribuee;
      }
    },
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    getCommandes() {
      this.$store.dispatch('Users/adminMe').then((data) => {
        if (this.isAdmin) {
          this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
            data.forEach((commande) => {
              commande.produits.forEach((produit) => {
                if (produit.nom === 'Boîte de poisson') {
                  produit['type'] = 'poisson';
                } else if (produit.nom === 'Boîte de gibier') {
                  produit['type'] = 'gibier';
                } else if (produit.nom === 'Boîte de saumon') {
                  produit['type'] = 'saumon';
                }
                produit.ssamtaUser = commande.ssamtaUser;
                produit.site_distribution_id = commande.site_distribution_id;
                this.rappots.push(produit);
              });
            });
            this.copieRappots = this.rappots;
          });
        } else {
          this.sitesDistributionUser = data.distributions;
          this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
            data.forEach((data) => {
              this.rappots.push(data);
            });
            this.copieRappots = this.rappots;
          });
        }
      });
      this.siteDistrib();
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    distribuerCommande() {
      if (confirm('Confirmez la distribution')) {
        this.$loading.startLoading();
        // this.selectedOrder.nbBoitesDistribuees =
        //   this.selectedOrder.nbBoitesCommandees;
        this.$store
          .dispatch('Commande/reclamerCommande', this.selectedOrder)
          .then((data: { message: string }) => {
            this.$loading.stopLoading();
            // this.getCommandes();
            location.reload();
          })
          .catch((message: string) => {
            this.$loading.stopLoading();
          });
      }
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      return (
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(
          this.padLeadingZeros(commande.pivot.commande_id)
        ) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.lastName) ||
        this.getIfStringMatchSearch(
          commande.ssamtaUser.firstName + ' ' + commande.ssamtaUser.lastName
        )
      );
    },
  },
  created() {
    this.isAdmin = JSON.parse(sessionStorage.user).role == 'admin';
    this.getCommandes();
  },
  computed: {
    siteNom(): string {
      const siteDistribution = this.adresses.find(
        (adr) => adr.id === this.site
      );
      return siteDistribution !== undefined ? siteDistribution.ville : '';
    },
    getSiteDistributionByUser() {
      if (this.isAdmin) {
        return this.adresses;
      } else {
        return this.adresses.filter((site) => {
          // return this.sitesDistributionUser.includes(site);
          var result = false;
          this.sitesDistributionUser.forEach((element) => {
            if (element.id === site.id) {
              result = true;
            }
          });
          return result;
        });
      }
    },
  },
});
</script>

<style scoped lang="scss">
#btn-distribuer:disabled {
  opacity: 0.5;
}
.ico {
  font-size: 20px;
  color: $bleuFonce;
  opacity: 0;
}
.ico-distribuee {
  font-size: 20px;
  color: $bleuFonce;
}
.sites {
  color: $bleuFonce;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
}
.lieu {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid $bleuFonce;
  color: $bleuFonce;
  font-weight: bold;
}
.gibier {
  color: $rougeFonce;
}
.saumon-icon {
  opacity: 0;
  width: 2em;
}
.showIcon {
  opacity: 1;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
select {
  height: 42px;
  border: 1px solid $grisFonce;
  padding-left: 10px;
  max-width: 40%;
  display: block;
}
select:focus {
  outline: none;
}
.btn-rigth {
  margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.cherch-btn {
  margin-top: 20px;
  input {
    height: 42px;
    width: 30%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.error {
  color: $rougeFonce;
  height: 40px;
}
.success {
  color: $vert;
  height: 40px;
}
</style>
