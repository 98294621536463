import { VuexModule, Module, Action } from 'vuex-module-decorators';
import AdminService from '@/services/AdminService';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { FilderDateDTO } from '@/types/store/DTO/FilderDateDTO';
import { AxiosResponse } from 'axios';
import InscriptionAssemblee from './InscriptionAssemblee';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';

function downloadFile(blobData: ArrayBuffer, fileName: string) {
  const fileURL = window.URL.createObjectURL(new Blob([blobData]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
}

@Module({ namespaced: true })
class Rapports extends VuexModule {
  @Action({ rawError: true })
  getAllcommandes(): Promise<CommandeDTO> {
    return AdminService.getRapports().then(
      (response) => {
        return Promise.resolve(response.data.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getAllDeletedCommandes(): Promise<CommandeDTO> {
    return AdminService.getAllDeletedCommandes().then(
      (response) => {
        return Promise.resolve(response.data.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  commandesWithDate(data: FilderDateDTO): Promise<CommandeDTO> {
    return AdminService.getcommandesWithDate(data).then(
      (response) => {
        return Promise.resolve(response.data.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  commandesSupprimeesWithDate(data: FilderDateDTO): Promise<CommandeDTO> {
    return AdminService.getCommandesSupprimeesWithDates(data).then(
      (response) => {
        return Promise.resolve(response.data.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  inscriptionsWithDates(data: FilderDateDTO): Promise<InscriptionAssembleeDTO> {
    return AdminService.getInscriptionsWithDates(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  inscriptionsAdminWithDates(
    data: FilderDateDTO
  ): Promise<InscriptionAssembleeDTO> {
    return AdminService.getInscriptionsAdminWithDates(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesCsv(): Promise<AxiosResponse> {
    return AdminService.getCommandesCsv().then(
      (response) => {
        downloadFile(response.data, 'commandes.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesSupprimeesCsv(): Promise<AxiosResponse> {
    return AdminService.getCommandesSupprimeesCsv().then(
      (response) => {
        downloadFile(response.data, 'commandes.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesPermisCsv(): Promise<AxiosResponse> {
    return AdminService.getCommandesPermisCsv().then(
      (response) => {
        downloadFile(response.data, 'commandesPermis.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesCsvWithDates(data: FilderDateDTO): Promise<AxiosResponse> {
    return AdminService.getCommandesCsvWithDates(data).then(
      (response) => {
        downloadFile(response.data, 'commandes.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesSupprimeesCsvWithDates(
    data: FilderDateDTO
  ): Promise<AxiosResponse> {
    return AdminService.getCommandesSupprimeesCsvWithDates(data).then(
      (response) => {
        downloadFile(response.data, 'commandes.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesAdminCsv(): Promise<AxiosResponse> {
    return AdminService.getCommandesAdminCsv().then(
      (response) => {
        downloadFile(response.data, 'coupons.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesPdf(): Promise<AxiosResponse> {
    return AdminService.getCommandesPdf().then(
      (response) => {
        downloadFile(response.data, 'rapport.pdf');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getRapportBySite(site?: { site: string }): Promise<AxiosResponse> {
    return AdminService.getRapportBySite(site).then(
      (response) => {
        return Promise.resolve(response.data.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getPdfSite(site: string): Promise<AxiosResponse> {
    return AdminService.getPdfSite(site).then(
      (response) => {
        if (site == '-1') {
          downloadFile(response.data, 'Rapport_' + 'tous_les_sites' + '.pdf');
        } else {
          downloadFile(response.data, 'Rapport_' + site + '.pdf');
        }
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesSiteCsv(site: string): Promise<AxiosResponse> {
    return AdminService.getCommandesSiteCsv(site).then(
      (response) => {
        downloadFile(response.data, 'commandes_' + site + '.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCouponPoisson(id: string): Promise<AxiosResponse> {
    return AdminService.getCouponPoisson(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCouponGibier(id: string): Promise<AxiosResponse> {
    return AdminService.getCouponGibier(id).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getCommandesMadeByAdminsPerPage(currentPage: number): Promise<AxiosResponse> {
    return AdminService.getCommandesMadeByAdminsPerPage(currentPage).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getEtatCommandesCsv(payload: object): Promise<AxiosResponse> {
    return AdminService.getEtatCommandesCsv(payload).then(
      (response) => {
        downloadFile(response.data, 'etat-des-commandes.xlsx');
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  importFileCouponsPromos(file): Promise<AxiosResponse> {
    return AdminService.importFileCouponsPromo(file).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default Rapports;
