import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5672420"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-login" }
const _hoisted_2 = { class: "box-login" }
const _hoisted_3 = { class: "titre-admin" }
const _hoisted_4 = { class: "titre" }
const _hoisted_5 = { class: "sous-titre" }
const _hoisted_6 = { class: "input-container" }
const _hoisted_7 = {
  for: "email",
  class: "form-label"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { class: "input-container" }
const _hoisted_11 = {
  for: "noMembre",
  class: "form-label"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "input-container" }
const _hoisted_14 = {
  key: 0,
  class: "errorcnx"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('admin')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('bienvenue')), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('loginInfo')), 1),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('email')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "email",
          id: "email",
          class: "form-input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
        }, null, 512), [
          [_vModelText, _ctx.email]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.email.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "errorMessage",
            key: error.$uid
          }, [
            (error.$validator == 'required')
              ? (_openBlock(), _createElementBlock("label", _hoisted_8, _toDisplayString(_ctx.$t('erreurs.required')), 1))
              : _createCommentVNode("", true),
            (error.$validator == 'email')
              ? (_openBlock(), _createElementBlock("label", _hoisted_9, _toDisplayString(_ctx.$t('erreurs.email')), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('password')), 1),
        _withDirectives(_createElementVNode("input", {
          type: "password",
          id: "noMembre",
          class: "form-input",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
        }, null, 512), [
          [_vModelText, _ctx.password]
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.v$.password.$errors, (error) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "errorMessage",
            key: error.$uid
          }, [
            (error.$validator == 'required')
              ? (_openBlock(), _createElementBlock("label", _hoisted_12, _toDisplayString(_ctx.$t('erreurs.required')), 1))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("button", {
          class: "btn-form",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.submitform && _ctx.submitform(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            icon: ['fas', 'lock'],
            class: "ico-form"
          }),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('boutons.connexion')), 1)
        ])
      ]),
      (_ctx.errorConnection)
        ? (_openBlock(), _createElementBlock("label", _hoisted_14, _toDisplayString(_ctx.$t('erreurs.infosIncorrectes')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}