<template>
  <div class="barreHeader">
    <router-link class="logoMalecites" :to="{ name: 'Home' }">
      <img src="@/assets/img/logoMalecites.png" alt="logo malecites" />
    </router-link>
    <HeaderMenu />
  </div>
  <div class="filsAriane">
    <Breadcrumb class="breadcrumbComponent" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Breadcrumb from '@/components/header/Breadcrumb.vue';
import HeaderMenu from '@/components/header/HeaderMenu.vue';
export default defineComponent({
  name: 'Header',
  components: {
    Breadcrumb,
    HeaderMenu,
  },
});
</script>

<style scoped lang="scss">
.filsAriane {
  display: flex;
  justify-content: space-between;
  background-color: #d2d8ee;
  padding-left: 3%;
  padding-right: calc(3% + 15px);
  color: $colorBleuFonce;
  font-size: 20px;
  height: 40px;
}
.barreHeader {
  background-color: white;
  display: flex;
  flex-direction: row;
  height: 91px;
  padding-top: 1vh;
  padding-bottom: 1vh;
  font-family: $police;
}
.logoMalecites {
  width: 150px;
  padding-left: 3%;
  align-self: center;
}
.breadcrumbComponent {
  height: 100%;
  display: flex;
  align-content: center;
}
</style>
