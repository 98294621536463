import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3048db62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contenu" }
const _hoisted_2 = { class: "titre" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "success"
}
const _hoisted_6 = {
  key: 1,
  cdlass: "error"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "btn-rapport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('mesPermis')), 1)
    ]),
    _createVNode(_component_Card, null, {
      content: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.permis,
          paginator: true,
          rows: 10,
          ref: "dt",
          paginatorTemplate: "CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown",
          rowsPerPageOptions: [10, 20, 50],
          responsiveLayout: "scroll",
          currentPageReportTemplate: "{totalRecords} PERMIS"
        }, {
          paginatorstart: _withCtx(() => [
            _createVNode(_component_router_link, { to: { name: 'CommandePermis' } }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_8, [
                  _createVNode(_component_font_awesome_icon, { icon: ['far', 'file-export'] }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('acheter_permis')), 1)
                ])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              header: this.$t('permis')
            }, {
              body: _withCtx((data) => [
                (_ctx.locale == 'fr')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(data.data.nom), 1))
                  : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(data.data.nom_en), 1))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: this.$t('possede')
            }, {
              body: _withCtx((data) => [
                (_ctx.commandesPermis.some((perm) => perm.id === data.data.id))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fal', 'circle-check'],
                        class: "ico-distribuee success"
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_font_awesome_icon, {
                        icon: ['fal', 'circle-xmark'],
                        class: "ico-distribuee error"
                      })
                    ]))
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              header: this.$t('date_expiration')
            }, {
              body: _withCtx((data) => [
                (_ctx.commandesPermis.some((perm) => perm.id === data.data.id))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.commandesPermis.find((perm) => perm.id === data.data.id).pivot
                    .date_expiration == null
                    ? ''
                    : _ctx.commandesPermis
                        .find((perm) => perm.id === data.data.id)
                        .pivot.date_expiration.slice(0, 10)), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    })
  ]))
}