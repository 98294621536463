<template>
  <CBreadcrumb class="breadcrumb" style="--cui-breadcrumb-divider: '>'">
    <CBreadcrumbItem
      class="bi-main"
      v-for="breadcrumbItem in breadcrumbItems"
      :key="breadcrumbItem.path"
      :href="breadcrumbItem.path"
      >{{ $t(breadcrumbItem.meta.displayName) }}</CBreadcrumbItem
    >
    <CBreadcrumbItem v-if="route" class="bi-item">{{
      $t(route)
    }}</CBreadcrumbItem>
  </CBreadcrumb>
  <div class="locale-changer">
    <select v-model="$i18n.locale" @change="changeLanguage">
      <option
        v-for="(locale, i) in locales"
        :key="`locale-${i}`"
        :value="locale"
      >
        {{ locale }}
      </option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CBreadcrumbItem } from '@coreui/vue';
import { RouteRecordRaw } from 'vue-router';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'Breadcrumb',
  components: {
    CBreadcrumbItem,
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      locales: ['fr', 'en'],
      route: '',
      breadcrumbItems: new Array<RouteRecordRaw>(),
    };
  },
  watch: {
    $route() {
      this.route = this.$route.meta.displayName;
      this.generateBreadcrum();
    },
  },
  methods: {
    changeLanguage() {
      localStorage.setItem('lang', this.$i18n.locale);
      window.location.reload();
    },
    generateBreadcrum() {
      this.breadcrumbItems = [];
      const routes = this.$router.options.routes;
      let parent = this.$route.meta.parent;
      while (parent != undefined) {
        const parentIndex = routes.findIndex(
          (breadcrumbItem: RouteRecordRaw) => breadcrumbItem.name == parent
        );
        if (parentIndex == -1) {
          parent = undefined;
        } else {
          parent = routes[parentIndex]?.meta?.parent;
          this.breadcrumbItems.push(routes[parentIndex]);
        }
      }
      this.breadcrumbItems.reverse();
    },
  },
  created() {
    this.generateBreadcrum();
  },
});
</script>
<style lang="scss" scoped>
.breadcrumb {
  font-family: $police;
  height: 20px;
  padding-top: 15px;
}
.bi-main {
  font-size: 14px;
  color: #274b93;
}
.bi-item {
  font-size: 15px;
  font-weight: 600;
}
.locale-changer {
  margin-top: 5px;
}
</style>
