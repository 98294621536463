<template>
  <div class="modification-coupons">
    <div class="distribution-communautaire">DISTRIBUTION COMMUNAUTAIRE</div>
    <div class="ligne-pale" />
    <div class="titre" v-if="!creation"><h1>Modifier un utilisateur</h1></div>
    <div class="titre" v-else><h1>Création d'un utilisateur</h1></div>
    <div class="contenu">
      <div class="formulaire fond-blanc">
        <h2 v-if="!creation">Utilisateur #{{ user.id }}</h2>
        <h2 v-else>Créer un utilisateur</h2>
        <div class="ligne-pale"></div>
        <form action="Modification">
          <div class="form-nom">
            <label for="nom">Nom Complet</label>
            <input type="text" id="nom" placeholder="Nom" v-model="user.name" />
          </div>
          <div class="form-courriel">
            <label for="courriel">Courriel</label>
            <input
              type="text"
              id="courriel"
              placeholder="Courriel"
              v-model="user.email"
            />
          </div>
          <div class="form-password" v-if="creation">
            <label for="password">Mot de passe</label>
            <input
              type="password"
              id="password"
              placeholder="Mot de passe"
              v-model="user.password"
            />
          </div>
          <div class="form-role" ref="formRole">
            <label for="role">Rôle</label>
            <select name="role" id="role" v-model="user.roleId">
              <option value="1">Administrateur</option>
              <option value="2">Distributeur</option>
            </select>
          </div>
          <div class="form-site">
            <label for="site">Site de distribution</label>
            <multiselect
              v-model="user.distributions"
              :options="adresses"
              label="displayName"
              :multiple="true"
              track-by="displayName"
            ></multiselect>

            <!-- <select
              v-model="user.site_distribution_id"
              name="site"
              id="site"
              multiple
            >
              <option
                v-for="adress in adresses"
                :key="adress.id"
                :value="adress.id"
              >
                {{ uppercase(adress.ville) }} - {{ adress.lieux }}
              </option>
            </select> -->
          </div>
        </form>
      </div>
    </div>
    <div class="bouton">
      <button
        class="button button-primary"
        @click="saveUser()"
        v-if="!creation"
      >
        <font-awesome-icon
          :icon="['fas', 'box-check']"
          class="box-check-logo"
        />
        Sauvegarder les modifications
      </button>
      <button class="button button-primary" @click="saveUser()" v-if="creation">
        <font-awesome-icon
          :icon="['fas', 'box-check']"
          class="box-check-logo"
        />
        Créer l'utilisateur
      </button>
      <button
        class="button button-primary delete"
        @click="deleteUser()"
        v-if="!creation"
      >
        <font-awesome-icon
          :icon="['fas', 'trash-can']"
          class="box-check-logo"
        />
        Supprimer l'utilisateur
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';

export default defineComponent({
  name: 'ModificationUtilisateur',
  data() {
    return {
      adresses: {} as Array<siteDistributionDTO>,
      user: {},
      creation: false,
      message: '',
      error: false,
    };
  },
  created() {
    this.siteDistrib();
    if (this.$route.params.userId) {
      this.creation = false;
      this.getUserInfo(this.$route.params.userId as string);
    } else {
      this.creation = true;
    }
  },
  mounted() {
    if (!this.creation) {
      const form = this.$refs.formRole as HTMLElement;
      form.classList.add('full-width');
    }
  },
  methods: {
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
    getUserInfo(userId: string) {
      this.$store
        .dispatch('Users/getUserById', userId)
        .then((data: UsersDTO) => {
          this.user = data;
        });
    },
    deleteUser() {
      if (confirm("Voulez-vous vraiment supprimer l'utilisateur?")) {
        this.$store
          .dispatch('Users/deleteUser', this.$route.params.userId as string)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    saveUser() {
      // this.user.password = this.password;
      if (this.creation) {
        this.$store
          .dispatch('Users/createUser', this.user)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      } else {
        this.$store
          .dispatch('Users/modifyUser', this.user)
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-utilisateurs');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.fond-blanc {
  background-color: $white;
}
.distribution-communautaire {
  text-align: center;
  font-size: 23px;
  font-weight: 600;
  color: $grisFonce;
  margin: 2% 0 1% 0;
  @media screen and (max-width: $md) {
    margin: 5% 0 1% 0;
  }
}
.ligne-pale {
  border: 1px solid $grisFonce;
  width: 100%;
}
.formulaire {
  width: 65%;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
  margin: 1%;
  padding: 1%;
  @media screen and (max-width: $md) {
    width: 80%;
    padding: 2%;
  }
  @media screen and (max-width: 425px) {
    padding: 2%;
  }
}
.titre {
  text-align: center;
  margin: 2%;
  @media screen and (max-width: $md) {
    margin: 5%;
  }
}
.contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
h2 {
  font-size: 17px;
  font-family: $police;
  font-weight: 600;
  margin-bottom: 0.5%;
}
select {
  max-width: 100%;
}
input[type='checkbox'] {
  transform: scale(1.5);
  margin-right: 3%;
  accent-color: $bleuFonce;
}
form {
  margin: 3% 0 1.5% 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-prenom {
  flex-basis: 47.5%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 30%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 100%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-prenom input {
  width: 100%;
}
.form-nom {
  flex-basis: 100%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
    flex-basis: 100%;
  }
}
.form-nom input {
  width: 100%;
}
.form-courriel {
  flex-basis: 100%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-courriel input {
  width: 100%;
}
.form-site {
  flex-basis: 100%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-site select {
  width: 100%;
}
.form-password {
  flex-basis: 65%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    flex-basis: 45%;
    margin-bottom: 5%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 50%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-password input {
  width: 100%;
}
.form-role {
  flex-basis: 30%;
  margin-bottom: 3%;
  @media screen and (max-width: 1070px) {
    margin-bottom: 5%;
    flex-basis: 45%;
  }
  @media screen and (max-width: $md) {
    flex-basis: 45%;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 10%;
  }
}
.form-role select {
  width: 100%;
}
input[type='text'],
input[type='email'],
input[type='password'],
select {
  border: 1px solid $colorGrisBorder;
  border-radius: 3px;
  height: 90%;
}
form label {
  color: $bleuFonce;
  font-size: 15px;
  margin-bottom: 0.2%;
}
.bouton {
  display: flex;
  justify-content: space-between;
  margin-left: 17.5%;
  margin-right: 17.5%;
  margin-bottom: 5%;
  @media screen and (max-width: $md) {
    margin-left: 10%;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}
button.button {
  border: none;
  height: 3em;
}
input[type='text'],
input[type='email'],
input[type='password'],
select {
  padding-left: 10px;
}
.delete {
  background-color: red;
}
.full-width {
  flex-basis: 100%;
}
</style>
