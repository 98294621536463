<script setup>
// Call the API to get the member coupon
import { ref } from 'vue';
import axios from 'axios';
const coupon = ref({});

axios
  .get(
    process.env.VUE_APP_BACKEND_URL +
      'couponPromoMembre/' +
      JSON.parse(sessionStorage.user).memberId,
    {
      headers: {
        memberId: JSON.parse(sessionStorage.user).memberId,
      },
    }
  )
  .then((response) => {
    coupon.value = response.data;
  })
  .catch(() => {
    coupon.value = null;
  });
</script>
<template>
  <div class="page-coupons">
    <div class="titre">
      <h1>Mon coupon promo</h1>
    </div>
    <div class="contenu" v-if="coupon">
      <p>
        Votre code promo : <b>{{ coupon.code }}</b>
      </p>
    </div>
    <div v-else>
      <p>Aucun coupon promo disponible.</p>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.contenu {
  display: flex;
  margin: auto;
  justify-content: space;
  width: 55%;
  height: 100%;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
  height: 100%;
  background-color: $white;
  padding: 10px;
  margin-bottom: 2%;
}
.titre {
  text-align: center;
  margin: 1.5%;
}
</style>
