<template>
  <header>
    <h3 class="titre-page">DISTRIBUTION COMMUNAUTAIRE</h3>
  </header>
  <div class="container">
    <label class="titre"> Rapport - Sites de distribution </label>
    <RapportSd />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import RapportSd from '@/components/rapports/RapportSd.vue';

export default defineComponent({
  name: 'RapportsSites',
  components: {
    RapportSd,
  },
});
</script>
<style scoped lang="scss">
.titre {
  color: #273566;
  font-size: 26px;
  margin-top: 44px;
  font-weight: 700;
  margin-bottom: 41px;
}
ul {
  margin-bottom: 0;
  margin-left: 20px;
  padding: 0;
}
header {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $bleuCiel;
}
.titre-page {
  font-weight: bold;
  color: $bleuCiel;
}
</style>
