<template>
  <div class="container">
    <label class="titre">
      Formulaire d'inscription pour l'assemblée générale
    </label>
    <div class="container-box">
      <form id="form-assemblee" action="">
        <Panel header="Renseignements">
          <div class="form-assemblee">
            <div class="input-field small">
              <label for="input-numMembre">Numéro de membre</label>
              <input
                type="text"
                id="input-numMembre"
                v-model="inscription.memberId"
                @blur="getMemberInfo"
              />
            </div>
            <div class="input-field small">
              <label for="input-nom">Nom</label>
              <input
                type="text"
                id="input-nom"
                v-model="inscription.nom"
                @blur="getMemberInfo"
              />
            </div>
            <div class="input-field small">
              <label for="input-prenom">Prénom</label>
              <input
                type="text"
                id="input-prenom"
                v-model="inscription.prenom"
              />
            </div>
            <div class="input-field small">
              <label for="input-adresse">Adresse</label>
              <input
                type="text"
                id="input-adresse"
                v-model="inscription.adresse"
              />
            </div>
            <div class="input-field small">
              <label for="input-ville">Ville</label>
              <input type="text" id="input-ville" v-model="inscription.ville" />
            </div>
            <div class="input-field small">
              <label for="input-codePostal">Code postal</label>
              <input
                type="text"
                id="input-codePostal"
                v-model="inscription.codePostal"
              />
            </div>
            <div class="input-field small">
              <label for="input-telephone">Téléphone</label>
              <input
                type="text"
                id="input-telephone"
                v-model="inscription.telephone"
              />
            </div>
            <div class="input-field small">
              <label for="input-courriel">Courriel</label>
              <input
                type="text"
                id="input-courriel"
                v-model="inscription.email"
              />
            </div>
            <div class="input-field small">
              <label for="input-age">Âge</label>
              <input
                type="number"
                min="0"
                step="1"
                id="input-age"
                v-model="inscription.age"
              />
            </div>
          </div>
        </Panel>
        <Panel header="Section 2 - Accompagnateurs">
          <label>Serez-vous accompagné à l'Assemblée générale ?</label>
          <div style="display: inline; margin: 0 10px">
            <RadioButton
              v-model="inscription.accompagnement"
              inputId="accompagnateurs_bool_true"
              name="accompagnateurs_bool"
              value="Oui"
            />
            <label for="accompagnateurs_bool_true" style="margin-left: 10px">
              Oui
            </label>
          </div>
          <div style="display: inline; margin: 0 10px">
            <RadioButton
              v-model="inscription.accompagnement"
              inputId="accompagnateurs_bool_false"
              name="accompagnateurs_bool"
              value="Non"
            />
            <label for="accompagnateurs_bool_false" style="margin-left: 10px">
              Non
            </label>
          </div>
          <div>
            Inscrire le nom des personnes qui voyageront avec vous et qui
            participeront à la rencontre. À noter que seuls les membres pourront
            participer à la l'AGA et seront admis dans la salle.
            <div
              class="form-assemblee"
              style="margin-top: 10px"
              v-if="inscription.accompagnement == 'Oui'"
            >
              <div class="input-field small">
                <label for="input-nom-accompagnateur1">Nom et prénom</label>
                <input
                  type="text"
                  id="input-nom-accompagnateur1"
                  v-model="inscription.nom_accompagnateur_1"
                />
              </div>
              <div class="input-field small">
                <label for="input-numMembre-accompagnateur1">
                  Numéro de membre
                </label>
                <input
                  type="text"
                  id="input-numMembre-accompagnateur1"
                  v-model="inscription.num_membre_accompagnateur_1"
                />
              </div>
              <div class="input-field small">
                <label for="input-nom-accompagnateur2">Nom et prénom</label>
                <input
                  type="text"
                  id="input-nom-accompagnateur2"
                  v-model="inscription.nom_accompagnateur_2"
                />
              </div>
              <div class="input-field small">
                <label for="input-numMembre-accompagnateur2">
                  Numéro de membre
                </label>
                <input
                  type="text"
                  id="input-numMembre-accompagnateur2"
                  v-model="inscription.num_membre_accompagnateur_2"
                />
              </div>
              <div class="input-field small">
                <label for="input-nom-accompagnateur3">Nom et prénom</label>
                <input
                  type="text"
                  id="input-nom-accompagnateur3"
                  v-model="inscription.nom_accompagnateur_3"
                />
              </div>
              <div class="input-field small">
                <label for="input-numMembre-accompagnateur3">
                  Numéro de membre
                </label>
                <input
                  type="text"
                  id="input-numMembre-accompagnateur3"
                  v-model="inscription.num_membre_accompagnateur_3"
                />
              </div>
            </div>
          </div>
        </Panel>
        <Panel header="Section 3 - Allergies">
          <label>
            Est-ce que vous ou une personne qui vous accompagne a des allergies
            alimentaires?
          </label>
          <div style="display: inline; margin: 0 10px">
            <RadioButton
              v-model="inscription.allergies"
              inputId="allergies_bool_true"
              name="allergies_bool"
              value="Oui"
            />
            <label for="allergies_bool_true" style="margin-left: 10px">
              Oui
            </label>
          </div>
          <div style="display: inline; margin: 0 10px">
            <RadioButton
              v-model="inscription.allergies"
              inputId="allergies_bool_false"
              name="allergies_bool"
              value="Non"
            />
            <label for="allergies_bool_false" style="margin-left: 10px">
              Non
            </label>
          </div>
          <div v-if="inscription.allergies == 'Oui'">
            Si oui, qui est cette personne et de quelle(s) allergie(s)
            souffre-t-elle ?
            <div class="input-field" style="margin-top: 10px">
              <textarea
                id="input-allergies"
                v-model="inscription.details_allergies"
                style="width: 100%"
              />
            </div>
          </div>
        </Panel>
        <Panel header="Section 4 - Hébergement">
          <div>
            Pour les membres qui habitent à plus de 75 km de l'Hôtel Universel
            de Rivière-du Loup (source utilisée : GoogleMap), la PNWW offre les
            frais d'hébergement pour deux nuits (vendredi 7 juin et samedi 8
            juin). Des accommodements pour les aînés (membres de plus de 75 ans)
            sont disponibles.
            <div class="form-assemblee" style="margin-top: 10px">
              <div class="input-field">
                <label for="input-hebergement_bool">
                  J'habite à plus de 75 km et j'ai besoin d'hébergement
                </label>
                <div style="display: inline; margin: 0 10px">
                  <RadioButton
                    v-model="inscription.hebergement"
                    inputId="hebergement_bool_true"
                    name="hebergement_bool"
                    value="Oui"
                  />
                  <label for="hebergement_bool_true" style="margin-left: 10px">
                    Oui
                  </label>
                </div>
                <div style="display: inline; margin: 0 10px">
                  <RadioButton
                    v-model="inscription.hebergement"
                    inputId="hebergement_bool_false"
                    name="hebergement_bool"
                    value="Non"
                  />
                  <label for="hebergement_bool_false" style="margin-left: 10px">
                    Non
                  </label>
                </div>
              </div>
            </div>
            <div
              v-if="inscription.hebergement == 'Oui'"
              class="form-assemblee"
              style="margin-top: 10px"
            >
              <div class="input-field small">
                <label for="input-hebergement_nb_chambres">
                  Combien de chambres aurez-vous besoin (maximum de quatre
                  personnes par chambre)
                </label>
                <input
                  type="number"
                  step="1"
                  min="1"
                  id="input-hebergement_nb_chambres"
                  v-model="inscription.nb_chambres"
                />
              </div>
              <div class="input-field">
                <label for="input-hebergement_besoins_speciaux">
                  Avez-vous des besoins spéciaux pour la chambre? Exemple :
                  personne à mobilité réduite.
                </label>
                <div style="display: inline; margin: 0 10px">
                  <RadioButton
                    v-model="inscription.besoins_speciaux"
                    inputId="hebergement_besoins_speciaux_true"
                    name="hebergement_besoins_speciaux"
                    value="Oui"
                  />
                  <label
                    for="hebergement_besoins_speciaux_true"
                    style="margin-left: 10px"
                  >
                    Oui
                  </label>
                </div>
                <div style="display: inline; margin: 0 10px">
                  <RadioButton
                    v-model="inscription.besoins_speciaux"
                    inputId="hebergement_besoins_speciaux_false"
                    name="hebergement_besoins_speciaux"
                    value="Non"
                  />
                  <label
                    for="hebergement_besoins_speciaux_false"
                    style="margin-left: 10px"
                  >
                    Non
                  </label>
                </div>
              </div>
              <div class="input-field">
                <label for="input-hebergement_besoins_speciaux_description">
                  Si oui, donnez-nous plus de détails
                </label>
                <textarea
                  id="input-hebergement_besoins_speciaux_description"
                  v-model="inscription.details_besoins_speciaux"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
        </Panel>
        <div class="bouton" style="margin-top: 10px">
          <button
            class="button button-primary"
            type="button"
            @click="saveInscription"
          >
            <font-awesome-icon
              :icon="['far', 'floppy-disk']"
              class="disquette"
            />
            Soumettre
          </button>
          <button
            class="button button-primary delete"
            type="button"
            @click="deleteInscription"
            v-if="!creation"
          >
            <font-awesome-icon
              :icon="['fas', 'trash-can']"
              class="box-check-logo"
            />
            Supprimer l'inscription
          </button>
        </div>
        <p class="errorMessage">{{ error }}</p>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { InscriptionAssembleeDTO } from '../types/store/DTO/InscriptionAssembleeDTO';
import Panel from 'primevue/panel';
import RadioButton from 'primevue/radiobutton';
export default defineComponent({
  name: 'CreationModificationInscription',
  data() {
    return {
      error: '',
      creation: false,
      inscription: {} as InscriptionAssembleeDTO,
    };
  },
  components: { Panel, RadioButton },
  created() {
    if (this.$route.params.id) {
      this.creation = false;
      this.getInscriptionInfo(this.$route.params.id as string);
    } else {
      this.creation = true;
    }
  },
  methods: {
    getMemberInfo() {
      if (
        this.inscription.nom != undefined &&
        this.inscription.nom != '' &&
        this.inscription.memberId != '' &&
        this.inscription.memberId != undefined
      ) {
        this.$loading.startLoading();

        let data = {
          lastName: this.inscription.nom,
          memberId: this.inscription.memberId,
        };

        this.$store
          .dispatch('InscriptionAssemblee/feedInscription', data)
          .then((data) => {
            if (data != '') {
              this.inscription.adresse = data.address;
              this.inscription.ville = data.city;
              this.inscription.codePostal = data.postalCode;
              this.inscription.telephone = data.telephone;
              this.inscription.email = data.email;
              this.inscription.age = data.age;
              this.inscription.prenom = data.firstName;
              this.inscription.memberId = data.bandNumber;
              this.inscription.nom = data.lastName;
              this.inscription.ssamtaMemberId = data.ssamtaMemberId;
            }
            this.$loading.stopLoading();
          })
          .catch((message: string) => {
            this.$loading.stopLoading();
          });
      }
    },
    getInscriptionInfo(id: string) {
      this.$store
        .dispatch('InscriptionAssemblee/getInscriptionAdminById', id)
        .then((data: InscriptionAssembleeDTO) => {
          this.inscription = data[0];
        });
    },
    deleteInscription() {
      if (confirm("Voulez-vous vraiment supprimer l'inscription?")) {
        this.$loading.startLoading();
        this.$store
          .dispatch(
            'InscriptionAssemblee/deleteInscriptionAdmin',
            this.$route.params.id as string
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
    saveInscription() {
      this.$loading.startLoading();
      if (this.creation) {
        this.$store
          .dispatch(
            'InscriptionAssemblee/createInscriptionAdmin',
            this.inscription
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      } else {
        this.$store
          .dispatch(
            'InscriptionAssemblee/updateInscriptionAdmin',
            this.inscription
          )
          .then(() => {
            this.$loading.stopLoading();
            this.$router.push('/liste-inscriptions-assemblee-admin');
          })
          .catch((message: string) => {
            this.error = true;
            this.message = message;
            this.$loading.stopLoading();
          });
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.errorMessage {
  font-size: 15px;
  color: red;
}
.titre {
  color: #273566;
  font-size: 26px;
  margin-top: 44px;
  font-weight: 700;
  margin-bottom: 41px;
}
.form-assemblee {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  input {
    font-family: $police;
    font-weight: 600;
    border: 1px solid #cecece;
    border-radius: 3px;
    opacity: 1;
    height: 42px;
    padding: 5px 10px;
    display: block;
    width: 100%;
    margin-top: 5px;
  }
  .input-field {
    margin-bottom: 20px;
    flex-basis: 100%;
  }
  .input-field.small {
    flex-basis: 45%;
    @media screen and (max-width: 600px) {
      flex-basis: 100%;
    }
  }
  .input-field h2 {
    text-align: left;
    border-bottom: 1px solid #acbdde;
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    margin-bottom: 30px;
  }

  .button {
    padding-top: 9px;
    padding-bottom: 9px;
    border: 1px solid #acbdde;
    opacity: 1;
  }
  label {
    color: $colorBleuFonce;
  }
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

.link {
  text-decoration: underline;
}

h2 {
  text-align: left;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-bottom: 25px;
}

.bouton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5%;
  @media screen and (max-width: $md) {
    margin-left: 10%;
  }
  @media screen and (max-width: 425px) {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}
button.button {
  border: none;
  height: 3em;
}

.delete {
  background-color: red;
}
</style>
