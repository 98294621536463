<template>
  <div
    class="background-menu"
    v-if="showMenu"
    @click="closeHamburgerMenu"
  ></div>
  <div
    v-if="userLoggedIn"
    class="barreHeaderDroite"
    v-bind:class="{ bg: showMenu }"
  >
    <label class="hamburger-menu">
      <nav class="sidebar-menu">
        <div class="ico-menu" @click="toggleHamburgerMenu">
          <div>
            MENU
            <font-awesome-icon
              v-if="showMenu"
              :icon="['fas', 'xmark']"
              class="barres-close"
            />
            <font-awesome-icon
              v-else
              :icon="['fas', 'bars']"
              class="barres-close"
            />
          </div>
        </div>
        <div class="menu" v-if="showMenu">
          <ul>
            <li
              v-for="menuItem in menuItems"
              :key="menuItem.title"
              :class="menuItem.class"
              @click="closeHamburgerMenu"
            >
              <div
                :to="menuItem.url"
                class="rl"
                v-if="
                  menuItem.title == 'Déconnexion' || menuItem.title == 'Log out'
                "
                @click="logout"
              >
                <font-awesome-icon
                  :icon="['far', menuItem.icon]"
                  :class="menuItem.iconClass"
                ></font-awesome-icon>
                {{ menuItem.title }}
              </div>
              <router-link :to="menuItem.url" v-else class="rl">
                <font-awesome-icon
                  v-if="menuItem.icon"
                  :icon="['far', menuItem.icon]"
                  :class="menuItem.iconClass"
                ></font-awesome-icon>
                {{ menuItem.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </nav>
    </label>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import EventBus from '@/events/EventBus';
import AccountType from '@/types/enums/AccountType';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'HeaderMenu',
  i18n: {
    sharedMessages: commonMessages,
  },
  data() {
    return {
      showMenu: false,
      userLoggedIn: false,
      role: AccountType.Any,
      menuItems: [],
      menuItemsUser: [
        {
          title: this.$t('distributionCommunautaire'),
          url: '/community-distribution',
          class: 'li-blue',
        },
        {
          title: this.$t('mesCoupons'),
          url: '/mes-coupons',
          class: 'li-blue',
        },
        {
          title: this.$t('monProfil'),
          iconClass: 'fa-user-profil',
          icon: 'user',
          url: '/profil',
          class: 'li-blue',
        },
        {
          title: this.$t('deconnexion'),
          iconClass: 'fa-deconnexion',
          icon: 'arrow-right-from-bracket',
          url: '/',
          class: 'deconnexion',
        },
      ],
      menuItemsAdmin: [
        {
          title: 'Rapports sites de distributions',
          url: '/distribution-site-report',
          class: 'li-blue',
        },
        {
          title: 'Commandes',
          url: '/community-distribution-report',
          class: 'li-blue',
        },
        {
          title: 'Coupons Créées manuellements',
          url: '/liste-couponAdmin',
          class: 'li-blue',
        },
        {
          title: 'Liste des utilisateur local',
          url: '/liste-utilisateur',
          class: 'li-blue',
        },
        {
          title: 'Déconnexion',
          iconClass: 'fa-deconnexion',
          icon: 'arrow-right-from-bracket',
          url: '/',
          class: 'deconnexion',
        },
      ],
      menuItemsDistributor: [
        {
          title: 'Scanner une commande',
          url: '/order-scan',
          class: 'li-blue',
        },
        {
          title: 'Rapport état des commandes',
          url: '/etat-commandes-report',
          class: 'li-blue',
        },
        {
          title: 'Déconnexion',
          iconClass: 'fa-deconnexion',
          icon: 'arrow-right-from-bracket',
          url: '/',
          class: 'deconnexion',
        },
      ],
    };
  },
  methods: {
    toggleHamburgerMenu() {
      this.showMenu = !this.showMenu;
    },
    closeHamburgerMenu() {
      this.showMenu = false;
    },
    getUserAccountType() {
      this.userLoggedIn = this.$store.getters['Auth/isLoggedIn'];
      if (this.userLoggedIn) {
        this.role = this.$store.getters['Auth/accountType'];
        if (this.role == AccountType.Admin) {
          this.menuItems = this.menuItemsAdmin;
        } else if (this.role == AccountType.Distributor) {
          this.menuItems = this.menuItemsDistributor;
        } else {
          this.menuItems = this.menuItemsUser;
        }
      }
    },
    logout() {
      this.$store.dispatch('Auth/signOut').then(() => {
        this.$router.push({ name: 'Login' });
        this.getUserAccountType();
      });
    },
  },
  created() {
    this.getUserAccountType();
  },
  mounted() {
    EventBus.on('userStatusChange', this.getUserAccountType);
  },
});
</script>

<style lang="scss" scoped>
.background-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  opacity: 0.4;
  z-index: 1;
}
.barreHeaderDroite {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 3%;
  margin-top: -15px;
  padding-top: 50px;
  align-items: center;
  z-index: 101;
}
.hamburger-menu {
  width: 283px;
}
.ico-menu {
  margin-left: 65%;
  margin-bottom: 10px;
  color: $colorBleuFonce;
  font-family: $police;
  font-size: 1rem;
  font-weight: 480;
  padding-right: 15px;
}
.ico-menu:hover {
  cursor: pointer;
}
.menu {
  ul {
    margin: 0;
    padding: 0;
    width: 283px;
    text-align: center;
    li {
      list-style-type: none;
      height: 50px;
      padding-top: 4px;
      border-top: 1px solid#ACBDDE;
      .rl {
        padding: 10px 0;
        text-decoration: none;
        font-weight: 400;
        font-size: 17px;
        cursor: pointer;
        display: block;
      }
    }
    .li-blue:hover {
      background: #87a8ea21;
    }
  }
}
.barres-close {
  margin-left: 10px;
}
.deconnexion {
  background-color: #274b93;
  div {
    color: white;
  }
}
.deconnexion:hover {
  background: #dd171b;
}
.fa-deconnexion {
  margin-right: 10px;
  color: white;
}
.fa-user-profil {
  margin-right: 10px;
  color: #274b93;
}
.bg {
  background: white;
}
</style>
