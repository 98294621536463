import { AxiosRequestHeaders } from 'axios';
export default function AuthHeader(): AxiosRequestHeaders {
  const storedUser = sessionStorage.getItem('user');
  const user = JSON.parse(storedUser ? storedUser : '');
  if (user && user.userAuthToken) {
    return {
      Authorization: 'Bearer ' + user.userAuthToken,
      memberId: user.memberId,
    };
  } else {
    console.error('not logged in as user');
    return {};
  }
}
