<template>
  <div class="container-box">
    <div class="header-box" v-if="icon || title">
      <font-awesome-icon :icon="['fal', icon]" class="ico" v-if="icon" />
      <span class="span-title" v-if="title">{{ title }}</span>
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Card',
  props: {
    icon: { type: String },
    title: { type: String },
  },
});
</script>

<style scoped lang="scss">
.container-box {
  padding: 0px;
  margin-top: 25px;
  background-color: $colorBlanc;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  text-align: start;
  .header-box {
    color: $bleuFonce;
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid $grisFonce;
    display: flex;
    .ico {
      font-size: 30px;
      align-self: center;
      margin-right: 10px;
    }
    .span-title {
      font-size: 17px;
      font-weight: 700;
      align-self: center;
      color: $bleuFonce;
    }
  }
}
@media only screen and (max-width: $md) {
  .container-box {
    margin-top: 75px;
  }
}
</style>
