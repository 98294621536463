<template>
  <label class="titre"> Liste des inscriptions à l'assemblée générale </label>
  <div class="contenu">
    <Card>
      <template v-slot:content>
        <div class="filters">
          <div class="filters-date">
            <div>
              <label for="minDate" style="margin-right: 10px">Du</label>
              <input type="date" v-model="minDate" @change="checkDisabled" />
            </div>
            <div>
              <label for="maxDate" style="margin-right: 10px">Au</label>
              <input type="date" v-model="maxDate" @change="checkDisabled" />
            </div>
          </div>
          <button
            class="btn-rapport btn-rigth"
            :disabled="isDisabled"
            :class="{ notActivated: isDisabled }"
            @click="getInscriptionsWithDates"
          >
            <font-awesome-icon :icon="[ico, 'filter']" />
            Filtrer
          </button>
        </div>
      </template>
    </Card>
    <Card>
      <template v-slot:content>
        <div class="recherche-et-bouton-cree">
          <div class="create-user">
            <router-link
              :to="{
                name: 'CreationModificationInscription',
              }"
            >
              <button class="button-primary button">Inscrire un membre</button>
            </router-link>
          </div>
        </div>
        <DataTable
          v-model:selection="selectedInscription"
          dataKey="id"
          selectionMode="single"
          :autoLayout="true"
          :value="inscriptionsList"
          :paginator="true"
          :rows="50"
          ref="dt"
          stripedRows=""
          breakpoint="1300px"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="stack"
          currentPageReportTemplate="{totalRecords} INSCRIPTIONS"
        >
          <Column field="created_at" header="DATE DE CRÉATION" :sortable="true">
            <template #body="{ data }">
              {{ data.dateCreation }}
            </template>
          </Column>
          <Column header="MEMBRE">
            <template #body="{ data }">
              {{ data.prenom }} {{ data.nom }}<br />
              {{ data.email }}<br />
              {{ data.adresse }}, {{ data.ville }}, {{ data.codePostal }}<br />
              {{ data.telephone }}
            </template>
          </Column>
          <Column field="age" header="AGE" />
          <Column header="ACCOMPAGNATEURS">
            <template #body="{ data }">
              <div v-if="data.accompagnement == 'Oui'">
                <p v-if="data.nom_accompagnateur_1 != null">
                  {{ data.nom_accompagnateur_1 }}
                  ({{ data.num_membre_accompagnateur_1 }})
                </p>
                <p v-if="data.nom_accompagnateur_2 != null">
                  {{ data.nom_accompagnateur_2 }}
                  ({{ data.num_membre_accompagnateur_2 }})
                </p>
                <p v-if="data.nom_accompagnateur_3 != null">
                  {{ data.nom_accompagnateur_3 }}
                  ({{ data.num_membre_accompagnateur_3 }})
                </p>
              </div>
            </template>
          </Column>
          <Column field="allergies" header="ALLERGIES">
            <template #body="{ data }">
              <div v-if="data.allergies == 'Oui'">
                Oui
                <p>{{ data.details_allergies }}</p>
              </div>
              <p v-else>Non</p>
            </template>
          </Column>
          <Column field="hebergement" header="HÉBERGEMENT">
            <template #body="{ data }">
              <div v-if="data.hebergement == 'Oui'">
                Oui
                <p>{{ data.nb_chambres }} chambre(s)</p>
                <p v-if="data.besoins_speciaux == 'Oui'">
                  Besoins spéciaux : {{ data.details_besoins_speciaux }}
                </p>
              </div>
              <p v-else>Non</p>
            </template>
          </Column>
          <template #paginatorstart>
            <div class="paginator-display">
              <JsonCSV
                :data="inscriptionsList"
                name="inscriptions.csv"
                delimiter=";"
                v-show="inscriptionsList.length > 0"
              >
                <button class="btn-rapport">
                  <font-awesome-icon :icon="['far', 'file-export']" />
                  Exporter en CSV
                </button>
              </JsonCSV>
              <button
                class="button button-primary"
                v-if="'id' in selectedInscription"
              >
                <router-link
                  :to="{
                    name: 'CreationModificationInscription',
                    params: { id: selectedInscription.id },
                  }"
                >
                  Modifier
                </router-link>
              </button>
            </div>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { InscriptionAssembleeDTO } from '@/types/store/DTO/InscriptionAssembleeDTO';
import JsonCSV from 'vue-json-csv';
export default defineComponent({
  name: 'ListeInscriptionsAssemblee',
  components: { DataTable, Column, Card, JsonCSV },
  data() {
    return {
      search: '',
      inscriptionsList: new Array<InscriptionAssembleeDTO>(),
      inscriptionsListCopie: new Array<InscriptionAssembleeDTO>(),
      ico: 'far',
      selectedInscription: {} as InscriptionAssembleeDTO,
      minDate: null,
      maxDate: null,
      isDisabled: true,
    };
  },
  methods: {
    getInscriptionsWithDates() {
      if (this.minDate != null && this.maxDate != null) {
        if (this.minDate > this.maxDate) {
          return;
        }
        this.$loading.startLoading();
        this.$store
          .dispatch('Rapports/inscriptionsAdminWithDates', {
            minDate: this.minDate,
            maxDate: this.maxDate,
          })
          .then((data: Array<InscriptionAssembleeDTO>) => {
            if (data != undefined) {
              this.inscriptionsList = data;
              this.inscriptionsListCopie = data;
            } else {
              this.inscriptionsList = new Array<InscriptionAssembleeDTO>();
              this.inscriptionsListCopie = new Array<InscriptionAssembleeDTO>();
            }
            this.$loading.stopLoading();
          })
          .catch(() => {
            this.$loading.stopLoading();
          })
          .finally(() => {
            this.$loading.stopLoading();
          });
      }
    },
    checkDisabled() {
      if (
        this.minDate == null ||
        this.maxDate == null ||
        this.minDate == '' ||
        this.maxDate == ''
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    dateParserLong(date: string) {
      return dateParserLong(date);
    },
    getInscriptions() {
      this.$loading.startLoading();
      this.$store
        .dispatch('InscriptionAssemblee/getAllInscriptionsAssembleeByAdmin')
        .then((data: Array<InscriptionAssembleeDTO>) => {
          if (data != undefined) {
            this.inscriptionsList = data;
            this.inscriptionsListCopie = data;
          } else {
            this.inscriptionsList = new Array<InscriptionAssembleeDTO>();
            this.inscriptionsListCopie = new Array<InscriptionAssembleeDTO>();
          }
          this.$loading.stopLoading();
        })
        .catch(() => {
          this.$loading.stopLoading();
        })
        .finally(() => {
          this.$loading.stopLoading();
        });
    },
  },
  created() {
    this.getInscriptions();
  },
});
</script>

<style scoped lang="scss">
header {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $bleuCiel;
}
.recherche-et-bouton-cree {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.create-user {
  margin-top: 20px;
  margin-right: 1%;
  button {
    width: 15em;
    height: 3em;
  }
}
.titre {
  color: #273566;
  font-size: 26px;
  margin-top: 44px;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.titre-page {
  font-weight: bold;
  color: $bleuCiel;
}
.ico {
  font-size: 20px;
  color: $bleuFonce;
}
.contenu {
  margin: 5%;
}
.liste-user {
  color: $bleuFonce;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight) {
  background: #e3f2fd !important;
}
.btn-rigth {
  //margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.button a {
  color: #fff !important;
}
.cherch-btn {
  width: 100%;
  margin-top: 20px;
  input {
    height: 42px;
    //width: 50%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.paginator-display {
  display: flex !important;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
input[type='date'] {
  height: 42px;
  border: 1px solid $grisFonce;
  padding: 10px;
  margin-bottom: 10px;
}
.filters {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
.filters-date {
  display: flex;
  gap: 10px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    div {
      width: 100%;
    }
    input {
      width: 100% !important;
      display: block;
    }
  }
}
</style>
