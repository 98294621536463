<template>
  <div>
    <Card>
      <template v-slot:content>
        <p class="sites">Sites de distribution</p>
        <select v-model="site" @change="filterDatatable" placeholder="Tous">
          <option selected value="-1">Tous</option>
          <option
            v-for="adress in adresses"
            :key="adress.ville"
            :value="adress.id"
          >
            {{ uppercase(adress.ville) }} - {{ adress.lieux }}
          </option>
        </select>
        <div class="cherch-btn">
          <input
            type="text"
            placeholder="Rechercher par nom, téléphone…"
            v-model="search"
          />
          <button class="recherche" @click="filter">
            <font-awesome-icon :icon="[ico, 'magnifying-glass']" />
          </button>
        </div>
        <p class="lieu">{{ nomSite }}</p>
        <DataTable
          :value="rappots"
          :paginator="true"
          :rows="10"
          ref="dt"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} COMMANDES"
        >
          <Column header="DATE">
            <template #body="{ data }">
              {{ dateParserLong(data.created_at) }}
            </template>
          </Column>
          <Column field="id" header="Nº COMMANDE">
            <template #body="{ data }">
              {{ padLeadingZeros(data.pivot.commande_id) }}
            </template>
          </Column>
          <Column header="NOM">
            <template #body="{ data }">
              {{ data.ssamtaUser.firstName }} <br />
              {{ data.ssamtaUser.lastName }}
            </template>
          </Column>
          <Column header="BOITE">
            <template #body="{ data }">
              <font-awesome-icon
                v-if="data.type === 'poisson'"
                icon="fish"
                class="ico"
                v-bind:class="{ showIcon: data.path_icone }"
              />
              <font-awesome-icon
                v-else-if="data.type === 'gibier'"
                icon="deer"
                class="ico gibier"
                v-bind:class="{ showIcon: data.path_icone }"
              />
              <img
                v-else-if="data.type === 'saumon'"
                class="saumon-icon"
                src="../../assets/img/icon-saumon.svg"
                alt="saumon"
                v-bind:class="{ showIcon: data.path_icone }"
              />
            </template>
          </Column>
          <Column header="ADRESSE">
            <template #body="{ data }">
              {{ data.ssamtaUser.address }} <br />
              {{ data.ssamtaUser.city }} <br />
              {{ data.ssamtaUser.postalCode }}
            </template>
          </Column>
          <Column header="TÉLÉPHONE"
            ><template #body="{ data }">
              {{ data.ssamtaUser.telephone }}
            </template>
          </Column>
          <Column header="CELLULAIRE">
            <template #body="{ data }">
              {{ data.ssamtaUser.mobile }}
            </template>
          </Column>
          <Column header="COURRIEL"
            ><template #body="{ data }">
              {{ data.ssamtaUser.email }}
            </template>
          </Column>
          <template #paginatorstart>
            <button class="btn-rapport" @click="exportCSV">
              <font-awesome-icon :icon="[ico, 'file-export']" />
              Exporter en CSV
            </button>
            <button class="btn-rapport left-margin" @click="exportPDF">
              <font-awesome-icon :icon="[ico, 'file-export']" />
              Exporter en PDF
            </button>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
export default defineComponent({
  name: 'RapportSd',
  components: { DataTable, Column, Card },
  data() {
    return {
      adresses: [] as Array<siteDistributionDTO>,
      rappots: new Array<CommandeDTO>(),
      copieRappots: new Array<CommandeDTO>(),
      ico: 'far',
      site: -1,
      nomSite: '',
      search: '',
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getCommandes() {
      this.$store.dispatch('Rapports/getRapportBySite').then((data) => {
        data.forEach((commande) => {
          commande.produits.forEach((produit) => {
            if (produit.nom === 'Boîte de poisson') {
              produit['type'] = 'poisson';
            } else if (produit.nom === 'Boîte de gibier') {
              produit['type'] = 'gibier';
            } else if (produit.nom === 'Boîte de saumon') {
              produit['type'] = 'saumon';
            }
            produit.ssamtaUser = commande.ssamtaUser;
            produit.site_distribution_id = commande.site_distribution_id;
            this.rappots.push(produit);
          });
        });
        this.copieRappots = this.rappots;
      });
    },
    filterDatatable() {
      this.rappots = this.copieRappots;
      if (this.site === '' || this.site == -1) {
        return;
      }
      const result = this.rappots.filter((rapport) => {
        return rapport.site_distribution_id === this.site;
      });
      this.rappots = result;
    },
    // async getRapports() {
    //   debugger;
    //   if (this.site != null) {
    //     this.$store
    //       .dispatch('Rapports/getRapportBySite', { site: this.site })
    //       .then((data: Array<CommandeDTO>) => {
    //         this.rappots = data;
    //         this.copieRappots = this.rappots;
    //       });
    //     const siteDistrib = this.adresses.find((add) => add.id === this.site);
    //     if (siteDistrib !== undefined) {
    //       this.nomSite = siteDistrib.ville;
    //     }
    //   }
    // },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande: CommandeDTO) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.firstName) ||
        this.getIfStringMatchSearch(commande.lastName) ||
        this.getIfStringMatchSearch(commande.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.adress) ||
        this.getIfStringMatchSearch(commande.city) ||
        this.getIfStringMatchSearch(commande.postalCode)
      );
    },
    filter() {
      this.rappots = this.copieRappots;
      const result = this.rappots.filter(this.getIfCommandeMatch);
      this.rappots = result;
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesSiteCsv', this.site).then();
    },
    exportPDF() {
      this.$store.dispatch('Rapports/getPdfSite', this.site).then();
    },
    uppercase(text: string) {
      return text.toUpperCase();
    },
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.adresses = data;
        });
    },
  },
  created() {
    this.siteDistrib();
    this.getCommandes();
  },
});
</script>

<style scoped lang="scss">
.ico {
  font-size: 20px;
  color: $bleuFonce;
  opacity: 0;
}
.sites {
  color: $bleuFonce;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.lieu {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid $bleuFonce;
  color: $bleuFonce;
  font-weight: bold;
}
.gibier {
  color: $rougeFonce;
}
.saumon-icon {
  width: 2em;
  opacity: 0;
}
.showIcon {
  opacity: 1;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
select {
  height: 42px;
  border: 1px solid $grisFonce;
  padding-left: 10px;
  max-width: 40%;
  display: block;
}
select:focus {
  outline: none;
}
.btn-rigth {
  margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.cherch-btn {
  margin-top: 20px;
  input {
    height: 42px;
    width: 30%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
