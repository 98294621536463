<template>
  <div class="content-boite input-from mt">
    <label>{{ $t('noCertificat') }}</label>
    <input
      type="text"
      placeholder=""
      v-model="noCertificatLocal"
      @change="emitInterface()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'NoCertificatForm',
  i18n: {
    sharedMessages: commonMessages,
  },
  props: {
    noCertificat: String,
  },
  data() {
    return {
      noCertificatLocal: '',
    };
  },
  methods: {
    emitInterface() {
      this.$emit('interface', {
        noCertificat: this.noCertificatLocal,
      });
    },
  },
  mounted() {
    this.emitInterface();
  },

  emits: ['interface', 'noCertificatLocal'],
});
</script>

<style scoped lang="scss">
.content-boite {
  .input-2 {
    display: flex;
  }
}
.mr {
  margin-right: 15px;
}
.mt {
  margin-top: 15px;
}
.input-from {
  width: 100%;
  label {
    color: $bleuFonce;
    margin-bottom: 5px;
  }
  .errorMessage {
    label {
      color: red;
      font-size: 12px;
    }
  }
  input,
  select {
    display: block;
    height: 42px;
    width: 100%;
    padding-left: 15px;
    border-radius: 3px;
    border: 1px solid $colorGrisBorder;
  }
  input:focus,
  select:focus {
    outline: none;
  }
  .postalCode {
    width: 25%;
  }
  @media only screen and (max-width: $md) {
    .postalCode {
      width: 48%;
    }
  }
}
</style>
