import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0592d092"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-boite input-from mt" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", null, _toDisplayString(_ctx.$t('noCertificat')), 1),
    _withDirectives(_createElementVNode("input", {
      type: "text",
      placeholder: "",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noCertificatLocal) = $event)),
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitInterface()))
    }, null, 544), [
      [_vModelText, _ctx.noCertificatLocal]
    ])
  ]))
}