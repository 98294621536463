<template>
  <header>
    <h3 class="titre-page">DISTRIBUTION COMMUNAUTAIRE</h3>
  </header>
  <label class="titre"> LISTE DES UTILISATEURS </label>
  <div class="contenu">
    <Card>
      <template v-slot:content>
        <p class="liste-user">Liste des utilisateurs</p>
        <div class="recherche-et-bouton-cree">
          <div class="cherch-btn">
            <input
              type="text"
              placeholder="Rechercher par nom, téléphone…"
              v-model="search"
            />
            <button class="recherche" @click="filter()">
              <font-awesome-icon :icon="[ico, 'magnifying-glass']" />
            </button>
          </div>
          <div class="create-user">
            <button class="button-primary button">
              <router-link
                :to="{
                  name: 'ModificationUtilisateur',
                }"
              >
                Créer un utilisateur
              </router-link>
            </button>
          </div>
        </div>
        <DataTable
          v-model:selection="selectedUser"
          dataKey="id"
          selectionMode="single"
          :autoLayout="true"
          :value="userList"
          :paginator="true"
          :rows="10"
          ref="dt"
          stripedRows=""
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} UTILISATEURS"
        >
          <Column field="created_at" header="DATE DE CRÉATION" :sortable="true">
            <template #body="{ data }">
              {{ dateParserLong(data.created_at) }}
            </template>
          </Column>
          <Column field="id" header="ID" />
          <Column field="firstName" header="NOM">
            <template #body="{ data }">
              {{ getFirstName(data.name) }}
            </template>
          </Column>
          <Column field="lastName" header="PRENOM">
            <template #body="{ data }">
              {{ getLastName(data.name) }}
            </template>
          </Column>
          <Column field="email" header="EMAIL" />
          <Column field="role.role" header="RÔLE" />
          <template #paginatorstart>
            <button class="button button-primary" v-if="'id' in selectedUser">
              <router-link
                :to="{
                  name: 'ModificationUtilisateur',
                  params: { userId: selectedUser.id },
                }"
              >
                Modifier
              </router-link>
            </button>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { dateParserLong } from '@/helpers/dateParserHelper';
import { UsersDTO } from '../types/store/DTO/UsersDTO';
import Card from '@/components/Card.vue';
export default defineComponent({
  name: 'listeUtilisateurs',
  components: { DataTable, Column, Card },
  data() {
    return {
      search: '',
      userList: new Array<UsersDTO>(),
      userListCopie: new Array<UsersDTO>(),
      ico: 'far',
      selectedUser: {} as UsersDTO,
    };
  },
  methods: {
    getLastName(fullName: string) {
      var firstName = fullName.split(' ').slice(0, -1).join(' ');
      return firstName;
    },
    getFirstName(fullName: string) {
      var lastName = fullName.split(' ').slice(-1).join(' ');
      return lastName;
    },
    dateParserLong(date: string) {
      return dateParserLong(date);
    },
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getUsers() {
      this.$store
        .dispatch('Users/getAllUsers')
        .then((data: Array<UsersDTO>) => {
          this.userList = data;
          this.userListCopie = this.userList;
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfUserMatch(user: UsersDTO) {
      return (
        this.getIfStringMatchSearch(user.name) ||
        this.getIfStringMatchSearch(user.email) ||
        this.getIfStringMatchSearch(dateParserLong(user.created_at)) ||
        this.getIfStringMatchSearch(user.memberId) ||
        this.getIfStringMatchSearch(user.role.role)
      );
    },
    filter() {
      this.userList = this.userListCopie;
      const result = this.userList.filter(this.getIfUserMatch);
      this.userList = result;
    },
  },
  created() {
    this.getUsers();
  },
});
</script>

<style scoped lang="scss">
header {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid $bleuCiel;
}
.recherche-et-bouton-cree {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.create-user {
  margin-top: 20px;
  margin-right: 1%;
  button {
    width: 15em;
    height: 3em;
  }
}
.titre {
  color: #273566;
  font-size: 26px;
  margin-top: 44px;
  font-weight: 700;
  display: flex;
  justify-content: center;
}
.titre-page {
  font-weight: bold;
  color: $bleuCiel;
}
.ico {
  font-size: 20px;
  color: $bleuFonce;
}
.contenu {
  margin: 5%;
}
.liste-user {
  color: $bleuFonce;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
::v-deep(.p-datatable.p-datatable-striped
    .p-datatable-tbody
    > tr:nth-child(even).p-highlight) {
  background: #e3f2fd !important;
}
.btn-rigth {
  margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.button a {
  color: #fff !important;
}
.cherch-btn {
  width: 50%;
  margin-top: 20px;
  input {
    height: 42px;
    width: 50%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
