<template>
  <div class="coupons-manuels">
    <div class="distribution-communautaire">DISTRIBUTION COMMUNAUTAIRE</div>
    <div id="ligne-pale" />
    <div class="titre"><h1>Coupons créés manuellement</h1></div>
  </div>
  <div class="contenu">
    <div class="recherche-et-bouton">
      <div class="barre-de-recherche">
        <input
          type="text"
          placeholder="Rechercher par numéro de commande"
          name="recherche"
          id="barre-recherche"
          v-model="textSearch"
        />
        <button
          class="btn-recherche"
          @click="updateCommandesDependingPagesAndSearch"
        >
          <font-awesome-icon :icon="['fas', 'search']" class="logo-recherche" />
        </button>
      </div>
      <button class="button" @click="toCreateCoupon">
        <font-awesome-icon
          :icon="['fas', 'file-plus']"
          class="logo-in-button"
        />
        Créer un coupon
      </button>
    </div>
    <span><h2>Résultats</h2></span>
    <div id="ligne-fonce" />
    <div class="datatable" id="table">
      <div class="datatable-row">
        <div class="datatable-row-header telechargement">Télécharger</div>
        <div class="datatable-row-header date">Date</div>
        <div class="datatable-row-header no-commande">Nº Commande</div>
        <div class="datatable-row-header coupon">Coupon</div>
        <div class="datatable-row-header nom">Nom</div>
        <div class="datatable-row-header no-membre">Nº De Membre</div>
        <!-- <div class="datatable-row-header boite">Boite</div> -->
        <div class="datatable-row-header adresse">Adresse</div>
        <div class="datatable-row-header no-telephone">Téléphone</div>
        <div class="datatable-row-header mobile">Cellulaire</div>
        <div class="datatable-row-header modifier"></div>
      </div>
      <div
        class="datatable-row not-th gris"
        v-for="commande in this.commandes"
        :key="commande"
      >
        <div class="datatable-row-cell">
          <div class="datatable-row-cell-downloadcontainer">
            <div class="datatable-row-header telechargement mobile-header">
              Télécharger
            </div>
            <a
              :href="getUrlCoupon(commande)"
              download="couponPoisson.pdf"
              v-if="commande.type === 'poisson'"
            >
              <font-awesome-icon
                :icon="['fas', 'fish']"
                class="logo-download fish"
                v-bind:class="{ show: commande.path_icone }"
              />
            </a>
            <a
              :href="getUrlCoupon(commande)"
              download="couponViande.pdf"
              v-else-if="commande.type === 'gibier'"
            >
              <font-awesome-icon
                :icon="['fas', 'deer']"
                class="logo-download deer"
                v-bind:class="{ show: commande.path_icone }"
              />
            </a>
            <a
              :href="getUrlCoupon(commande)"
              download="couponSaumon.pdf"
              v-else-if="commande.type === 'saumon'"
            >
              <img
                class="logo-download saumon"
                src="../assets/img/icon-saumon.svg"
                alt="saumon"
                v-bind:class="{ show: commande.path_icone }"
              />
            </a>
          </div>
        </div>

        <div class="datatable-row-cell date-container">
          <div class="datatable-row-header date mobile-header">Date</div>
          {{ dateParserLong(commande.pivot.created_at) }}
        </div>
        <div class="datatable-row-cell no-commande-container">
          <div class="datatable-row-header no-commande mobile-header">
            Nº Commande
          </div>
          {{ padLeadingZeros(commande.commande_id) }}
        </div>
        <div class="datatable-row-cell coupon-container">
          <div class="datatable-row-header coupon mobile-header">Coupon</div>
          {{ padLeadingZeros(commande.id) }}
        </div>
        <div class="datatable-row-cell nom-container">
          <div class="datatable-row-header nom mobile-header">Nom</div>
          {{ commande.ssamtaUser.firstName }} {{ commande.ssamtaUser.lastName }}
        </div>
        <div class="datatable-row-cell no-membre-container">
          <div class="datatable-row-header no-membre mobile-header">
            Nº de Membre
          </div>
          {{ commande.ssamtaUser.bandNumber }}
        </div>
        <!-- <div class="datatable-row-cell boite-container">
          <div class="datatable-row-header boite mobile-header">Boite(s)</div>
          <div class="datatable-row-cell-downloadcontainer-boites-logos">
            <font-awesome-icon
              v-if="commande.type === 'poisson'"
              :icon="['fas', 'fish']"
              class="logo-boite fish"
              id="fish"
              v-bind:class="{ show: commande.path_icone }"
            />
            <font-awesome-icon
              v-else-if="commande.type === 'gibier'"
              :icon="['fas', 'deer']"
              class="logo-boite deer"
              id="deer"
              v-bind:class="{ show: commande.path_icone }"
            />
            <img
              v-else-if="commande.type === 'saumon'"
              class="logo-boite saumon"
              src="../assets/img/icon-saumon.svg"
              alt="saumon"
              v-bind:class="{ show: commande.path_icone }"
            />
          </div>
        </div> -->
        <div class="datatable-row-cell adresse-container">
          <div class="datatable-row-header adresse mobile-header">Adresse</div>
          <p>{{ commande.ssamtaUser.address }}</p>
          <p>{{ commande.ssamtaUser.city }}</p>
          <p>{{ commande.ssamtaUser.postalCode }}</p>
        </div>
        <div class="datatable-row-cell no-telephone-container">
          <div class="datatable-row-header no-telephone mobile-header">
            Téléphone
          </div>
          {{ commande.ssamtaUser.telephone }}
        </div>
        <div class="datatable-row-cell mobile-container">
          <div class="datatable-row-header mobile mobile-header">
            Cellulaire
          </div>
          {{ commande.ssamtaUser.mobile }}
        </div>
        <div class="datatable-row-cell modifier-container">
          <router-link
            class="button"
            :to="{
              name: 'CreationModificationCoupon',
              params: { id: commande.commande_id },
            }"
          >
            Modifier
          </router-link>
        </div>
      </div>
    </div>
    <div class="footer-table">
      <div class="footer-table-content">
        <button class="btn-rapport" @click="exportCSV">
          <font-awesome-icon :icon="[ico, 'file-export']" />
          Exporter en CSV
        </button>
      </div>
      <div class="pagination footer-table-content">
        <!-- <div>
        <button class="btn-rapport" @click="exportCSV">
          <font-awesome-icon :icon="[ico, 'file-export']" />
          Exporter en CSV
        </button>
      </div> -->
        <p>{{ tableSize }} RÉSULTATS</p>
        <font-awesome-icon
          :icon="['fas', 'caret-left']"
          class="logo-fleche"
          @click="previousPage"
          id="fleche-gauche"
          v-if="currentPage > 1"
        />
        <div class="no-page">Page {{ currentPage }} de {{ totalPages }}</div>
        <font-awesome-icon
          :icon="['fas', 'caret-right']"
          class="logo-fleche"
          @click="nextPage"
          v-if="currentPage + 1 <= totalPages"
          id="fleche-droite"
        />
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import { CommandeDTO } from '@/types/store/DTO/CommandeDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';

export default defineComponent({
  name: 'ListeCouponsAdmin',
  data() {
    return {
      commandes: new Array<CommandeDTO>(),
      commandesCopie: new Array<CommandeDTO>(),
      tableSize: -1,
      itemPerPages: 10,
      textSearch: '',
      currentPage: 1,
      totalPages: -1,
    };
  },
  created() {
    this.fetchCommandes();
  },
  methods: {
    getUrlCoupon(data) {
      return (
        process.env.VUE_APP_BACKEND_URL +
        'getCoupon/' +
        data.pivot.commande_id +
        '/' +
        data.pivot.uuid +
        '/' +
        data.type
      );
    },
    // getURLPoisson(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponPoisson/' + uuid;
    // },
    // getURLViande(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponGibier/' + uuid;
    // },
    // getURLSaumon(uuid: string): string {
    //   return process.env.VUE_APP_BACKEND_URL + 'getCouponSaumon/' + uuid;
    // },
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    previousPage() {
      if (this.currentPage == 1) {
        return;
      }
      this.currentPage = this.currentPage - 1;
      this.updateCommandesDependingPagesAndSearch();
    },
    nextPage() {
      if (this.currentPage == this.totalPages) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      this.updateCommandesDependingPagesAndSearch();
    },
    fetchCommandes() {
      this.$store
        .dispatch('Rapports/getCommandesMadeByAdminsPerPage')
        .then((data: Array<CommandeDTO>) => {
          this.commandesCopie = data;
          this.updateCommandesDependingPagesAndSearch();
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.textSearch;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.firstName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.lastName) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.address) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.city) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.postalCode) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.telephone) ||
        this.getIfStringMatchSearch(commande.ssamtaUser.mobile)
      );
    },
    updateCommandesDependingPagesAndSearch() {
      const commandes = this.commandesCopie.filter(this.getIfCommandeMatch);
      this.tableSize = commandes.length;
      this.totalPages = Math.ceil(this.tableSize / this.itemPerPages);
      this.commandes = commandes.splice(
        (this.currentPage - 1) * this.itemPerPages,
        this.itemPerPages
      );
    },
    downloadPDF(id: number, poisson: boolean, gibier: boolean) {
      if (poisson) {
        this.$store.dispatch('Rapports/getCouponPoisson', id).then();
      }
      if (gibier) {
        this.$store.dispatch('Rapports/getCouponGibier', id).then();
      }
    },
    toCreateCoupon() {
      this.$router.push({ name: 'CreationModificationCoupon' });
    },
    toModifyCoupon(id: string) {
      this.$router.push({ name: 'CreationModificationCoupon/' + id });
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesAdminCsv').then();
    },
  },
});
</script>
<style lang="scss" scoped>
.contenu {
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  width: 90%;
  height: 100%;
  background-color: $white;
  padding: 1%;
  margin-bottom: 8%;
  box-shadow: 0px 0px 6px #87a8ea21;
  border-radius: 4px;
  overflow: auto;
  .recherche-et-bouton {
    @media screen and (max-width: $lg) {
      display: flex;
      flex-direction: column-reverse;
      height: 8em;
      align-items: center;
      .button {
        margin-bottom: 0.5em;
        margin-top: 0.5em;
        white-space: nowrap;
        width: 80%;
      }
      .barre-de-recherche {
        width: 97%;
        margin-bottom: 0.5em;
      }
    }
  }
}
.titre {
  text-align: center;
  margin: 3% 0 3% 0;
  color: $bleu;
}
.distribution-communautaire {
  text-align: center;
  font-size: 1.7em;
  color: $grisFonce;
  font-weight: 600;
  margin-top: 3%;
  margin-bottom: 1%;
}
#ligne-pale {
  border: 1px solid $grisFonce;
}
#ligne-fonce {
  border: 1px solid $bleuFonce;
  margin: 1%;
}
.recherche-et-bouton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3em;
  margin-bottom: 2%;
}
.barre-de-recherche,
h2 {
  margin-left: 1%;
}
.gris {
  background-color: #f4f4f4;
}
.logo-boite {
  height: 21px;
  width: 22px;
  opacity: 0;
}

input {
  border: 1px solid $grisFonce;
  width: 85%;
  height: 100%;
  border-radius: 3px 0px 0px 3px;
}
.btn-recherche {
  background-color: $bleuFonce;
  border: 1px solid $bleuFonce;
  height: 100%;
  width: 12%;
  border-radius: 0px 4px 4px 0px;
}
.logo-recherche {
  color: white;
}
.barre-de-recherche {
  width: 30%;
  height: 100%;
}
.button {
  background-color: $bleuFonce;
  border: none;
  color: white;
  width: 13%;
  height: 90%;
  @media screen and (max-width: 1750px) {
    width: 30%;
  }
}
.logo-in-button {
  transform: scale(1.3);
  margin-right: 2%;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.footer-table {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    .footer-table-content {
      width: 100%;
    }
    .pagination {
      justify-content: space-between;
    }
  }
}
.pagination {
  color: $bleuFonce;
  justify-content: end;
  margin-bottom: 1%;
  margin-right: 2%;
  width: 35%;
}
.pagination p {
  color: $bleuFonce;
  margin-right: 3%;
  padding-top: 2.5px;
}
.no-page {
  border: 1px solid #dee2e6;
  padding: 0.2% 0.5% 0.2% 0.5%;
  margin-right: 0.5%;
  margin-left: 0.5%;
}
.logo-fleche {
  color: $grisFonce;
  transform: scale(1.5);
  padding-top: 5px;
}
.button-mobile {
  width: 50%;
  height: 3em;
  margin: 5% 0% 5% 25%;
  @media screen and (min-width: 640px) {
    font-size: 1.5em;
  }
}
.recherche-mobile {
  width: 100%;
  height: 3em;
  display: flex;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 10%;
}
.recherche-mobile input {
  width: 80%;
}
.btn-recherche-mobile {
  width: 15%;
}
h4 {
  font-size: 13px;
  color: $bleuFonce;
  font-weight: 400;
  @media screen and (min-width: 640px) {
    font-size: 1.5em;
  }
}
// .section-mobile {
//   background-color: s;
// }
// .section-mobile {
//   margin: 3% 1% 1% 1%;
//   padding: 10%;
//   display: flex;
//   flex-wrap: wrap;
// }
// .date-mobile {
//   flex-basis: 50%;
//   margin-bottom: 15%;
// }
// .commande-mobile {
//   flex-basis: 50%;
//   margin-bottom: 15%;
// }
// .coupon-mobile {
//   flex-basis: 50%;
//   margin-bottom: 15%;
// }
// .boite-mobile {
//   flex-basis: 50%;
//   margin-bottom: 15%;
// }
// .nom-mobile {
//   flex-basis: 100%;
//   margin-bottom: 15%;
// }
// .adresse-mobile {
//   flex-basis: 70%;
//   margin-bottom: 15%;
// }
// .telephone-mobile {
//   flex-basis: 100%;
//   margin-bottom: 15%;
// }
// .courriel-mobile {
//   flex-basis: 100%;
//   margin-bottom: 15%;
// }
// .mobile p {
//   font-size: 13px;
//   font-weight: 600;
//   @media screen and (min-width: 640px) {
//     font-size: 1.5em;
//   }
// }
.btn-telecharger {
  margin-left: 0%;
  width: 70%;
}
.logo-download-mobile {
  color: white;
}
.mobile-logo-boite {
  @media screen and (min-width: 640px) {
    width: 50px;
    height: 50px;
  }
}
.show {
  opacity: 1 !important;
}
.logo-fleche-mobile {
  height: 25px;
  width: 25px;
}
.logo-download-gibier {
  color: $rougeFonce;
  margin-left: 10%;
}
button a {
  color: white;
}
.datatable {
  width: 99%;
  display: table;
  margin-left: 1%;
  border-bottom: 1px solid $grisFonce;
  margin-bottom: 3%;
  &-row:nth-child(odd) .datatable-row-cell {
    background: #f4f4f4;
  }
  &-row:nth-child(even) .datatable-row-cell {
    background: #fff;
  }
  &-row {
    display: table-row;
    vertical-align: center;
    padding: 1% 0px 1% 1%;
    @media screen and (max-width: $lg) {
      padding: 0;
    }
    .logo-download {
      transform: scale(1.3);
      color: $bleuFonce;
    }
    .not-th {
      height: 5em;
    }
    .mobile-header {
      display: none;
    }
    @media screen and (max-width: $lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .mobile-header {
        display: flex;
        flex-basis: 100%;
        margin-bottom: 0.75em;
      }
      .date-container {
        flex-basis: 50%;
        flex-wrap: wrap;
      }
      .telechargement-container {
        flex-basis: 50%;
        flex-wrap: wrap;
      }
      .no-commande-container {
        flex-basis: 50%;
        flex-wrap: wrap;
      }
      .coupon-container {
        flex-basis: 50%;
        flex-wrap: wrap;
      }
      .nom-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
      .no-membre-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
      .boite-container {
        flex-basis: 100%;
        flex-wrap: wrap;
        // flex-direction: row;
      }
      .adresse-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
      .no-telephone-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
      .mobile-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
      .modifier-container {
        flex-basis: 100%;
        flex-wrap: wrap;
      }
    }
    &-header {
      padding: 0 10px;
      display: table-cell;
      color: $bleuFonce;
      text-transform: uppercase;
      @media screen and (max-width: $lg) {
        padding: 0;
        text-transform: inherit;
        font-size: 12px;
        flex-direction: row;
        display: none;
        .telechargement {
          display: flex;
          width: 100%;
        }
      }
    }
    &-cell {
      padding: 10px;
      @media screen and (max-width: $lg) {
        flex-basis: 50%;
        padding: 3% 0 1% 3%;
        font-weight: 600;
      }
      display: table-cell;
      .btn-modifier {
        width: 100px;
      }
      &-downloadcontainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        // width: 100%;
        &-boites-logos {
          display: flex;
          @media screen and (max-width: $lg) {
            display: flex;
            width: 25px;
            svg {
              margin-right: 5px;
            }
          }
        }
      }
      .logo-download {
        opacity: 0;
        padding-right: 10px;
      }
      .logo-download.deer {
        padding-right: 15px;
      }
      .fish {
        color: $bleuFonce;
        width: 100%;
      }
      .deer {
        color: $rougeFonce;
        width: 100%;
      }
      .saumon {
        width: 2em;
      }
    }
  }
}
</style>
