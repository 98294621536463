import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6044c3d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "onChange"]
const _hoisted_2 = {
  key: 0,
  class: "nom-permis"
}
const _hoisted_3 = {
  key: 1,
  class: "nom-permis"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availablePermis, (perm) => {
    return (_openBlock(), _createElementBlock("div", {
      key: perm.id,
      class: "content-boite"
    }, [
      _createElementVNode("span", null, [
        _createElementVNode("input", {
          type: "checkbox",
          value: perm.id,
          onChange: ($event: any) => (_ctx.updateSelectedPermis(perm))
        }, null, 40, _hoisted_1),
        (_ctx.locale === 'fr')
          ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(perm.nom), 1))
          : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(perm.nom_en), 1))
      ])
    ]))
  }), 128))
}