import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Profil from '@/components/Profil.vue';
import CommunityDistributionView from '../views/CommunityDistributionView.vue';
import HomeView from '../views/Home.vue';
import UIKit from '../views/UIKit.vue';
import LoginAdmin from '../views/LoginAdmin.vue';
import Login from '../views/Login.vue';
import ConfirmationCommande from '../views/ConfirmationCommande.vue';
import RapportsDc from '../views/RapportsDistributionCom.vue';
import RapportEc from '../views/RapportEtatCommandes.vue';
import RapportEcAdmin from '../views/RapportEtatCommandesAdmin.vue';
import RapportsSd from '../views/RapportsSites.vue';
import Remerciment from '../views/Remerciment.vue';
import RemercimentPermis from '../views/RemercimentPermis.vue';
import RemercimentAssemblee from '../views/RemercimentAssemblee.vue';
import ListeCouponsAdmin from '../views/ListeCouponsAdmin.vue';
import ListeInscriptionsAssembleeAdmin from '../views/ListeInscriptionsAssembleeAdmin.vue';
import OrderScan from '../views/OrderScan.vue';
import Coupons from '../views/Coupons.vue';
import Permis from '../views/Permis.vue';
import ConfirmationCommandePermis from '../views/ConfirmationCommandePermis.vue';
import store from '../store/index';
import CreationModificationCoupon from '../views/CreationModificationCoupon.vue';
import CreationModificationInscription from '../views/CreationModificationInscription.vue';
import ListeUtilisateurs from '../views/ListeUtilisateurs.vue';
import ModificationUtilisateur from '../views/CreationModificationUtilisateur.vue';
import AccountType from '../types/enums/AccountType';
import FormulaireAssembleeGenerale from '../views/FormulaireAssembleeGenerale.vue';
import ListeInscriptionsAssemblee from '../views/ListeInscriptionsAssemblee.vue';
import RapportCommandesPermisAdmin from '../views/RapportCommandesPermisAdmin.vue';
import EspaceVideo from '@/views/EspaceVideo.vue';
import Elections from '@/views/Elections.vue';
import ReponsesElections from '@/views/ReponsesCandidatsElections.vue';
import ReponsesCandidatsElections from '@/views/ReponsesCandidatsElections.vue';
import RapportCs from '@/views/RapportCommandesSupprimees.vue';
import CouponPromoMembre from '@/views/CouponPromoMembre.vue';
import ImportationCouponsPromo from '@/views/ImportationCouponsPromo.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { displayName: 'accueil', accountType: AccountType.Any },
  },
  {
    path: '/community-distribution',
    name: 'CommunityDistribution',
    meta: {
      parent: 'Home',
      displayName: 'distributionCommunautaire',
      accountType: AccountType.User,
    },
    component: CommunityDistributionView,
  },
  {
    path: '/order-validation',
    name: 'OrderValidation',
    component: ConfirmationCommande,
    meta: {
      parent: 'CommunityDistribution',
      displayName: 'confirmationCommande',
      accountType: AccountType.User,
    },
  },
  {
    path: '/commande-permis',
    name: 'CommandePermis',
    component: ConfirmationCommandePermis,
    meta: {
      parent: 'permis',
      displayName: 'commanderPermis',
      accountType: AccountType.User,
    },
  },
  {
    path: '/community-distribution-report',
    name: 'RapportsDc',
    component: RapportsDc,
    meta: {
      parent: 'Home',
      displayName: 'Rapports distribution communautaire',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/order-scan',
    name: 'OrderScan',
    component: OrderScan,
    meta: {
      parent: 'Home',
      displayName: 'Scanner une commande',
      accountType: AccountType.Distributor,
    },
  },
  {
    path: '/distribution-site-report',
    name: 'RapportsSd',
    component: RapportsSd,
    meta: {
      parent: 'Home',
      displayName: 'Rapports sites de distributions',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/etat-commandes-report',
    name: 'RapportEc',
    component: RapportEc,
    meta: {
      parent: 'Home',
      displayName: 'Rapport état des commandes',
      accountType: AccountType.Distributor,
    },
  },
  {
    path: '/etat-commandes-report-admin',
    name: 'RapportEcAdmin',
    component: RapportEcAdmin,
    meta: {
      parent: 'Home',
      displayName: 'Rapport état des commandes (admin)',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/commandes-supprimees-report',
    name: 'RapportCs',
    component: RapportCs,
    meta: {
      parent: 'Home',
      displayName: 'Rapport des commandes supprimées',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/commandes-permis-report-admin',
    name: 'RapportCommandesPermisAdmin',
    component: RapportCommandesPermisAdmin,
    meta: {
      parent: 'Home',
      displayName: 'Rapport des commandes de permis (admin)',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/creation-modification-inscription-assemblee/:id?',
    name: 'CreationModificationInscription',
    component: CreationModificationInscription,
    meta: {
      parent: 'ListeInscriptionsAssembleeAdmin',
      displayName: "Création ou modification d'une inscription à l'assemblée",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/liste-inscriptions-assemblee-admin',
    name: 'ListeInscriptionsAssembleeAdmin',
    component: ListeInscriptionsAssembleeAdmin,
    meta: {
      parent: 'Home',
      displayName: "Liste des inscriptions à l'assemblée (admin)",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/remerciment',
    name: 'Remerciment',
    component: Remerciment,
    meta: {
      parent: 'OrderValidation',
      displayName: 'merci',
    },
  },
  {
    path: '/remercimentPermis',
    name: 'RemercimentPermis',
    component: RemercimentPermis,
    meta: {
      parent: 'CommandePermis',
      displayName: 'merci',
    },
  },
  {
    path: '/remercimentAssemblee',
    name: 'RemercimentAssemblee',
    component: RemercimentAssemblee,
    meta: {
      parent: 'FormulaireAssembleeGenerale',
      displayName: 'merci',
    },
  },
  {
    path: '/liste-inscriptions-assemblee-generale',
    name: 'ListeInscriptionsAssemblee',
    component: ListeInscriptionsAssemblee,
    meta: {
      parent: 'Home',
      displayName: "Liste des inscriptions à l'assemblée générale",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/liste-coupons-admin',
    name: 'ListeCouponsAdmin',
    component: ListeCouponsAdmin,
    meta: {
      parent: 'Home',
      displayName: "Coupons créés par l'administrateur",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/profil',
    name: 'profil',
    component: Profil,
    meta: {
      parent: 'Home',
      displayName: 'monProfil',
      accountType: AccountType.User,
    },
  },
  {
    path: '/assemblee-generale',
    name: 'FormulaireAssembleeGenerale',
    component: FormulaireAssembleeGenerale,
    meta: {
      parent: 'Home',
      displayName: "Formulaire d'inscription à l'assemblée générale",
      accountType: AccountType.User,
    },
  },
  {
    path: '/creation-modification-coupon/:id?',
    name: 'CreationModificationCoupon',
    component: CreationModificationCoupon,
    meta: {
      parent: 'Home',
      displayName: "Création ou modification d'un coupon",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/mes-coupons',
    name: 'coupons',
    component: Coupons,
    meta: {
      parent: 'Home',
      displayName: 'mesCoupons',
      accountType: AccountType.User,
    },
  },
  {
    path: '/mes-permis',
    name: 'permis',
    component: Permis,
    meta: {
      parent: 'Home',
      displayName: 'mesPermis',
      accountType: AccountType.User,
    },
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      parent: 'Home',
      displayName: 'aPropos',
      accountType: AccountType.User,
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: 'about' */ '../views/About.vue'),
  },
  {
    path: '/espace-video',
    name: 'espaceVideo',
    component: EspaceVideo,
    meta: {
      parent: 'Home',
      displayName: 'espaceVideo',
      accountType: AccountType.Any,
    },
  },
  {
    path: '/elections',
    name: 'elections',
    component: Elections,
    meta: {
      parent: 'Home',
      displayName: 'elections',
      accountType: AccountType.Any,
    },
  },
  {
    path: '/reponses-candidats-elections',
    name: 'reponsesCandidatsElections',
    component: ReponsesCandidatsElections,
    meta: {
      parent: 'Home',
      displayName: 'reponsesCandidatsElections',
      accountType: AccountType.Any,
    },
  },
  {
    path: '/coupon-promo',
    name: 'couponPromoMembre',
    component: CouponPromoMembre,
    meta: {
      parent: 'Home',
      displayName: 'monCouponPromo',
      accountType: AccountType.User,
    },
  },
  {
    path: '/importation-coupons-promo',
    name: 'importationCouponsPromo',
    component: ImportationCouponsPromo,
    meta: {
      parent: 'Home',
      displayName: 'importationCouponsPromo',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/liste-utilisateurs',
    name: 'ListeUtilisateurs',
    component: ListeUtilisateurs,
    meta: {
      parent: 'Home',
      displayName: 'Liste des utilisateurs',
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/Modification-utilisateur/:userId?',
    name: 'ModificationUtilisateur',
    component: ModificationUtilisateur,
    meta: {
      parent: 'liste-utilisateur',
      displayName: "Modification ou création d'un utilisateur",
      accountType: AccountType.Admin,
    },
  },
  {
    path: '/UIKit',
    name: 'Uikit',
    component: UIKit,
  },
  {
    path: '/admin/login',
    name: 'LoginAdmin',
    component: LoginAdmin,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.accountType)) {
    const isAuthenticated = store.getters['Auth/isLoggedIn'];
    const accountTypeNeeded = to.meta.accountType;
    const accountType = store.getters['Auth/accountType'];
    if (accountType == accountTypeNeeded) {
      next();
    } else if (
      to.matched.find((record) => record.meta.accountType == AccountType.Any) &&
      isAuthenticated
    ) {
      next();
    } else if (isAuthenticated) {
      next('/');
    } else {
      next('/login');
    }
  } else {
    next();
  }
});

export default router;
