import { VuexModule, Module, Action } from 'vuex-module-decorators';
import AdminService from '@/services/AdminService';
import { PermisDTO } from '@/types/store/DTO/PermisDTO';
import UserService from '@/services/UserService';
import { AxiosResponse } from 'axios';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
@Module({ namespaced: true })
class Permis extends VuexModule {
  @Action({ rawError: true })
  getAllCommandesPermis(): Promise<PermisDTO> {
    return AdminService.getAllCommandesPermis().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getAllPermis(): Promise<PermisDTO> {
    return UserService.getAllPermis().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  getUserPermis(memberId: string): Promise<CommandePermisDTO> {
    return UserService.getUserPermis().then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
  @Action({ rawError: true })
  commanderPermis(data: CommandePermisDTO): Promise<AxiosResponse> {
    return UserService.commanderPermis(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default Permis;
