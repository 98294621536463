<template>
  <div class="container">
    <h1 class="titre">{{ $t('confirmationInscription') }}</h1>
    <MessageBox
      :HeaderMessage="$t('inscriptionRecue.titre')"
      :BodyMessage="$t('inscriptionRecue.contenu')"
      :type="boxStyle.Success"
    />
    <button class="btn-demande" @click="goToHome">
      <font-awesome-icon :icon="['fas', 'house']" />
      {{ $t('retourAccueil') }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'RemercimentAssemblee',
  components: {
    MessageBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        confirmationInscription: 'Confirmation of registration',
        inscriptionRecue: {
          titre: 'Registration has been received',
          contenu: 'Thank you for your registration.',
        },
      },
      fr: {
        confirmationInscription: "Confirmation de l'inscription",
        inscriptionRecue: {
          titre: "L'inscription a bien été reçue",
          contenu: 'Merci pour votre inscription.',
        },
      },
    },
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 300px;
  padding-top: 100px;
  .titre {
    margin-bottom: 40px;
  }
  .btn-demande {
    margin-top: 20px;
    background-color: $bleuFonce;
    border: none;
    border-radius: 30px;
    padding: 10px 20px 10px 20px;
    color: $white;
    font-weight: 500;
    .ico {
      margin-right: 5px;
    }
  }
}
</style>
