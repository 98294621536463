import { VuexModule, Module, Action } from 'vuex-module-decorators';
import UserService from '@/services/UserService';
import { UserUpdateDTO } from '@/types/store/DTO/UserUpdateDTO';
@Module({ namespaced: true })
class User extends VuexModule {
  @Action({ rawError: true })
  updateUser(data: UserUpdateDTO): Promise<UserUpdateDTO> {
    return UserService.updateUser(data).then(
      (response) => {
        return Promise.resolve(response.data);
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return Promise.reject(message);
      }
    );
  }
}
export default User;
