<template>
  <div class="container">
    <div class="container-box">
      <qrcode-stream
        :camera="camera"
        :track="paintOutline"
        @decode="onDecode"
        @init="onInit"
      >
        <div v-if="validationSuccess" class="validation-success">
          <font-awesome-icon
            id="qrSuccess"
            :icon="['fal', 'circle-check']"
            class="ico"
            width="150px"
          />
          {{ success }}
          <br />
          Distribution des boîtes : {{ nbBoitesDistribuees }}/{{
            nbBoitesCommandees
          }}
        </div>

        <div v-if="validationFailure" class="validation-failure">
          <font-awesome-icon
            id="qrError"
            :icon="['fal', 'circle-xmark']"
            class="ico"
            width="150px"
          />
          {{ error }}
        </div>

        <div v-if="validationPending" class="validation-pending">
          Réclamation de la commande en cours...
        </div>
      </qrcode-stream>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
import { DetectedCode } from '../types/qrCodeReader/DetectedCode';
import { Context } from '../types/qrCodeReader/Context';
import QrcodeStream from 'vue-qrcode-reader/src/components/QrcodeStream.vue';

export default defineComponent({
  name: 'OrderScan',
  components: {
    QrcodeStream,
  },
  data() {
    return {
      camera: 'auto',
      commande: {
        uuid: '',
        site_distribution_id: -1,
      },
      isValid: undefined as undefined | boolean,
      showScanConfirmation: false,
      error: '',
      success: '',
      boitesDistribuees: '',
      boitesCommandees: '',
    };
  },
  computed: {
    validationPending(): boolean {
      return this.isValid === undefined && this.camera === 'off';
    },
    validationSuccess(): boolean {
      return this.isValid === true;
    },
    validationFailure(): boolean {
      return this.isValid === false;
    },
  },
  methods: {
    paintOutline(detectedCodes: Array<DetectedCode>, ctx: Context) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = 'red';

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    onInit(promise: Promise<void>) {
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content: string) {
      // pretend it's taking really long
      this.turnCameraOff();
      await this.timeout(1000);

      this.commande.uuid = content;

      this.$loading.startLoading();
      this.$store
        .dispatch('Commande/reclamerCommande', this.commande)
        .then((data: { data; message: string }) => {
          this.nbBoitesDistribuees = data.data.nbBoitesDistribuees;
          this.nbBoitesCommandees = data.data.nbBoitesCommandees;
          this.isValid = true;
          this.success = data.message;
          this.$loading.stopLoading();
        })
        .catch((message: string) => {
          this.isValid = false;
          this.error = message;
          this.$loading.stopLoading();
        });

      // some more delay, so users have time to read the message
      await this.timeout(3000);

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = 'auto';
    },

    turnCameraOff() {
      this.camera = 'off';
    },

    timeout(ms: number) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  created() {
    this.$store.dispatch('Users/adminMe').then((data: UsersDTO) => {
      this.commande.site_distribution_id = data.site_distribution_id;
    });
  },
});
</script>
<style lang="scss" scoped>
.validation-success,
.validation-failure,
.validation-pending {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  padding: 10px;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
#qrSuccess,
#qrError {
  height: auto;
  margin-bottom: 20px;
}
#qrError {
  color: $rougeFonce;
}
#qrSuccess {
  color: $vertClaire;
}
</style>
