<template>
  <div>
    <Card>
      <template v-slot:content>
        <div class="cherch-btn">
          <input
            type="text"
            placeholder="Rechercher par nom, téléphone…"
            v-model="search"
          />
          <button class="recherche" @click="filter">
            <font-awesome-icon :icon="[ico, 'magnifying-glass']" />
          </button>
        </div>
        <p class="result">Résultats</p>
        <DataTable
          v-model:selection="selectedOrder"
          :value="rapports"
          :paginator="true"
          :rows="10"
          ref="dt"
          paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          responsiveLayout="scroll"
          currentPageReportTemplate="{totalRecords} PERMIS"
        >
          <Column header="DATE" :sortable="true">
            <template #body="{ data }">
              {{ dateParserLong(data.created_at) }}
            </template>
          </Column>
          <Column header="NUMÉRO DE REÇU" field="squareReceiptNumber"> </Column>
          <Column field="id" header="Nº COMMANDE" :sortable="true">
            <template #body="{ data }">
              {{ padLeadingZeros(data.id) }}
            </template>
          </Column>
          <Column header="NOM" :sortable="true">
            <template #body="{ data }">
              {{ data.firstName }} <br />
              {{ data.lastName }}
            </template>
          </Column>
          <Column
            header="NO. CERTIFICAT"
            field="noCertificat"
            :sortable="true"
          />
          <Column header="NO. MEMBRE" field="bandNumber" :sortable="true" />
          <Column header="DATE DE NAISSANCE" :sortable="true">
            <template #body="{ data }">
              {{ dateParserLong(data.birthDate) }}
            </template>
          </Column>
          <Column header="PERMIS">
            <template #body="{ data }">
              <ul class="liste-permis">
                <li
                  v-for="permis in data.commandes_permis_items"
                  v-bind:key="permis.id"
                >
                  {{ permis.nom }}
                </li>
              </ul>
            </template>
          </Column>
          <Column header="ADRESSE" field="adress" />
          <Column header="TÉLÉPHONE" field="telephone" />
          <Column header="COURRIEL" field="email" />
          <template #paginatorstart>
            <button class="btn-rapport" @click="exportCSV">
              <font-awesome-icon :icon="[ico, 'file-export']" />
              Exporter en CSV
            </button>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { CommandePermisDTO } from '@/types/store/DTO/CommandePermisDTO';
import { dateParserLong } from '@/helpers/dateParserHelper';
import Card from '@/components/Card.vue';
import { UsersDTO } from '@/types/store/DTO/UsersDTO';
export default defineComponent({
  name: 'RapportCp',
  components: { DataTable, Column, Card },
  data() {
    return {
      rapports: new Array<CommandePermisDTO>(),
      copieRapports: new Array<CommandePermisDTO>(),
      ico: 'far',
      site: -1,
      search: '',
      isAdmin: false,
      selectedOrder: {} as CommandePermisDTO,
    };
  },
  methods: {
    dateParserLong,
    padLeadingZeros(num: number) {
      let s = num + '';
      while (s.length < 7) s = '0' + s;
      return s;
    },
    getCommandesPermis() {
      this.$store
        .dispatch('Permis/getAllCommandesPermis')
        .then((data: Array<CommandePermisDTO>) => {
          this.rapports = data;
          this.copieRapports = this.rapports;
        });
    },
    getNormalizedString(str: string) {
      return str
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
    },
    getIfStringMatchSearch(str: string) {
      const search = this.search;
      return (
        str !== undefined &&
        str !== null &&
        this.getNormalizedString(str).includes(
          this.getNormalizedString(search.toLowerCase())
        )
      );
    },
    getIfCommandeMatch(commande: CommandePermisDTO) {
      return (
        this.getIfStringMatchSearch(this.padLeadingZeros(commande.id)) ||
        this.getIfStringMatchSearch(commande.firstName) ||
        this.getIfStringMatchSearch(commande.lastName) ||
        this.getIfStringMatchSearch(commande.email) ||
        this.getIfStringMatchSearch(dateParserLong(commande.created_at)) ||
        this.getIfStringMatchSearch(commande.memberId) ||
        this.getIfStringMatchSearch(commande.adress) ||
        this.getIfStringMatchSearch(commande.city) ||
        this.getIfStringMatchSearch(commande.postalCode) ||
        this.getIfStringMatchSearch(commande.noCertificat) ||
        this.getIfStringMatchSearch(commande.squareReceiptNumber) ||
        this.getIfStringMatchSearch(dateParserLong(commande.birthDate)) ||
        this.getIfStringMatchSearch(commande.bandNumber)
      );
    },
    filter() {
      this.rapports = this.copieRapports;
      const result = this.rapports.filter(this.getIfCommandeMatch);
      this.rapports = result;
    },
    exportCSV() {
      this.$store.dispatch('Rapports/getCommandesPermisCsv').then();
    },
  },
  created() {
    this.isAdmin = JSON.parse(sessionStorage.user).role == 'admin';
    this.getCommandesPermis();
  },
});
</script>

<style scoped lang="scss">
#btn-distribuer:disabled {
  opacity: 0.5;
}
.ico {
  font-size: 20px;
  color: $bleuFonce;
  opacity: 0;
}
.ico-distribuee {
  font-size: 20px;
  color: $bleuFonce;
}
.sites {
  color: $bleuFonce;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.lieu {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid $bleuFonce;
  color: $bleuFonce;
  font-weight: bold;
}
.gibier {
  margin-left: 15px;
  color: $rougeFonce;
}
.saumon-icon {
  opacity: 0;
  width: 2em;
  margin-left: 15px;
}
.showIcon {
  opacity: 1;
}
.btn-rapport {
  border: 1px solid $grisFonce;
  background-color: $bleuFonce;
  border-radius: 30px;
  padding: 10px 20px 10px 20px;
  color: #fff;
  font-weight: 500;
  .ico {
    margin-right: 5px;
  }
}
.notActivated {
  background-color: white;
  color: $grisFonce;
}
::v-deep(th) {
  background: none !important;
  color: $bleuFonce !important;
  font-weight: 800 !important;
  font-size: 16px !important;
}
::v-deep(td) {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: $textRapport !important;
}
::v-deep(.p-paginator-current) {
  color: $bleuFonce !important;
  font-weight: 500 !important;
}
::v-deep(tr:nth-child(even)) {
  background: #f8f8f8 !important;
}
select {
  height: 42px;
  border: 1px solid $grisFonce;
  padding-left: 10px;
  max-width: 40%;
  display: block;
}
select:focus {
  outline: none;
}
.btn-rigth {
  margin: auto;
  float: right;
}
.left-margin {
  margin-left: 15px;
}
.cherch-btn {
  margin-top: 20px;
  input {
    height: 42px;
    width: 30%;
    border: 1px solid $grisFonce;
    padding-left: 10px;
  }
  input:focus {
    outline: none;
  }
  button {
    height: 42px;
    border: none;
    color: #fff;
    background-color: $bleuFonce;
    padding-right: 15px;
    padding-left: 15px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
.error {
  color: $rougeFonce;
  height: 40px;
}
.success {
  color: $vert;
  height: 40px;
}
.result {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid $bleuFonce;
  color: $bleuFonce;
  font-weight: bold;
}
.liste-permis {
  padding: 0 1rem;
}
</style>
