<script setup>
import { Button } from 'primevue/button';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

const toast = useToast();
const store = useStore();

const form = ref({
  file: null,
  errors: [],
});

function submit() {
  console.log(form.value.file);
  debugger;

  store
    .dispatch('Rapports/importFileCouponsPromos', form.value.file)
    .then(() => {
      toast.add({
        severity: 'success',
        summary: 'Fichier importé',
        detail: 'Le fichier a été importé avec succès',
        life: 3000,
      });
    });
}
</script>
<template>
  <div class="contenu">
    <div class="titre">
      <h1>Importation de coupons promotionnels</h1>
    </div>
    <form
      @submit.prevent="submit"
      style="
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        max-width: 400px;
      "
    >
      <div>
        <label class="mr-5" style="display: block; margin-bottom: 5px"
          >Fichier</label
        >
        <input
          type="file"
          name="file"
          id="file"
          accept=".csv"
          style="display: block; margin-bottom: 10px"
          @input="form.file = $event.target.files[0]"
        />
        <button type="submit" class="button">Importer</button>
      </div>
    </form>
  </div>
</template>
<style lang="scss" scoped>
.button {
  background-color: $bleu;
  color: $white;
  border: 1px solid $bleuCiel;
  padding: 1% 1.7% 1% 1.7%;
  margin-top: 1%;
}
.contenu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background-color: $white;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0px 0px 6px #87a8ea21;
  width: 50%;
  margin: 2rem auto;
  @media screen and (max-width: $md) {
    width: 80%;
  }
}
.titre {
  text-align: center;
  margin: 1.5%;
}
</style>
