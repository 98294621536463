<template>
  <div class="container">
    <h1 class="titre">{{ $t('confirmationCommande') }}</h1>
    <MessageBox
      :HeaderMessage="$t('commandeEnvoyee.titre')"
      :BodyMessage="$t('commandeEnvoyee.contenu')"
      :type="boxStyle.Success"
    />
    <button class="btn-demande" @click="goToHome">
      <font-awesome-icon :icon="['fas', 'house']" />
      {{ $t('retourAccueil') }}
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import commonMessages from '../locales/common';
export default defineComponent({
  name: 'RemercimentPermis',
  components: {
    MessageBox,
  },
  i18n: {
    sharedMessages: commonMessages,
    messages: {
      en: {
        confirmationCommande: 'Order confirmation',
        commandeEnvoyee: {
          titre: 'Order sent successfully',
          contenu:
            'Thank you for your order. You will receive confirmation of your licence order by email.',
        },
      },
      fr: {
        confirmationCommande: 'Confirmation de la commande',
        commandeEnvoyee: {
          titre: 'Commande envoyée avec succès',
          contenu:
            'Merci pour votre commande. Vous recevrez une confirmation de votre commande de permis par courriel.',
        },
      },
    },
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
  },
});
</script>
<style lang="scss" scoped>
.container {
  padding-bottom: 300px;
  padding-top: 100px;
  .titre {
    margin-bottom: 40px;
  }
  .btn-demande {
    margin-top: 20px;
    background-color: $bleuFonce;
    border: none;
    border-radius: 30px;
    padding: 10px 20px 10px 20px;
    color: $white;
    font-weight: 500;
    .ico {
      margin-right: 5px;
    }
  }
}
</style>
