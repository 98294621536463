<template>
  <div class="content-boite">
    <div class="box b1" v-if="panier.poisson">
      <div class="boite">
        <font-awesome-icon icon="fish" class="ico fish" />
        <span>
          <label>{{ $t('boite.poisson') }}</label>
        </span>
      </div>
      <button class="btn-supprimer" @click="deleteBoite(1)">
        <font-awesome-icon :icon="['fas', 'circle-xmark']" class="ico" />
        {{ $t('boutons.supprimer') }}
      </button>
    </div>
    <div class="box" v-if="panier.gibier">
      <div class="boite">
        <font-awesome-icon icon="deer" class="ico gibier" />
        <span>
          <label>{{ $t('boite.gibier') }}</label>
        </span>
      </div>
      <button class="btn-supprimer" @click="deleteBoite(2)">
        <font-awesome-icon :icon="['fas', 'circle-xmark']" class="ico" />
        {{ $t('boutons.supprimer') }}
      </button>
    </div>
  </div>
  <div class="content-boite">
    <div class="box b1" v-if="panier.saumon">
      <div class="boite">
        <img
          class="saumon-icon"
          src="../../assets/img/icon-saumon.svg"
          alt="saumon"
        />
        <span>
          <label>{{ $t('boite.saumon') }}</label>
        </span>
      </div>
      <button class="btn-supprimer" @click="deleteBoite(3)">
        <font-awesome-icon :icon="['fas', 'circle-xmark']" class="ico" />
        {{ $t('boutons.supprimer') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Panier } from '@/types/Panier';
import EventBus from '@/events/EventBus';
import commonMessages from '@/locales/common';
export default defineComponent({
  name: 'Requestbox',
  data() {
    return {
      panier: {} as Panier,
    };
  },
  i18n: {
    sharedMessages: commonMessages,
  },
  methods: {
    goToDistributionComm() {
      this.$router.push('/community-distribution');
    },
    //1 poisson 2 gibier
    deleteBoite(boite: number) {
      switch (boite) {
        case 1:
          this.panier.poisson = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
        case 2:
          this.panier.gibier = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
        case 3:
          this.panier.saumon = false;
          sessionStorage.panier = JSON.stringify(this.panier);
          this.redirectEmpty();
          EventBus.emit('panierChange');
          break;
      }
    },
    redirectEmpty() {
      if (!this.panier.gibier && !this.panier.poisson && !this.panier.saumon) {
        this.goToDistributionComm();
      }
    },
  },
  created() {
    if (sessionStorage.panier != undefined) {
      this.panier = JSON.parse(sessionStorage.panier);
    } else {
      this.goToDistributionComm();
    }
  },
});
</script>

<style scoped lang="scss">
.content-boite {
  display: flex;
  .box {
    .btn-supprimer {
      border: none;
      background-color: transparent;
      margin-top: 12px;
      color: $bleuFonce;
    }
  }
  .boite {
    margin-top: 20px;
    display: flex;
    .saumon-icon {
      width: 2em;
    }
    span {
      margin-left: 20px;
      align-self: center;
      display: flex;
      label {
        font-size: 26px;
        color: $bleu;
        align-self: center;
      }
    }
  }
  .b1 {
    margin-right: 50px;
  }
}
.fish {
  font-size: 24px;
  color: $bleuFonce;
  align-self: center;
}
.gibier {
  font-size: 24px;
  color: $rougeFonce;
  align-self: center;
}
@media only screen and (max-width: $md) {
  .content-boite {
    display: block;
    .b1 {
      margin-left: 0;
    }
  }
}
</style>
