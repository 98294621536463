import { AxiosRequestHeaders } from 'axios';
export default function AuthHeader(): AxiosRequestHeaders {
  const storedUser = sessionStorage.getItem('user');
  const user = JSON.parse(storedUser ? storedUser : '');
  if (user && user.access_token) {
    return {
      Authorization: 'Bearer ' + user.access_token,
    };
  } else {
    console.error('not logged in as local user');
    return {};
  }
}
