<template>
  <CSpinner />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CSpinner } from '@coreui/vue';

export default defineComponent({
  name: 'Loading',
  components: {
    CSpinner,
  },
});
</script>
<style lang="scss">
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: #5279a3;
  }
  40% {
    stroke: #5279a3;
  }
  66% {
    stroke: #5279a3;
  }
  80%,
  90% {
    stroke: #5279a3;
  }
}
</style>
