<template>
  <div class="container-login">
    <div class="box-login">
      <p class="titre">{{ $t('bienvenue') }}</p>
      <p class="sous-titre">
        {{ $t('loginInfo') }}
      </p>
      <div class="input-container">
        <label for="Name" class="form-label">{{ $t('nomFamille') }}</label>
        <input type="text" id="Name" class="form-input" v-model="lastName" />
        <div
          class="errorMessage"
          v-for="error of v$.lastName.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ requiredMessage }}
          </label>
          <label v-if="error.$validator == 'minLength'">
            Ce champ doit être au moins {{ error.$params.min }} caractères.
          </label>
        </div>
      </div>
      <div class="input-container">
        <label for="dateNaisse" class="form-label">{{
          $t('dateNaissance')
        }}</label>
        <!-- <input
          type="date"
          id="dateNaisse"
          class="form-input"
          v-model="birthDate"
        /> -->
        <Datepicker
          id="dateNaisse"
          v-model="birthDate"
          :enableTimePicker="false"
          :flow="flow"
          autoApply
          :yearRange="[1900, 2022]"
          locale="fr"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </Datepicker>
        <p
          class="errorMessage"
          v-for="error of v$.birthDate.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ requiredMessage }}
          </label>
        </p>
      </div>
      <div class="input-container">
        <label for="noMembre" class="form-label">{{ $t('numMembre') }}</label>
        <input
          type="text"
          id="noMembre"
          class="form-input"
          v-model="bandNumber"
        />
        <p
          class="errorMessage"
          v-for="error of v$.bandNumber.$errors"
          :key="error.$uid"
        >
          <label v-if="error.$validator == 'required'">
            {{ requiredMessage }}
          </label>
        </p>
      </div>
      <div class="input-container">
        <button class="btn-form" @click="submitform">
          <font-awesome-icon :icon="['fas', 'lock']" class="ico-form" />
          {{ $t('btnConnexion') }}
        </button>
      </div>
      <label v-if="errorConnection" class="errorcnx"
        >Les informations entrées sont incorrectes</label
      >
    </div>
  </div>
</template>

<script lang="ts">
import { load, ReCaptchaInstance } from 'recaptcha-v3';
import { defineComponent } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, numeric, minLength } from '@vuelidate/validators';
import EventBus from '../events/EventBus';
import Datepicker from '@vuepic/vue-datepicker';
import { ref } from 'vue';
export default defineComponent({
  name: 'Login',
  setup() {
    const flow = ref(['year', 'month', 'calendar']);
    return {
      flow,
      v$: useVuelidate(),
    };
  },
  components: { Datepicker },
  i18n: {
    messages: {
      en: {
        bienvenue: 'Welcome to our portal',
        loginInfo: 'To log in, please complete the fields below :',
        nomFamille: 'Last name',
        dateNaissance: 'Birth date',
        numMembre: 'Member number',
        btnConnexion: 'Login',
      },
      fr: {
        bienvenue: 'Bienvenue sur notre portail',
        loginInfo:
          'Pour vous connecter, veuillez compléter les champs ci-dessous :',
        nomFamille: 'Nom de famille',
        dateNaissance: 'Date de naissance',
        numMembre: 'Numéro de membre',
        btnConnexion: 'Se connecter',
      },
    },
  },
  data() {
    return {
      lastName: '',
      birthDate: '',
      bandNumber: '',
      requiredMessage: 'Champ requis.',
      errorConnection: false,
      recaptcha: {} as ReCaptchaInstance,
    };
  },
  validations: {
    lastName: { required, minLength: minLength(3) },
    birthDate: { required },
    bandNumber: { required },
  },
  methods: {
    submitform() {
      this.$loading.startLoading();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.$loading.stopLoading();
        return;
      }
      this.login();
    },
    formatDate(date: string) {
      let dateToFormat = new Date(date).toISOString().split('T')[0];
      return dateToFormat;
    },
    login() {
      if (this.lastName && this.birthDate && this.bandNumber) {
        let formattedBirthDate = this.formatDate(this.birthDate);
        this.recaptcha.execute('login').then((token) => {
          this.$store
            .dispatch('Auth/login', {
              lastName: this.lastName,
              birthDate: formattedBirthDate,
              bandNumber: this.bandNumber,
              recaptcha: token,
            })
            .then(() => {
              this.$loading.stopLoading();
              EventBus.emit('userStatusChange');
              this.$router.push({ name: 'Home' });
            })
            .catch(() => {
              this.$loading.stopLoading();
              this.errorConnection = true;
            });
        });
      }
    },
  },
  created() {
    load(process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY).then((recaptcha) => {
      this.recaptcha = recaptcha;
    });
    const userLogged = this.$store.getters['Auth/isLoggedIn'];
    if (userLogged) this.$router.push({ name: 'Home' });
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/_layout.scss';
.container-login {
  background-image: url('@/assets/img/fondConnexion.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  display: flex;
  justify-content: right;
  padding: 66px 15px 66px 15px;
  margin: 0px;
  @include media-breakpoint-down($lg) {
    justify-content: center;
  }
}

.box-login {
  background-color: white;
  width: 464px;
  border-radius: 16px;
  padding: 40px;
  text-align: center;
}
.titre {
  color: #273566;
  margin-bottom: 28px;
  font-size: 22px;
  font-weight: 800;
}
.sous-titre {
  font-weight: 500;
  margin-bottom: 9px;
}
.form-label {
  display: block;
  color: #274b93;
  font-weight: 500;
}
.form-input {
  width: 100%;
  height: 42px;
  border: 1px solid #cecece;
  padding-left: 10px;
}
.form-input:focus {
  outline: none;
}
.input-container {
  margin-top: 15px;
}
.btn-form {
  background-color: #274b93;
  width: 100%;
  margin-top: 10px;
  border-radius: 21px;
  border: 1px solid #abcdde;
  color: white;
  padding: 11px;
  font-weight: 500;
}
.ico-form {
  margin-right: 5px;
}
.errorMessage {
  font-size: 12px;
  color: red;
}
.errorcnx {
  color: red;
  margin-top: 10px;
}
@media screen and (min-width: 1200px) {
  .container-login {
    padding-right: 15%;
  }
}
</style>
