import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86cb494a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-box" }
const _hoisted_2 = {
  key: 0,
  class: "header-box"
}
const _hoisted_3 = {
  key: 1,
  class: "span-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon || _ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.icon)
            ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                key: 0,
                icon: ['fal', _ctx.icon],
                class: "ico"
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          (_ctx.title)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.title), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "content", {}, undefined, true)
  ]))
}