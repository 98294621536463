<template>
  <div class="componentlist">
    <div class="componentlist__item">
      <h1>MessageBox</h1>
      <div class="componentlist__item-layout componentlist__item-spacing">
        <MessageBox
          :HeaderMessage="'Commande envoyée avec succès'"
          :BodyMessage="'Merci pour votre commande. Vous recevrez des coupons'"
          :type="boxStyle.Success"
        />
        <MessageBox
          :HeaderMessage="'Commande envoyée avec succès'"
          :BodyMessage="'Merci pour votre commande. Vous recevrez des coupons'"
          :type="boxStyle.Info"
        />
        <MessageBox
          :HeaderMessage="'Commande envoyée avec succès'"
          :BodyMessage="'Merci pour votre commande. Vous recevrez des coupons'"
          :type="boxStyle.Warning"
        />
        <MessageBox
          :HeaderMessage="'Commande envoyée avec succès'"
          :BodyMessage="'Merci pour votre commande. Vous recevrez des coupons'"
          :type="boxStyle.Error"
        />
      </div>
      <div>
        <Tab />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MessageBox from '@/components/MessageBox.vue';
import MessageBoxType from '@/types/enums/MessageBoxType';
import Tab from '@/components/rapports/RapportDc.vue';

export default defineComponent({
  name: 'UIKit',
  components: {
    MessageBox,
    Tab,
  },
  data() {
    return {
      boxStyle: MessageBoxType,
    };
  },
});
</script>
<style lang="scss" scoped>
.componentlist {
  &__item {
    &-layout {
      display: flex;
      flex-direction: row;
    }
    &-spacing > * {
      margin: 0.5rem;
    }
  }
}
</style>
