<template>
  <div class="adresses-box">
    <span v-for="adress in sites" :key="adress.ville">
      <p class="t1">{{ adress.ville }}</p>
      <p class="t2">{{ adress.lieux }}</p>
      <p class="t3">{{ adress.adresse }}</p>
    </span>
  </div>
</template>

<script lang="ts">
import { siteDistributionDTO } from '@/types/store/DTO/siteDistributionDTO';
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'AdressesDistribution',
  data() {
    return {
      sites: {} as Array<siteDistributionDTO>,
    };
  },
  methods: {
    siteDistrib() {
      this.$store
        .dispatch('SiteDistribution/getSiteDistribution')
        .then((data: Array<siteDistributionDTO>) => {
          this.sites = data;
        });
    },
  },
  created() {
    this.siteDistrib();
  },
});
</script>

<style scoped lang="scss">
@media only screen and (min-width: $xl) {
  .adresses-box {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}
.adresses-box {
  .t1 {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .t2,
  .t3 {
    font-weight: 500;
  }
  .t2 {
    font-style: italic;
  }
  .t3 {
    margin-bottom: 20px;
  }
}
</style>
